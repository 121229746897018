import classNames from 'classnames';
/*eslint-disable*/
import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import { AssistantT } from '@/Assistant/Construction/kind';
import { getAssistants } from '@/Assistant/Mechanisms/action';
import { PlanT } from '@/DialogEditor/Canvas/Components/WelcomePageWithJourney';
import { NotificationPanel } from '@/Notification/Notification';
import { useNotifications } from '@/Notification/userNotifications';
import { defaultProfileData } from '@/Profile/types';
import { Workspaces } from '@/Workspace/workspace';
import {
  DialogIcon,
  EntityIcon,
  HelpDocIconV2,
  IntentIcon,
  NotificationIcon,
  RightArrowWithOutCircle,
  SubPanelIcons,
  SupportIcon,
  TourIcon,
  UserIconAnalytics,
  UserIconAnalyticsMenu,
} from '@/common/Icons/Icons';
import { useConditionalEffect } from '@/common/hooks/useConditionalEffect';
import useToggleOnClickWithOutsideClickHandled from '@/common/hooks/useToggleOnClickWithOutsideClickHandled';
import { defaultState } from '@/common/types/WorkspacesT';
import { sortAnArrayByDate } from '@/common/utils/common.utils';

import logo_img from '@/common/images/logo.png';
import { getJson, loginApiURL, stripeGetApi } from '@/common/utils/api-utils';
import { SideBarContext } from './SideBarContext';
import { isNotAutomate } from '@/common/utils/utils';
import {
  Linktext,
  NavLinkT,
  SideBarT,
  subMenu,
  getSideBarLinkTree,
  LinkRoute
} from './SideBarTypes';
import { TourSideBars } from './TourSideBar';
import { usePermissionSignal, usePermissions } from '@/common/utils/auth-token-api-utils';
import { useComputed, useSignal } from '@preact/signals-react';
import { useQueryClient } from 'react-query';
import { Location, Outlet } from 'react-router-dom';

type Panels = 'Profile' | 'Notification' | 'HelpVideos' | 'None';

const ProfilePathstoShowSelected = ['profile', 'users', 'billing'];

const SupportPathstoShowSelected = ['support'];

const usePrevious = (value: any) => {
  const ref = React.useRef<any>();
  React.useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};


export function ProfileTabs(props:{history:any,match:any,location:Location}) {

  const { workspacename } = props.match.params

  let emailLiRef: any = useRef();

  const [panel, setPanel] = useState<Panels>('None');

  const [profileData, setProfileData] = useState(defaultProfileData);

  const [plan, setPlan] = useState<PlanT | {}>({});

  const [state, setState] = useState(defaultState);


  let profileReference: any = useRef();
  let notificationRef: any = useRef();

  const [
    notifications,
    { markAllRead, markSeen, notificationClick, fetchNotification },
    notificationCount,
    hasMore,
  ] = useNotifications(panel !== 'Notification', workspacename);

  const queryClient = useQueryClient()

  useEffect(() => {
    callProfileApi();
    callWorkspacesApi();
  }, []);

  async function callProfileApi() {
    const apis = [
        queryClient
              .fetchQuery({
                  queryKey : ['user_info'],
                  queryFn : () => { 
                  return getJson(loginApiURL('/user_info')) 
                  },
              }),
        queryClient
              .fetchQuery({
                  queryKey : ['subscription_current'],
                  queryFn : () => { 
                  return stripeGetApi(`${workspacename}/subscription/current/lite`) 
                  },
              })
    ];

    Promise.all(apis)
      .then(([user, plan]) => {
        setProfileData(user);
        setPlan(plan.output);
      })
      .catch((e) => {
        //console.log(e)
      });
  }

  async function callWorkspacesApi() {
    let updatedState = { ...state };

    await queryClient
      .fetchQuery({
          queryKey : ['accessible_workspaces'],
          queryFn : () => { 
          return getJson(loginApiURL('/accessible_workspaces')) 
          },
      })
      .then((e) => {
        updatedState = {
          ...updatedState,
          workspaces: e,
          defaultWs: '',
          viewWorkspaces: e.length == 1,
        };
        setState(updatedState);
      })
      .catch((e) => {
        // console.log(e)
      });

      await queryClient
      .fetchQuery({
          queryKey : ['get_default_workspace'],
          queryFn : () => { 
          return getJson(loginApiURL('/get_default_workspace')) 
          },
      })
      .then((e) => {
        setState({
          ...updatedState,
          defaultWs:
            e.default_workspaceId === null ? '' : e.default_workspaceId,
        });
      })
      .catch((e) => {
        // console.log(e)
      });
  }

  useConditionalEffect(markSeen, { panel }, (curr: any, prev: any) => {
    if (prev == undefined) {
      return true;
    }
    // console.log('run effect condition', curr, prev)
    if (prev.panel == 'Notification' && curr.panel != 'Notification') {
      return false;
    }
    return true;
  });

  useToggleOnClickWithOutsideClickHandled(
    profileReference,
    () => {
      // setPanel('None')
      props.location.pathname.includes('/help/videos')
        ? setPanel('HelpVideos')
        : setPanel('None');

      // if (!selectedLinks.showSubpanel) closeFlowCanvasLeftPanel(false);
      // panel === 'Profile' &&
      //   typeof selectedLinks.previousLink !== 'boolean' &&
      //   setSelectedLink({
      //     ...selectedLinks,
      //     selectedLink: selectedLinks.previousLink,
      //   });
    },
    () => {
      setPanel(
        panel === 'Profile'
          ? props.location.pathname.includes('/help/videos')
            ? 'HelpVideos'
            : 'None'
          : 'Profile'
      );

      // setPanel(panel === 'Profile' ? 'None' : 'Profile')
      // panel === 'Profile' &&
      //   typeof selectedLinks.previousLink !== 'boolean' &&
      //   setSelectedLink({
      //     ...selectedLinks,
      //     selectedLink: selectedLinks.previousLink,
      //   });
      // closeFlowCanvasLeftPanel(false);
    },
    [notificationRef, emailLiRef]
  );

  const closeSidePanelWithSubPanelCheck = () => {
    props.location.pathname.includes('/help/videos')
      ? setPanel('HelpVideos')
      : setPanel('None');
    // if (!selectedLinks.showSubpanel) closeFlowCanvasLeftPanel(false);
    // panel === 'Notification' &&
    //   typeof selectedLinks.previousLink !== 'boolean' &&
    //   setSelectedLink({
    //     ...selectedLinks,
    //     selectedLink: selectedLinks.previousLink,
    //   });
  };

  useToggleOnClickWithOutsideClickHandled(
    notificationRef,
    closeSidePanelWithSubPanelCheck,
    () => {
      setPanel(
        panel === 'Notification'
          ? props.location.pathname.includes('/help/videos')
            ? 'HelpVideos'
            : 'None'
          : 'Notification'
      );
      // closeFlowCanvasLeftPanel(panel === 'Notification' ? false : true);
      // panel === 'Notification' &&
      //   typeof selectedLinks.previousLink !== 'boolean' &&
      //   setSelectedLink({
      //     ...selectedLinks,
      //     selectedLink: selectedLinks.previousLink,
      //   });
    },
    [profileReference, emailLiRef]
  );

  // console.log(props,"props")

  return (
    <>
    <div className="item-group bottom_bar_sidemenu">
      <div
        className={
          props.location.pathname == `/workspace/${workspacename}/support` && panel === 'None'
            ? 'item support_selected'
            : 'item'
        }
        onClick={() => {
          if(!props.location.pathname.includes("/support")){
            props.history.push(`/workspace/${workspacename}/support`);
          } 
          setPanel("None")
        }}
      >
        <div className="img">
          <SupportIcon />
        </div>
        <span className="side_menu_hvr">Support</span>
      </div>
      {/* <a>
        <div
          className={
            panel == 'HelpVideos' ? 'item notification_selected' : 'item'
          }
          onClick={() => {
            setPanel('HelpVideos');
            props.history.push(`/workspace/${props.workSpaceName}/help/videos`);
          }}
        >
          <div className="img ">
            <TourIcon />
          </div>
          <span className="side_menu_hvr">Help Videos</span>
        </div>
      </a>
      <a href="https://help.workativ.com/kb/workativ-assistant/" target="_blank">
        <div className={'item'}>
          <div className="img">
            <HelpDocIconV2 />
          </div>
          <span className="side_menu_hvr">Help Articles</span>
        </div>
      </a> */}
      {/* 
              <div className={'item help_button_box'}>
                  <div className="img" onClick={() => setShowHelpTab(true)}>
                      <HelpIconMenu />
                  </div>
                  {showHelpTab && (
                      <Downshift isOpen={true} onOuterClick={() => setShowHelpTab(false)}>
                          {() => (
                              <div className="sidebar_help_button_box">
                                  <a
                                      className="sidebar_help_button"
                                      onClick={() => props.history.push(`/workspace/${workSpaceName}/help/videos`)}
                                  >
                                      <VideoIcon />
                                      <p>Help Videos</p>
                                  </a>
                                  <a
                                      href="https://help.workativ.com/"
                                      target="_blank"
                                      className="sidebar_help_button"
                                  >
                                      <HelpDocIcon />
                                      <p>Help Document</p>
                                  </a>
                              </div>
                          )}
                      </Downshift>
                  )}
                  {!showHelpTab && <span className="side_menu_hvr">Help</span>}
              </div> */}
      <div
        className={
          panel === 'Notification' ? 'item notification_selected' : 'item'
        }
        ref={notificationRef}
      >
        <div className="img">
          <NotificationIcon />
          {notificationCount > 0 ? (
            <div className="count_notification">
              <span className="count_not_number"></span>
            </div>
          ) : null}
        </div>
        <span className="side_menu_hvr">Notifications</span>
      </div>
      <div
        className={
          props.location.pathname.includes('ProfilePaths') || panel === 'Profile'
            ? 'item profile_selected'
            : 'item'
        }
        ref={profileReference}
      >
        <div className="img ">
          {/* <div className="img_width"> */}
            {!profileData.image ? (
              // <span>
                <UserIconAnalytics />
              // </span>
            ) : (
              <img src={profileData.image} alt="user" />
            )}
          </div>
          <span className="side_menu_hvr">Profile</span>
        </div>       
      </div>
    {/* </div> */}
    {panel == 'Profile' && (
          <div>
            <Workspaces
              {...props}
              closePopup={() => {
                props.location.pathname.includes('/help/videos')
                  ? setPanel('HelpVideos')
                  : setPanel('None');

                // setPanel('None')
              }}
              selectedLink={"Workspace"}
              state={state}
              setState={setState}
              profileData={profileData}
              emailLiRef={emailLiRef}
              plan={plan}
            />
          </div>
        )}

        <div>
          <NotificationPanel
            show={panel == 'Notification'}
            notifications={notifications}
            markAllRead={markAllRead}
            notificationClick={notificationClick}
            fetchNotification={() => {
              console.log("fetchNotification('scroll')")
              fetchNotification('scroll')
            }}
            closePopup={closeSidePanelWithSubPanelCheck}
            hasMore={hasMore}
            workspace={workspacename}
          />
        </div>
    </>
  );
}

export function SidebarComponent(props: any) {
  // console.log('props', props)

  const queryClient = useQueryClient()

  const [links, setLinks] = useState<NavLinkT[]>([]);

  const [selectedBot, setSelectedBot] = useState<string>('');

  const { setSubPanelOpen } = useContext(SideBarContext);

  const [selectedLinks, setSelectedLink] = useState<SideBarT>({
    selectedLink: '',
    selectedSublink: '',
    showSubpanel: false,
    previousLink: false,
  });

  const selectedLink$ = useSignal<Linktext| string>('')

  const selecttedModule$ = useComputed(() => {
    return returnModuleName(selectedLink$.value as Linktext)
  })

  const hasPermission = usePermissionSignal(props.match.params.workspacename, selecttedModule$ , "READ");

  const previousSideBar = usePrevious(selectedLinks) as SideBarT;

  const [subPanelClosedManually, setSubpanelValue] = useState<any>(undefined);

  useLayoutEffect(() => {
    const { defaultDisplayText, defaultSublink, showSubpanel } = (() => {
      const splittedPath = props.match.path.split('/');

      if (splittedPath.length > 0 && splittedPath[0] !== '*') {
        const defaultRoute = links.filter(
          (x) =>
            x.displayText.toLowerCase() ===
            (!splittedPath[3].includes('-')
              ? splittedPath[3].toLowerCase()
              : splittedPath[3].replace('-', ' ').toLowerCase())
        );
        if (defaultRoute.length > 0) {

          return {
            defaultDisplayText: defaultRoute[0].displayText,
            defaultSublink: '',
            showSubpanel:false
          };
        } else {
          const lastvalue = splittedPath.filter(
            (_path: any, i: number) => i + 1 === splittedPath.length
          );
          const lastBefore = splittedPath[splittedPath.length - 2];

          const isPartOfProfileMenu =
            ProfilePathstoShowSelected.indexOf(lastvalue[0]) >= 0 ||
            lastBefore == 'billing';

          // console.log('lastvalue', lastvalue, lastBefore, splittedPath)

          return {
            defaultDisplayText: isPartOfProfileMenu
              ? 'ProfilePaths'
              : SupportPathstoShowSelected.indexOf(lastvalue[0]) >= 0
              ? 'Support'
              : 'Dialog',
            defaultSublink: '',
            showSubpanel:false,
          };
        }
      }
      return {
        defaultDisplayText: 'Dialog',
        defaultSublink: '',
        showSubpanel: false,
      };
    })();

    setSelectedLink({
      selectedLink: defaultDisplayText,
      selectedSublink: "",
      showSubpanel: false,
      previousLink: false,
    });

  }, [props.location.pathname,links]);

  useEffect(() => {
    // console.log('selectedLinks', selectedLinks, previousSideBar)
    selectedLink$.value = selectedLinks.selectedLink
    if (
      selectedLinks.selectedLink === 'Dialog' &&
      (previousSideBar.selectedSublink == '' ||
        previousSideBar.selectedSublink == 'Home')
    ) {
      // console.log(
      //     'selectedLinks ::: setting bot as ',
      //     props.match.params.assistantname ? props.match.params.assistantname : selectedBot
      // )
      setSelectedBot(
        props.match.params.assistantname
          ? props.match.params.assistantname
          : selectedBot
      );
    }
  }, [selectedLinks]);
  const workSpaceName = props.match.params.workspacename;


  var url = new URL(window.location as any);
  var c = url.searchParams.get('showtour');

  // const [showTour, setTourState] = useState(c && c == 'true' ? true : false);
  const [showTour, setTourState] = useState(false);

  let sidePanelWrapperRef = useRef<HTMLDivElement | null>();
  //   let sidePanelDialogIndexRef = useRef<number>(0);
  const scrollToBottom = (index: number) => {
    if (sidePanelWrapperRef.current) {
      setTimeout(() => {
        if (index > 10) {
          //   console.log('DOING :: 1');
          sidePanelWrapperRef.current?.scrollTo({
            top: sidePanelWrapperRef.current?.scrollHeight,
            behavior: 'smooth',
          });
        } else {
          //   console.log('DOING :: 2');
          sidePanelWrapperRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
        }
      }, 0);

      //   scrollTop(1000000);
      //   sidePanelWrapperRef.current?.scrollTo({
      //     top: sidePanelWrapperRef.current?.offsetTop,
      //     behavior: 'smooth',
      //   });
    }

    // sidePanelWrapperRef.current?.scrollIntoView({ behavior: 'smooth' })
  };

  // to use subpanel open value in routes
  // useEffect(() => {
  //   setSubPanelOpen(selectedLinks.showSubpanel);
  // }, [selectedLinks.showSubpanel]);

  useEffect(() => {
    if (isNotAutomate()) {
      callAssistantApi();

     queryClient
      .fetchQuery({
          queryKey : ['user_info'],
          queryFn : () => { 
          return getJson(loginApiURL('/user_info'))
          },
      })
      .then((e) => {
        props.setUserData(e);
      })
      .catch((e) => {
        // console.log(e)
      });
    }
  }, []);
  
  // useEffect(() => {
  //     callAssistantApi()
  // }, [props.match.params])

  // useEffect(() => {
  //   const IntentDetailHeaderClass: any = document.getElementsByClassName(
  //     'intent_header_background'
  //   );
  //   const SubmenuClass = document.getElementsByClassName('closed_sub_menu');
  //   if (SubmenuClass.length > 0 && IntentDetailHeaderClass.length > 0) {
  //   } else if (IntentDetailHeaderClass.length > 0) {
  //   }
  // });

  async function callAssistantApi() {
    const dialog: NavLinkT = {
      displayText: 'Dialog',
      icon: DialogIcon,
      to: 'assistant/homepage',
      active: true,
      submenu: []
    }
   
    const rawLink = getSideBarLinkTree()

    setLinks([...rawLink.slice(0, 1), dialog, ...rawLink.slice(1)]);
    
  }

  const handleChange = (linkText: Linktext,to:LinkRoute) => {
    setSelectedLink({
      ...selectedLinks,
      selectedLink: linkText,
      showSubpanel: false,
      previousLink: selectedLinks.selectedLink as any,
    });

    closeFlowCanvasLeftPanel(true);
    // if(selectedLinks.selectedLink!==linkText && (to == "assistant/homepage" || to == "automation/view/draft")){
      const { match } = props
      props.history.push(`/workspace/${match.params.workspacename}/${to}`)
    // }
  };

  function closeFlowCanvasLeftPanel(close: boolean) {
    const menu: any = document.querySelectorAll(
      '.automation_canvas .flex_side_menu'
    );
    const marketPlaceSideMneu: any = document.querySelectorAll(
      '.market_place_wrapper .flex_side_menu'
    );

    if (close && menu && menu.length > 0) {
      menu[0].style.display = 'none';
    } else if (menu && menu.length > 0) {
      menu[0].style.display = 'block';
    }

    if (close && marketPlaceSideMneu && marketPlaceSideMneu.length > 0) {
      marketPlaceSideMneu[0].style.display = 'none';
    } else if (marketPlaceSideMneu && marketPlaceSideMneu.length > 0) {
      marketPlaceSideMneu[0].style.display = 'block';
    }
  }



  const showSelected = (subLink: subMenu) => {
    const splittedPath = props.match.path.split('/');
    if (subLink.to === 'dialog/homepage') {
      return props.match.path === '/workspace/:workspacename/dialog/homepage';
    } else if (subLink.to.includes('dialog/homepage')) {
      return props.match.params.assistantname === subLink.displayText;
    } else if (subLink.to.includes('dialog/assistant')) {
      return props.match.url.includes('dialog/assistant');
    } else if (subLink.to.includes('dialog/settings')) {
      return props.match.url.includes('dialog/settings');
    } else if (subLink.to.includes('integration/chat/slack')){
      return props.match.url.includes('integration/chat/slack');
    } else if (subLink.to.includes('integration/chat/teams')){
      return props.match.url.includes('integration/chat/teams');
    }
      else if (subLink.to.includes('integration/chat/widget')){
      return props.match.url.includes('integration/chat/widget');
    }
    return (
      selectedLinks.selectedSublink === subLink.displayText &&
      selectedLinks.selectedLink.toLowerCase() ===
        (!splittedPath[3].includes('-')
          ? splittedPath[3].toLowerCase()
          : splittedPath[3].replace('-', ' ').toLowerCase())
    );
  };

  const showBotSelected = (subLink: subMenu) => {
    if(props.match.url.includes('/folder')){
      return subLink.to.includes("/dialogs")
    } else{
      return props.match.url.includes(subLink.to);
    }
  };

  const filterArrary = (links: NavLinkT[], selectedLink: string) => {
    const currentRoute = links.filter((x) => {
      return x.displayText === selectedLinks.selectedLink;
    });
    return currentRoute;
  };

  const menuHeader = (selectedLink: string) => {
    switch (selectedLink) {
      case 'Dialog':
        return 'Chatbot Builder';
      case 'Automation':
        return 'Workflow Builder';
      case 'Integration':
        return 'Omni-channel';
      case 'Train Assistant':
        return 'Training';
      case 'Knowledge':
        return 'Knowledge AI'
      default:
        return selectedLink;
    }
  };

  const filteredSubpanelArray = filterArrary(
    links,
    selectedLinks.selectedLink
  )[0];

  if (showTour)
    return (
      <TourSideBars
        closeTour={() => {
          setTourState(false);
        }}
        {...props}
      />
    );

    function returnModuleName(displayText:Linktext){
      switch (displayText) {
        case "Welcome":
          return "WELCOME_PAGE"
        case 'Dialog':
          return 'CHAT_BOT_MODULE/BOT';
        case 'Automation':
          return 'WORKFLOW_MODULE/WORKFLOWS';
        case 'Integration':
          return 'WORKFLOW_MODULE/WORKFLOWS';
        case 'Train Assistant':
          return 'TRAINING_MODULE';
          // return "WORKFLOW_MODULE/WORKFLOWS"
        case 'Knowledge' :
          return "KNOWLEDGE_AI";
        default:
          // return "WORKFLOW_MODULE/WORKFLOWS/PUBLISHED_WORKFLOWS";
          return "WORKFLOW_MODULE/WORKFLOWS"
    }
  }


  return (
    <React.Fragment>
      <div className="flex_menu">
        <div className="sidepanel_menu_left">
          <div className="sidepanel_menu_left_logo">
            <img src={logo_img} />
          </div>
          <nav>
            <div className="sidepeanel_settings">
              <div className="item-group">
                {links.map((link, index) => {
                 return <CheckUserPermisssionForTab 
                          link={link} 
                          i={index}
                          selectedLinks={selectedLinks}
                          setSelectedLink={setSelectedLink}
                          match={props.match}
                          closeFlowCanvasLeftPanel={closeFlowCanvasLeftPanel}
                          history={props.history}
                          handleChange={handleChange}
                          moduleName={returnModuleName(link.displayText)}
                          key={index}
                       />
                })}
              </div>
              {/* {bottomBar()} */}
            </div>
          </nav>
        </div>
        
      </div>
      {/* {links &&
      selectedLinks.selectedLink !== 'ProfilePaths' &&
      selectedLinks.selectedLink !== 'Support' &&
      selectedLinks.showSubpanel && hasPermission.value ? (
        <div className="flex_side_menu">
          <div className="sidepanel_menu_right">
            <div className="sidepanel_menu_right_head">
              <h4>{menuHeader(selectedLinks.selectedLink)}</h4>
              {menuHeader(selectedLinks.selectedLink) === "Knowledge AI" &&
                <h6>Beta</h6>}
              <span
                className="sub_panel_toggle_btn"
                onClick={() => {
                  setSelectedLink({
                    ...selectedLinks,
                    showSubpanel: !selectedLinks.showSubpanel,
                    selectedLink: selectedLinks.previousLink
                      ? (selectedLinks.previousLink as any)
                      : selectedLinks.selectedLink,
                  });

                  setSubpanelValue(true);

                  closeFlowCanvasLeftPanel(false);
                }}
              >
                <RightArrowWithOutCircle />
              </span>
            </div>
            <div
              className="sidepanel_menu_right_menu"
              ref={sidePanelWrapperRef}
            >
              {filteredSubpanelArray &&
                filteredSubpanelArray.submenu.map(
                  (subLink: subMenu, index: number) => {
                    return (
                      <React.Fragment key={index}>
                        <div
                          className={
                            showSelected(subLink)
                              ? 'side_menu_li side_menu_li_active'
                              : 'side_menu_li'
                          }
                          key={index}
                          onClick={() => {
                            if (selectedLinks.selectedLink === 'Dialog') {
                              setSelectedBot((link) =>
                                link === subLink.displayText
                                  ? ''
                                  : subLink.displayText
                              );
                            }
                            typeof selectedLinks.previousLink !== 'boolean'
                              ? setSelectedLink({
                                  ...selectedLinks,
                                  selectedLink: selectedLinks.previousLink,
                                  selectedSublink: subLink.displayText,
                                })
                              : setSelectedLink({
                                  ...selectedLinks,
                                  selectedSublink: subLink.displayText,
                                });
                            if (subLink.submenu) return;
                            props.history.push(
                              `/workspace/${props.match.params.workspacename}/${subLink.to}`
                            );
                          }}
                        >
                          <subLink.icon />
                          <p>{subLink.displayText}</p>
                          {selectedLinks.selectedLink === 'Dialog' ? (
                            index === 0 ||
                            index ===
                              filteredSubpanelArray.submenu
                                .length ? null : selectedBot ===
                              subLink.displayText ? (
                              <span>
                                <RightArrowWithOutCircle />
                              </span>
                            ) : (
                              <span
                                style={{
                                  transform: 'rotate(-90deg)',
                                }}
                              >
                                <RightArrowWithOutCircle />
                              </span>
                            )
                          ) : null}
                        </div>
                        {subLink.submenu &&
                          selectedBot === subLink.displayText &&
                          subLink.submenu.map(
                            (subLinkchild: subMenu, index: number) => (
                              <div
                                className={
                                  showBotSelected(subLinkchild)
                                    ? 'side_menu_li side_menu_li_center side_menu_li_active'
                                    : 'side_menu_li side_menu_li_center'
                                }
                                key={index}
                                onClick={() => {
                                  typeof selectedLinks.previousLink !==
                                    'boolean'
                                    ? setSelectedLink({
                                        ...selectedLinks,
                                        selectedLink:
                                          selectedLinks.previousLink,
                                        selectedSublink:
                                          subLinkchild.displayText,
                                      })
                                    : setSelectedLink({
                                        ...selectedLinks,
                                        selectedSublink:
                                          subLinkchild.displayText,
                                      });
                                  props.history.push(
                                    `/workspace/${props.match.params.workspacename}/${subLinkchild.to}`
                                  );
                                }}
                              >
                                <subLinkchild.icon />
                                <p>{subLinkchild.displayText}</p>
                              </div>
                            )
                          )}
                      </React.Fragment>
                    );
                  }
                )}
            </div>
          </div>
        </div>
      ) : (
        selectedLinks.selectedLink !== 'ProfilePaths' &&
        selectedLinks.selectedLink !== 'Support' &&
        !props.match.path.includes('/welcome') &&
        !props.match.path.includes('/assistant') &&
        !props.match.path.includes('/automation') &&
        !props.match.path.includes('/automation/detail/') &&
        !props.match.path.includes('/marketplace/automation/') &&
        !props.match.path.includes('/automation/market/detail/') &&
        !props.match.path.includes('/knowledge') &&
        props.match.path !==
          '/workspace/:workspacename/dialog/:assistantname/detail/:dialogue_node'&& hasPermission.value && (
          <div className="flex_side_menu closed_sub_menu">
            <div className="sidepanel_menu_right">
              <div className="sidepanel_menu_right_head">
                <span
                  className="sub_panel_toggle_btn closed_arrow"
                  onClick={() => {
                    setSelectedLink({
                      ...selectedLinks,
                      showSubpanel: !selectedLinks.showSubpanel,
                    });
                    setSubpanelValue(false);
                  }}
                >
                  <RightArrowWithOutCircle />
                </span>
              </div>
            </div>
          </div>
        )
      )} */}
      <Outlet/>
    </React.Fragment>
  );
}

type TabPropsT ={
  link: NavLinkT,
   i: number,
   selectedLinks:SideBarT,
   match:any,
   setSelectedLink:(params:SideBarT)=>void,
   history:any,
   closeFlowCanvasLeftPanel:(params:boolean)=>void,
   handleChange:(linkText: Linktext,to:LinkRoute)=>void
   moduleName:string
}

function CheckUserPermisssionForTab(props:TabPropsT){

  const hasPermission = usePermissions(props.match.params.workspacename, props.moduleName, "READ");
  return props.moduleName === "KNOWLEDGE_AI" ? <Tab {...props}/> : (hasPermission.value)?<Tab {...props}/>:null

}


function Tab(props:TabPropsT) {
  const {i,link,selectedLinks,setSelectedLink,match,closeFlowCanvasLeftPanel,history,handleChange} = props
  function hover(displayText: string) {
    switch (displayText) {
      case 'Dialog':
        return 'BOT';
      case 'Automation':
        return 'Automations';
      default:
        return displayText;
    }
  }
  const activeClass = (linkText: Linktext) => {
    // console.log(linkText,selectedLinks)
    const classList =
      linkText !== selectedLinks.selectedLink
        ? ['item']
        : ['item', 'selected'];
    return classNames(classList);
  };
  
  return (
    <div
      key={i}
      className={activeClass(link.displayText)}
      onClick={() => {
        if (
          link.displayText !== selectedLinks.selectedLink &&
          link.to == 'welcome' &&
          !props.match.path.includes('/welcome')
        ) {
          setSelectedLink({
            ...selectedLinks,
            selectedLink: link.displayText,
            showSubpanel: false,
            previousLink: selectedLinks.selectedLink as any,
          });

          closeFlowCanvasLeftPanel(true);

          props.history.push(`/workspace/${props.match.params.workspacename}/welcome`);
        } else if (link.to != 'welcome') {
          if (
            link.displayText === selectedLinks.selectedLink
          )
            return;
          handleChange(link.displayText,link.to);
        }
      }}
    >
      <div className="img">
        <link.icon />
      </div>
      <span className="side_menu_hvr">{hover(link.displayText)}</span>
    </div>
  );
}

export const Sidebar = SidebarComponent;
