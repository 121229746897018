import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import {
  UserIconAnalyticsMenu,
  InfoIcon,
  EditIcon,
  DeleteIcon,
  BotsIcon,
  DialogIcon,
} from '@/common/Icons/Icons';
import Tooltip from 'react-tooltip-lite';
import {
  BasicUserInfo,
  inviteUser,
  RolesInputT,
  updateRoles,
} from './managerUsers.api';
import { Role, User } from './user.types';
import { Loader } from '@/common/components/Loader';
import { Error } from '@/common/components/ErrorBoundary';
import { AxiosError } from 'axios';
import {
  getJson,
  logEvent,
  loginApiURL,
  patchJson,
  stripeGetApi,
} from '@/common/utils/api-utils';
import { Modal } from '@/common/components/Modal';
import { Input } from '@/common/styled/Dialog.BotDetails.Dumb';
import { WarningAlertIcon } from '@/common/Icons/Workflow.Icons';
import * as R from 'ramda';
import blockedDomains from '../blocked_email';
import parseDomain from 'parse-domain';
import useMangeUsers from './useManageUsers';
import Axios from 'axios';

import { InputContainer } from '@/common/styled/Workflow.Dumb';
import useRoles, {
  cbAdminRole,
  ChatBotContributor,
  containsChatBotAdmin,
  containsChatBotContributor,
  contributorRole,
} from './useRoles';
import Downshift, { useMultipleSelection, useSelect } from 'downshift';
import { useMutation, useQuery } from 'react-query';
import {
  ConfirmationOnActionP,
  ErrorT as ConfirmedActionErr,
} from './manageUsers.types';
import { CurrentUserInfo } from '@/common/utils/utils';
import { getAssistants } from '@/Assistant/Mechanisms/action';
import { BotStartedWrapper } from '@/Flows/Flow';
import { divide, mean } from 'ramda';
import { patchReq } from '@/common/utils/axios-fp.utils';
import { authorizeToken } from '@/common/utils/auth-token-api-utils';
import { ProfileTabs } from '@/SidePanel/Sidebar'

export const InputBox = styled.div`
  width: 100%;
  float: left;
  padding: 22px 7px 8px 12px;
  border: 1px solid rgb(185, 185, 185);
  border-radius: 6px;
  background: rgb(255, 255, 255);
  height: ${(props: any) => (props.value ? `auto` : '48px')};
  font-size: 12px;
  font-family: Gordita-Regular;
  color: #000;
  outline: 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
}
  

    ${CancelButtonInput} {
      left: unset;
    }
  }
`;

export const DropdownLi = styled.div`
  width: 100%;
  float: left;

  .dropdown_list {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #EBEBEB;
    font-size: 12px;
    font-family: 'Gordita-Regular';
    height: 36px;
    padding: 0 10px;
    background: #fff;
    cursor: pointer !important;
    color: #000;

    :hover {
      background: #ddfeef;
      color: #333;
      border-bottom: 1px solid #ddfeef;
    }
  }

  img {
    width: 14px;
    height: 14px;
    margin-right: 7px;
  }

  :first-child {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    background: #fff;
  }
  :last-child {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    background: #fff;
    border-bottom: 0.5px solid #fff;
  }
  :hover {
    background: #ddfeef;
    color: #333;
    // border-bottom: 1px solid #ddfeef;
  }
`;
export const DropdownUl = styled.div`
  width: 100%;
  position: absolute;
  margin-top: 22px;
  right: 0px;
  left: 0%;
  cursor: pointer;
  padding: 0px;
  overflow-y: scroll;
  max-height: 215px;
  margin-bottom: 0px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 6px;
  z-index: 2;
  margin-bottom: 0px;
`;
export const DropdownUlWrapper = styled.div`
  width: 100%;
  float: left;
  position: relative;
  margin-bottom: 0px;
`;

export const TabContainer = styled.div`
  width: 97%;
  float: left;
  display: flex;
  // height: 800px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 5px #00000029;
  border-radius: 10px;
  padding: 18px 20px;
  margin: 20px 20px 10px 20px;
  align-items: baseline;
  flex-direction: column;
  div:first-child {
    display: flex;
    align-items: center;
  }
  h4 {
    color: #000000;
    font-family: 'Gordita-Medium';
    font-size: 16px;
  }
  p {
    color: #355d8b;
    font-family: 'Gordita-Regular';
    font-size: 12px;
    margin-bottom: 0;
  }

  .target.customTip {
    width: 12px;
    height: 12px;
    bottom: 2.5px;
    right: 2px;
    cursor: pointer;
    svg {
      color: #999999;
    }
  }
`;

export const Button = styled.button`
  min-width: 90px;
  height: 32px;
  background: #fff;
  border-radius: 18px;
  border: 1px solid #b9b9b9;
  outline: 0;
  font-size: 12px;
  padding: 7px 12px;
  font-family: 'Gordita-Medium';
  color: #333;
  margin: 10px 0px;
  cursor: pointer;

  :hover {
    background: #ddfeef;
    color: #333;
    border: 1px solid #009d5f;

    label {
      color: #000;
      cursor: pointer;
    }

    svg {
      color: #000;
      fill: #000;
    }
  }
  :active {
    background: #ddfeef !important;
    border: 1px solid #009d5f !important;
    color: #333 !important;
  }

  span {
    width: 18px;
    height: 18px;
    float: left;
    margin-right: 6px;
    display: flex;
    align-items: center;
    position: relative;
    top: 1px;
    cursor: pointer;
    svg {
      width: 18px;
      height: 18px;
      color: #000;
      fill: #000;
      margin-right: 0px;
    }
  }
`;
export const Td = styled.td`
  width: auto;
  float: left;
  font-family: 'Gordita-Regular';
  width: ${(props: any) => `${props.size * 8.33}%` || 'auto'};
  display: flex;
  justify-content: ${(props: any) => (props.contentCenter ? 'center' : 'left')};
  align-items: left;
  flex-direction: column;
  padding-top: 12px;
  padding-bottom: 12px;
  min-height: 64px;
  cursor: pointer;
  .synonyms_p {
    overflow-y: auto;
    white-space: normal;
    word-break: break-all;
    padding-right: 5px;
    max-height: 30px;
    margin-bottom: 0px;
  }
  // button {
  //     color: ${(props: any) => props.color}!important;
  //     border-color: ${(props: any) => props.color}!important;
  // }

  .logs_feedback_content {
    display: block;
    width: 95%;
  }
  span {
    width: 16px;
    height: 16px;
    top: 2px;
    svg {
      width: 12px;
      height: 12px;
      color: #999;
      cursor: pointer;
      margin-left: 4px;
    }
  }
`;
export const Count = styled.span`
  font-family: 'Gordita-Medium';
  color: #ff7900;
  font-size: 10px;
  margin-left: 2px;
`;
export const TdContent = styled.div`
  width: max-content;
  float: left;
  display: flex;
  align-items: center;

  svg {
    width: 8px;
    height: 8px;
    fill: #000;
    margin-left: 5px;
    display: none;
  }
  ${Count} {
    width: 24px;
    height: 24px;
    background: #d5ffee 0% 0% no-repeat padding-box;
    opacity: 1;
    color: #333333;
    font-family: 'Gordita-Regular';
    font-size: 10px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
  }
`;
export const Th = styled.th`
  width: ${(props: any) => `${props.size * 8.33}%` || 'auto'};
  float: left;
  font-size: 14px;
  display: flex;
  justify-content: ${(props: any) => (props.contentCenter ? 'center' : 'left')};
  font-family: 'Gordita-Medium';
  color: #355d8b;
  .tab_container_right {
    display: grid;
    grid-template-columns: 180px auto;
    justify-content: flex-end;
    .prev_icon {
      margin-left: 6px;
      width: 16px;
      height: 16px;
      position: relative;
      top: -1px;
      svg {
        width: 16px;
        height: 16px;
        cursor: pointer;
        fill: #555;
        transform: rotate(90deg);
      }
    }
    .next_icon {
      margin-left: 6px;
      width: 16px;
      height: 16px;
      position: relative;
      top: -1px;
      svg {
        width: 16px;
        height: 16px;
        fill: #555;
        cursor: pointer;
        transform: rotate(-90deg);
      }
    }
    p {
      margin-bottom: 0px;
      display: flex;
      align-items: center;
    }
  }
`;
export const Tr = styled.tr`
  width: 100%;
  float: left;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: flex-start;
  padding-bottom: 0px;
  color: #000;
  :hover {
    border: none !important;

    .td_header .td_editicon {
      display: inline-block;
      position: relative;
    }
  }
`;
export const Thead = styled.thead`
  width: 100%;
  float: left;
  display: flex;

  ${Tr} {
    height: 48px;
    background: transparent linear-gradient(180deg, #ffffff 0%, #f4f4f4 100%) 0%
      0% no-repeat padding-box;
    border-radius: 10px 10px 0px 0px;
    align-items: flex-start;
    padding: 0px 20px;
    padding-top: 20px;
  }
  ${Th} {
    line-height: 14px;
  }
  .is_opacity {
    opacity: 0.2;
    box-shadow: none;
    pointer-events: none;
    width: 100%;
    float: left;
  }
`;
export const Tbody = styled.tbody`
  width: 100%;
  float: left;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
  position: relative;
  overflow-y: scroll;
  height: calc(100vh - 350px);
  border-radius: 10px;

  ${Tr} {
    background: #ffffff 0% 0% no-repeat padding-box;
    padding: 0px 20px;
    min-height: 64px;
    line-height: normal;
    display: flex;
    border-bottom: 1px solid #e0e0e0;
    // :nth-child(1){
    //     border-bottom: none;
    // }

    ${Td} {
      padding-right: 15px;
      font-size: 12px;
      color: #000000;
      justify-content: center;
      :last-child {
        padding-right: 0px;
        justify-content: center;
      }
      :first-child {
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;

        span {
          width: 18px;
          height: 18px;
          float: left;
          margin-right: 7px;
          svg {
            width: 18px;
            height: 18px;
            float: left;
          }
        }
      }
    }

    :hover {
      background: #ddfeef 0% 0% no-repeat padding-box;
      box-shadow: 0px 2px 4px #00000029;
      z-index: 1;
      position: relative;
      top: 0px;

      ${TdContent} {
        svg {
          display: block;
        }
        ${Count} {
          background: #fff;
        }
      }
      .option-input.radio {
        border: 1px solid #2468f6 !important;
        visibility: visible !important;
      }
    }
  }
  .is_active_wrapper {
    background: transparent linear-gradient(180deg, #ffffff 0%, #f4f4f4 100%) 0%
      0% no-repeat padding-box;
    border-radius: 10px 10px 0px 0px;
    box-shadow: 0px 5px 15px #00000033;
    border-radius: 10px;
    display: block;
    width: 100%;
    float: left;
    margin: -1px 0px;

    ${TdContent} {
      svg {
        display: block;
        transform: rotate(90deg);
      }
    }

    :hover {
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 5px 15px #00000033;
      border-radius: 10px;
      ${Tr} {
        // background: #fff 0% 0% no-repeat padding-box;
        box-shadow: 0px 2px 4px #00000029;

        ${TdContent} {
          svg {
            display: block;
          }
          ${Count} {
            background: #d5ffee;
          }
        }
      }
    }
  }

  .is_active_wrapper .is_active_tr {
    background: transparent linear-gradient(180deg, #ffffff 0%, #f4f4f4 100%) 0%
      0% no-repeat padding-box !important;
    border-radius: 10px 10px 0px 0px;

    :hover {
      background: transparent linear-gradient(180deg, #ffffff 0%, #f4f4f4 100%)
        0% 0% no-repeat padding-box;
    }
  }
  .is_opacity_wrapper {
    width: 100%;
    float: left;
  }
  .is_opacity {
    // opacity: 0.5;
    // box-shadow: none;
    // pointer-events: none;
    width: 100%;
    float: left;
  }
`;
export const Table = styled.table`
  width: 100%;
  float: left;
  position: relative;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 5px #00000029;
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
`;
export const TableContainer = styled.div`
  width: 100%;
  float: left;
`;
export const SmallButton = styled.button`
  background: ${(props) => (props.primary ? '#fff' : '#2468f6')};
  border-radius: 18px;
  border: ${(props) =>
    props.primary ? '1px solid #cecece' : '1px solid #2468f6'};
  outline: 0;
  font-size: 12px;
  color: ${(props) => (props.primary ? '#333' : '#fff')};
  font-family: 'Gordita-Regular';
  display: flex;
  align-items: center;
  min-width: 64px;
  height: 24px;
  transition: 0.5s ease-out;
  padding: 0px 10px;
  cursor: pointer;
  justify-content: center;

  label {
    margin-bottom: 0px;
    position: relative;
    top: 1px;
    cursor: pointer;
  }
  :hover {
    fill: #333;
    outline: 0;
    transition: 0.5s ease-in;
    cursor: pointer;
    background: ${(props) => (props.primary ? '#fff' : '#2468f6')};
    border: ${(props) =>
      props.primary ? '1px solid #333' : '1px solid #2468f6'};
    color: ${(props) => (props.primary ? '#333' : '#fff')};
  }
  :active {
    background: ${(props) => (props.primary ? '#fff' : '#2468f6')};
    border: ${(props) =>
      props.primary ? '1px solid #b9b9b9' : '1px solid #2468f6'};
    color: ${(props) => (props.primary ? '#333' : '#fff')};
  }
  :focus {
    background: #ddfeef;
    color: #333;
    border: 1px solid #009d5f;
  }
  span {
    width: 10px;
    height: 10px;
    float: left;
    margin-right: 3px;
    display: flex;
    align-items: center;
    position: relative;
    top: 0px;
    svg {
      width: 10px;
      height: 10px;
      fill: #333;
      color: #333;
    }
  }
`;
const TabelWrapper = styled.div`
  width: 100%;
  float: left;
  ${Table} {
    margin-top: 0px;
  }
  ${Tbody} {
    border-radius: 0px 0px 10px 10px;
    max-height: calc(100vh - 380px);
    height: auto;
    @media (max-width: 1500px) {
      max-height: calc(100vh - 340px);
    }
    ${Tr} {
      height: 48px;
      ${Td} {
        padding: 0px;
        height: 48px;
        font-size: 12px;
        font-family: 'Gordita-Regular';
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        :first-child {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
        }

        .td_header {
          font-size: 12px;
          color: #000;
          line-height: 12px;
          margin-bottom: 6px;
        }
        .td_date {
          font-size: 7px;
          color: #355d8b;
          line-height: 7px;
        }

        .td_cc_img {
          width: 24px;
          height: 24px;
          margin-right: 6px;

          svg {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }
`;

export const InputContent = styled.div`
  color: #999;
  font-size: 12px;
  font-family: 'Gordita-Regular';
  position: absolute;
  pointer-events: none;
  left: 12px;
  top: 18px;
  transition: 0.26s ease-in;
  ${Input}:focus ~ &,
    ${Input}:not(:placeholder-shown).input:not(:focus) ~ .label,
    ${Input}:valid ~ & {
    top: 10px;
    font-size: 10px;
    transition: 0.26s ease-out;
    line-height: 10px;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  float: left;
  position: relative;
  bottom: 0;
  display: flex;
  justify-content: center;
  padding-top: ${(props) => (props.padding ? '10px' : '0px')};
  padding-bottom: 20px;
  margin: 0;

  ${Button} {
    text-align: center;
    justify-content: center;
    margin: 0px;
    :first-child {
      margin-right: 15px;
    }
  }
`;

type UserActions = {
  enableUser: (user: User) => void;
  disableUser: (user: User) => void;
  removeUser: (user: User) => void;
  editRole: (user: User) => void;
};

type UserTableP = {
  users: User[];
  actions: UserActions;
  currentUser?: CurrentUserInfo;
  mfaToggle: boolean;
};

type UserRowP = {
  user: User;
  actions: UserActions;
  currentUser: CurrentUserInfo;
  mfaToggle: boolean;
};

type InvitationPopupP = {
  bots: BotsP[];
  currentUser: any;
  ws: string;
  callbacks: {
    cancel: () => void;
    completed: (userInfo: BasicUserInfo) => null | void;
    failed: (err: Error, user: BasicUserInfo) => any;
  };
  rBACAcceess: boolean;
};

type RoleEditPopupP = {
  bots: BotsP[];
  user: User;
  ws: string;
  callbacks: {
    cancel: () => void;
    completed: () => null | void;
    failed: (err: any) => string;
  };
  botlist: string[];
  adminBotList: string[];
  rBACAcceess: boolean;
};

const capitalize = (string: any) =>
  R.ifElse(
    R.isEmpty,
    R.always(''),
    R.compose(R.flip(R.concat)(R.slice(1, Infinity, string)), R.toUpper, R.head)
  )(string);

const replaceUnAlpha = R.replace(/[^a-zA-Z]/g, '');

const normalize = (name: string) =>
  (name.length &&
    name[0] +
      R.compose<string, string, string>(
        R.toLower,
        R.slice(1, Infinity)
      )(name)) ||
  '';

const allowOnlyAlpha: (val: any) => any = R.ifElse(
  R.isEmpty,
  R.always(''),
  R.compose(normalize, capitalize, replaceUnAlpha)
);

export const isValidEmail = R.test(
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

const getDomain = (url: string) =>
  R.compose<string, any, string, string>(
    R.ifElse(R.startsWith('.'), R.slice(1, Infinity), R.identity),
    R.converge(R.unapply(R.join('.')), [
      R.prop('subdomain'),
      R.prop('domain'),
      R.prop('tld'),
    ]),
    parseDomain
  )(url);

const domainIsExistedInBlackList = (domain: any) => {
  return R.findIndex(R.equals(domain), blockedDomains) !== -1;
};

const isBlockedDomain = R.compose(domainIsExistedInBlackList, getDomain);

export const CancelButtonInput = styled.div`
  width: 16px;
  height: 16px;
  display: flex;
  right: 12px;
  left: inherit;
  position: absolute;
  top: 16px;
  transform: ${(props) => (props.rotate ? 'rotate(90deg)' : 'inherit')};
  svg {
    width: 16px;
    height: 16px;
    color: #555555;
    fill: #555555;
  }
`;

export const DropdownWithCircle = () => (
  <svg
    id="Group_9262"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 16.2 16.2"
    xmlSpace="preserve"
  >
    <style>{'.st00{fill:currentColor}'}</style>
    <g id="Group_8957">
      <path
        id="Path_4530"
        className="st00"
        d="M8.1 16.2C3.6 16.2 0 12.6 0 8.1S3.6 0 8.1 0s8.1 3.6 8.1 8.1-3.6 8.1-8.1 8.1zm0-14.8a6.7 6.7 0 100 13.4 6.7 6.7 0 000-13.4z"
      />
    </g>
    <g id="Group_8958" transform="translate(4.723 6.073)">
      <path
        id="Path_4531"
        className="st00"
        d="M3.4 4c-.2 0-.4 0-.5-.2L.2 1.1C-.1.8 0 .4.2.1c.3-.1.7-.1 1 .1l2.2 2.2L5.6.2c.3-.3.7-.3 1 0s.3.7 0 1L3.8 3.8c-.1.2-.2.2-.4.2z"
      />
    </g>
  </svg>
);

const LoadingPopUp = () => {
  return <Modal showPopupLoader={true} close={() => {}}></Modal>;
};

function ErrorPopup<T>(props: ConfirmedActionErr<T>) {
  return (
    <Modal showPopupLoader={false} close={props.canceled}>
      <React.Fragment>
        {props.err}
        <Button
          primary
          onClick={() => props.doAction(props.data)}
          type="button"
        >
          Retry
        </Button>
      </React.Fragment>
    </Modal>
  );
}

const ConfirmationPopUp = function <T>(props: ConfirmationOnActionP<T>) {
  if (props.isLoading) {
    return <LoadingPopUp />;
  } else if (props.isErr) {
    return <ErrorPopup {...props}></ErrorPopup>;
  } else {
    return (
      <Modal showPopupLoader={false} close={props.canceled}>
        <h2>{props.title}</h2>
        <p>{props.message}</p>
        <ButtonContainer className="pb-0" style={{ marginTop: 20 }}>
          <Button primary onClick={props.canceled} type="button">
            <label>{props.cancelBtn}</label>
          </Button>
          <Button
            onClick={() => props.doAction(props.data)}
            type="button"
            className="remove_uesr"
          >
            <label>{props.actionBtn}</label>
          </Button>
        </ButtonContainer>
      </Modal>
    );
  }
};

type RolesDropDownP = {
  roles: Role[];
  selectedRoles: { [key: string]: boolean };
  addToSelectedRoles: (role: Role) => void;
  removeFromSelectedRoles: (role: Role) => void;
  getSelectedRolesArr: () => Role[];
  containsCBAdmin: boolean;
  containsCBContributor: boolean;
  bots?: BotsP[];
  selectedBots: string[];
  setSelectedBots: (selectedBots:string[])=>void
  selectedAdminBots: string[];
  setselectedAdminBots: (selectedAdminBots: string[])=>void
  rBACAcceess:boolean
};

const RolesDropDown = ({
  roles,
  selectedRoles,
  addToSelectedRoles,
  removeFromSelectedRoles,
  getSelectedRolesArr,
  containsCBAdmin,
  containsCBContributor,
  bots,
  selectedBots,
  setSelectedBots,
  selectedAdminBots,
  setselectedAdminBots,
  rBACAcceess
}: RolesDropDownP) => {
  const { getDropdownProps, selectedItems } = useMultipleSelection({
    selectedItems: getSelectedRolesArr(),
  });
  const [uniqueRoles, setUniqueRoles] = useState<Role[]>([]);
  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    selectedItem: null,
    defaultHighlightedIndex: 0, // after selection, highlight the first item.
    items: uniqueRoles,
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useSelect.stateChangeTypes.MenuKeyDownEnter:
        case useSelect.stateChangeTypes.ToggleButtonKeyDownArrowDown:
        case useSelect.stateChangeTypes.ItemClick:
          return {
            ...changes,
            isOpen: true, // keep the menu open after selection.
          };
        default:
          return changes;
      }
    },
    onStateChange: ({ type, selectedItem: newSelectedItem }) => {
      switch (type) {
        case useSelect.stateChangeTypes.MenuKeyDownEnter:
        case useSelect.stateChangeTypes.MenuKeyDownSpaceButton:
        case useSelect.stateChangeTypes.ItemClick:
          if (newSelectedItem) {
            selectedRoles[newSelectedItem.id]
              ? removeFromSelectedRoles(newSelectedItem)
              : addToSelectedRoles(newSelectedItem);
          }
          break;
        default:
          break;
      }
    },
  });
  const [botDropdown,setBotdropdown]=useState<boolean>(false)
  const selectedArray = roles.filter((r) => {
    return Object.keys(selectedRoles).includes(r.id.toString());
  });
  
  useEffect(()=>{
    !containsCBContributor ? setSelectedBots([]) : setSelectedBots(selectedBots)
    !containsCBAdmin ? setselectedAdminBots([]): setselectedAdminBots(selectedAdminBots)
  },[containsCBContributor,containsCBAdmin])

  useEffect(() => {
    const nonCBCArr: Role[] = roles.filter(
      (r) =>
        r.display_name !== 'Chatbot Contributor' &&
        r.display_name !== 'Chatbot Admin'
    );
    if(rBACAcceess){
      if (selectedArray.length > 0) {
        const selectednameArray = selectedArray.map((s) => s.display_name);

        const uniqueRoleFilter = selectedArray.filter((s) => {
          const checkCBC = selectednameArray.includes('Chatbot Contributor');
          const checkCBAdmin = selectednameArray.includes('Chatbot Admin');
          if (checkCBC && checkCBAdmin) {
            const specificCbc = selectedArray.filter(
              (r) =>
                r.display_name === 'Chatbot Contributor' ||
                r.display_name === 'Chatbot Admin'
            );
            setUniqueRoles(nonCBCArr.concat(specificCbc));
          } else if (checkCBC) {
            const specificCbc = selectedArray.filter(
              (r) => r.display_name === 'Chatbot Contributor'
            );
            setUniqueRoles(nonCBCArr.concat(specificCbc, cbAdminRole()));
          } else if (checkCBAdmin) {
            const specificCbc = selectedArray.filter(
              (r) => r.display_name === 'Chatbot Admin'
            );
            setUniqueRoles(nonCBCArr.concat(specificCbc, contributorRole()));
          } else {
            setUniqueRoles(nonCBCArr.concat(contributorRole(), cbAdminRole()));
          }
        });
      } else {
        setUniqueRoles(nonCBCArr.concat(contributorRole(), cbAdminRole()));
      }
    } else{
      setUniqueRoles(nonCBCArr)
    }
  }, []);
  const botArrayselection = (name: string) => {
    if (selectedBots.includes(name) || selectedAdminBots.includes(name) ) {
      const newArray = selectedBots.filter((item) => item !== name);
      setSelectedBots(newArray);
    } else {
      setSelectedBots([...selectedBots, name]);
    }
  };
  const adminbotArray = (name: string) => {
    if (selectedAdminBots.includes(name) || selectedBots.includes(name) ) {
      const newArray = selectedAdminBots.filter((item) => item !== name);
      setselectedAdminBots(newArray);
    } else {
      setselectedAdminBots([...selectedAdminBots, name]);
    }
  };

  return (
    <div>
      <DropdownUlWrapper className="manage_roles_inputBox" {...getMenuProps()}>
        <InputBox 
        className = {selectedItems.length>0 ? "manage_roles_input":""}
          onClick={()=>{setBotdropdown(true)}}
        >
          {selectedItems.map((s) =>
            // Object.keys(s.meta)?.length > 0 ? (
              <>
                <span>{s.display_name},</span>
                {s.display_name === "Chatbot Contributor" && 
                  selectedBots.map((b) => <span className='manage_selected_bot'><span><DialogIcon /></span><p>{b}</p></span>
                  )
                }
                {s.display_name === "Chatbot Admin" && 
                  selectedAdminBots.map((b)=> <span className='manage_selected_bot'><span><DialogIcon /></span><p>{b}</p></span>)
                } 
              </>
            // ) : (
            //   [s.display_name].join(', ')
            // )
          )}

          <InputContent className={selectedItems.length>0 ? "input_content_active": "input_content"}>Role</InputContent>
          <CancelButtonInput>
            <DropdownWithCircle />
          </CancelButtonInput>
        </InputBox>
        {botDropdown && (
          <Downshift isOpen={true} onOuterClick={() => setBotdropdown(false)}>
          {() => (
          <div
            className="automation_scroly mb-0"
            // style={{ top: 2, position: 'relative' }}
          >
            <DropdownUl style={{ marginTop: 48 }} relative>
              {uniqueRoles.map((role, index) => (
                <DropdownLi
                  key={role.id}
                  className="mangae_role_li"
                  style={
                    highlightedIndex === index
                      ? { backgroundColor: '#ffe5d3' }
                      : {}
                  }
                  {...getItemProps({ item: role, index })}
                >
                  <div className="dropdown_list">
                    <label>
                      <input
                        type="radio"
                        className="option-input radio"
                        checked={selectedRoles[role.id]}
                      />
                    </label>
                    {role.display_name}
                    <Tooltip
                      className="target customTip"
                      zIndex={10000}
                      arrowSize={8}
                      tagName="span"
                      content={role.display_name}
                      distance={5}
                    >
                      <InfoIcon />
                    </Tooltip>
                    {role.display_name === 'Chatbot Contributor' && containsCBContributor && selectedBots.length < 1 &&
                     <Tooltip
                     className="target customTip alert_message"
                     zIndex={10000}
                     arrowSize={8}
                     tagName="span"
                     content={"Select the bot to provide access."}
                     distance={5}
                   >
                     <WarningAlertIcon />
                   </Tooltip>
                    }
                    {role.display_name === 'Chatbot Admin' && containsCBAdmin && selectedAdminBots.length < 1 &&
                     <Tooltip
                     className="target customTip alert_message"
                     zIndex={10000}
                     arrowSize={8}
                     tagName="span"
                     content={"Select the bot to provide access."}
                     distance={5}
                   >
                     <WarningAlertIcon />
                   </Tooltip>
                    }
                  </div>

                  {role.display_name === 'Chatbot Contributor' &&
                    containsCBContributor &&
                    bots?.map((bot) => (
                      <div className="mangae_role_li_box">
                        <div className="sub_ul">
                          <div
                            className={!selectedAdminBots.includes(bot.name) ? "sub_ul_li" : "sub_ul_li selected_li"}
                            onClick={(e) => {
                              e.stopPropagation();
                              botArrayselection(bot.name);
                            }}
                          >
                            <div className='mangae_role_li_selected'>
                              <label>
                                <input
                                  type="radio"
                                  className="option-input radio"
                                  checked={selectedBots.includes(bot.name)}
                                  disabled = {selectedAdminBots.includes(bot.name)}
                                />
                              </label>
                              <div className='manage_roles_icon'>
                                <DialogIcon />
                              </div>
                            {bot.name}
                            </div>
                            <div className='mangae_role_li_selected_toolTip'>
                            { selectedAdminBots.includes(bot.name) &&
                              <Tooltip
                                className="target customTip"
                                zIndex={10000}
                                arrowSize={8}
                                tagName="span"
                                content={"Can’t assign two roles for the same bot"}
                                distance={5}
                              >
                                <WarningAlertIcon />
                              </Tooltip>
                            }
                            </div>
                          </div>

                        </div>
                      </div>
                    ))}
                  {role.display_name === 'Chatbot Admin' &&
                    containsCBAdmin &&
                    bots?.map((bot) => (
                      <div className="mangae_role_li_box">
                        <div className="sub_ul">
                          <div
                            className={!selectedBots.includes(bot.name) ? "sub_ul_li" : "sub_ul_li selected_li"}
                            onClick={(e) => {
                              e.stopPropagation();
                              adminbotArray(bot.name);
                            }}
                          >
                             <div className='mangae_role_li_selected'>
                            <label>
                              <input
                                type="radio"
                                className="option-input radio"
                                checked={selectedAdminBots.includes(bot.name)}
                                disabled={selectedBots.includes(bot.name)}
                              />
                            </label>
                            <div className='manage_roles_icon'>
                                <DialogIcon />
                              </div>
                            {bot.name}
                            </div>
                            <div className='mangae_role_li_selected_toolTip'>
                           
                            { selectedBots.includes(bot.name) &&
                              <Tooltip
                              className="target customTip"
                              zIndex={10000}
                              arrowSize={8}
                              tagName="span"
                              content={"Can’t assign two roles for the same bot"}
                              distance={5}
                            >
                              <WarningAlertIcon />
                            </Tooltip>
                            }
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </DropdownLi>
              ))}
            </DropdownUl>
          </div>
        )}
        </Downshift>
        )}
      </DropdownUlWrapper>
    </div>

  );
};

type RolesEditMutationT = { ws: string; userId: number; roles: RolesInputT };

const EditRolesPopUP = ({
  bots,
  ws,
  user,
  callbacks: { cancel, completed, failed },
  botlist,
  adminBotList,
  rBACAcceess
}: RoleEditPopupP) => {
  const rolesMutation = useMutation<unknown, AxiosError, RolesEditMutationT>(
    ({ ws, userId, roles }: RolesEditMutationT) => {
      return updateRoles(ws, userId, roles);
    }
  );
  const {
    roles,
    queryInfo: rolesQueryInfo,
    selectedRoles,
    addToSelectedRoles,
    removeFromSelectedRoles,
    getSelectedRolesArr,
    containsCBContributor,
    assignedBotList,
    containsCBAdmin,
  } = useRoles(
    ws,
    user.Roles.reduce((map, { id }) => ({ ...map, [id]: true }), {})
  );
  const [botOpen, setBotOpen] = useState<boolean>(false);
  const [selectedBots, setSelectedBots] = useState<string[]>(botlist);
  const [selectedAdminBots, setselectedAdminBots] =
    useState<string[]>(adminBotList);
  const selectedRolesIdArr = getSelectedRolesArr().map(({ id }) => ({
        id,
      }))

  const detailedBotsArray = bots.filter((b) => selectedBots?.includes(b.name));
  const detailedadminbot = bots.filter((b) =>
    selectedAdminBots?.includes(b.name)
  );
  const permissionArray = (array: BotsP[]) => {
    const newArr = array.map((db) => {
      return [
        {
          resource: `CHAT_BOT_MODULE/BOT/${db.id}`,
          access: 'READ',
        },
        {
          resource: `CHAT_BOT_MODULE/BOT/${db.id}/ROOT_DIALOG_NODE/*/CONDITION`,
          access: 'READ.WRITE',
        },
        {
          resource: `CHAT_BOT_MODULE/BOT/${db.id}/ROOT_DIALOG_NODE/*/RESPONSE_NODE`,
          access: 'READ.WRITE.DELETE',
        },
        {
          resource: `CHAT_BOT_MODULE/BOT/${db.id}/SUB_DIALOG_NODE/**`,
          access: 'READ.WRITE.DELETE',
        },
        {
          resource: `CHAT_BOT_MODULE/BOT/${db.id}/INTENTS`,
          access: 'READ.WRITE',
        },
        {
          resource: `CHAT_BOT_MODULE/BOT/${db.id}/INTENTS/*`,
          access: 'READ.WRITE',
        },
        {
          resource: `CHAT_BOT_MODULE/BOT/${db.id}/INTENTS/*/EXAMPLES`,
          access: 'READ.WRITE.DELETE',
        },
        {
          resource: `CHAT_BOT_MODULE/BOT/${db.id}/ENTITY`,
          access: 'READ.WRITE',
        },
        {
          resource: `CHAT_BOT_MODULE/BOT/${db.id}/ENTITY/*`,
          access: 'READ.WRITE',
        },
        {
          resource: `CHAT_BOT_MODULE/BOT/${db.id}/ENTITY/*/ENTITY_VALUE`,
          access: 'READ.WRITE.DELETE',
        },
        {
          resource: `CHAT_BOT_MODULE/BOT/${db.id}/AGENT_BOT`,
          access: 'READ.WRITE.DELETE',
        },
        {
          resource: `CHAT_BOT_MODULE/BOT/${db.id}/SETTINGS`,
          access: 'READ.WRITE',
        },
        {
          resource: `CHAT_BOT_MODULE/BOT/${db.id}/ROOT_DIALOG_NODE`,
          access: 'READ.WRITE',
        },
        {
          resource: `CHAT_BOT_MODULE/BOT/${db.id}/CHATBOT_SUMMARY`,
          access: 'READ',
        },
        {
          resource: `CHAT_BOT_MODULE/BOT/${db.id}/LOGS`,
          access: 'READ',
        },
      ];
    });
    const flatArr = Array.prototype.concat.apply([], newArr);

    return [
      ...flatArr,
      {
        resource: `TRAINING_MODULE/**`,
        access: 'READ',
      },
      {
        resource: `CHAT_BOT_MODULE/BOT`,
        access: 'READ',
      },
      {
        resource: `BOT_MARKET_PLACE`,
        access: 'READ',
      },
    ];
  };

  const permissionArrayforCBAdmin = (array: BotsP[]) => {
    const newArr = array.map((db) => {
      return [
        {
          resource: `CHAT_BOT_MODULE/BOT/${db.id}`,
          access: 'READ',
        },
        {
          resource: `CHAT_BOT_MODULE/BOT/${db.id}/ROOT_DIALOG_NODE/*/CONDITION`,
          access: 'READ.WRITE.DELETE',
        },
        {
          resource: `CHAT_BOT_MODULE/BOT/${db.id}/ROOT_DIALOG_NODE/*/RESPONSE_NODE`,
          access: 'READ.WRITE.DELETE',
        },
        {
          resource: `CHAT_BOT_MODULE/BOT/${db.id}/SUB_DIALOG_NODE/**`,
          access: 'READ.WRITE.DELETE',
        },
        {
          resource: `CHAT_BOT_MODULE/BOT/${db.id}/INTENTS`,
          access: 'READ.WRITE.DELETE',
        },
        {
          resource: `CHAT_BOT_MODULE/BOT/${db.id}/INTENTS/*`,
          access: 'READ.WRITE.DELETE',
        },
        {
          resource: `CHAT_BOT_MODULE/BOT/${db.id}/INTENTS/*/EXAMPLES`,
          access: 'READ.WRITE.DELETE',
        },
        {
          resource: `CHAT_BOT_MODULE/BOT/${db.id}/ENTITY`,
          access: 'READ.WRITE.DELETE',
        },
        {
          resource: `CHAT_BOT_MODULE/BOT/${db.id}/ENTITY/*`,
          access: 'READ.WRITE.DELETE',
        },
        {
          resource: `CHAT_BOT_MODULE/BOT/${db.id}/ENTITY/*/ENTITY_VALUE`,
          access: 'READ.WRITE.DELETE',
        },
        {
          resource: `CHAT_BOT_MODULE/BOT/${db.id}/AGENT_BOT`,
          access: 'READ.WRITE.DELETE',
        },
        {
          resource: `CHAT_BOT_MODULE/BOT/${db.id}/SETTINGS`,
          access: 'READ.WRITE',
        },
        {
          resource: `CHAT_BOT_MODULE/BOT/${db.id}/ROOT_DIALOG_NODE`,
          access: 'READ.WRITE.DELETE',
        },
        {
          resource: `CHAT_BOT_MODULE/BOT/${db.id}/CHATBOT_SUMMARY`,
          access: 'READ',
        },
        {
          resource: `CHAT_BOT_MODULE/BOT/${db.id}/LOGS`,
          access: 'READ',
        },
      ];
    });
    const flatArr = Array.prototype.concat.apply([], newArr);

    return [
      ...flatArr,
      {
        resource: `TRAINING_MODULE/**`,
        access: 'READ',
      },
      {
        resource: `CHAT_BOT_MODULE/BOT`,
        access: 'READ.WRITE.DELETE',
      },
      {
        resource: `BOT_MARKET_PLACE`,
        access: 'READ',
      },
      {
        resource: `ENGINE/PUBLIC_BOT`,
        access: 'READ.WRITE',
      },
    ];
  };

  const selectedBotID = (array: BotsP[]) => {
    const newArr = array.map((a) => a.id);
    return newArr;
  };

  const rolesCBCRemoved: any = selectedRolesIdArr.filter(
    (a) => a.id === 1
  );

  const submit = async function () {
    const chatBotlist = (email: string) => {
      const emailName = email.split('@')[0];
      const randomNum = Math.floor(1000 + Math.random() * 9000);
      const cbcValue = {
        name: `chatbot_contributor_${emailName}_${randomNum}`,
        display_name: 'Chatbot Contributor',
        meta: {
          bot_list: selectedBotID(detailedBotsArray),
          contains_chatbotContributor: containsCBContributor,
          botname_list: selectedBots,
        },
        permissions: permissionArray(detailedBotsArray),
        description:
          'Read and Write access to the chatbot builder (cannot delete, only create and update)',
      };
      return rolesCBCRemoved.concat(cbcValue);
    };
    const chatBotAdminlist = (email: string) => {
      const emailName = email.split('@')[0];
      const randomNum = Math.floor(1000 + Math.random() * 9000);
      const cbcValue = {
        name: `chatbot_admin_${emailName}_${randomNum}`,
        display_name: 'Chatbot Admin',
        meta: {
          bot_list: selectedBotID(detailedadminbot),
          contains_chatbotContributor: false,
          conatins_chatbotAdmin: containsCBAdmin,
          botname_list: selectedAdminBots,
        },
        permissions: permissionArrayforCBAdmin(detailedadminbot),
        description:
          'Access to a particular chatbot (create, update, delete, and publish)',
      };
      return rolesCBCRemoved.concat(cbcValue);
    };
    rolesMutation.mutate(
      {
        ws,
        userId: user.id,
        roles:
          !containsCBAdmin && !containsCBContributor
            ? selectedRolesIdArr
            : containsCBAdmin && containsCBContributor
            ? [chatBotAdminlist(user.email), chatBotlist(user.email)]
            : containsCBAdmin
            ? chatBotAdminlist(user.email)
            : chatBotlist(user.email),
      },
      {
        onSuccess() {
          logEvent(ws, {
            event: 'EVENT::INVITE_USER::INVITE_TO_WORKSPACE',
            event_meta: {},
          });
          completed();
          window.location.reload()
        },
      }
    );
  };

  const err =
    (rolesQueryInfo.error || rolesMutation.error) &&
    failed(rolesQueryInfo.error || rolesMutation.error);

  const errComponent = err ? (
    <React.Fragment>
      {err}
      <Button primary onClick={cancel} type="button">
        <label>Close</label>
      </Button>
    </React.Fragment>
  ) : null;
  const botArrayselection = (name: string) => {
    if (selectedBots.includes(name)) {
      const newArray = selectedBots.filter((item) => item !== name);
      setSelectedBots(newArray);
    } else {
      setSelectedBots([...selectedBots, name]);
    }
  };

  return (
    <Modal
      className="invite_new_user"
      close={!rolesMutation.isLoading ? cancel : R.identity}
      showPopupLoader={rolesMutation.isLoading || rolesQueryInfo.isLoading}
    >
      {errComponent || (
        <React.Fragment>
          <h2>Edit Roles </h2>
          <div className="invite_new_user_box">
            <div className="account_popup_user_input automate">
              <div className="d-flex justify-content-around">
                <div className="form-row">
                  <div className="form-group col-md-12">
                    {/* <label>First Name</label> */}
                    <Input
                      type="text"
                      className="form-control"
                      // placeholder="Enter first name"
                      value={user.first_name}
                    />
                    <InputContent className="input_content">
                      Enter first name
                    </InputContent>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-12">
                    {/* <label>Last Name</label> */}
                    <Input
                      type="text"
                      className="form-control"
                      // placeholder="Enter last name"
                      value={user.last_name}
                    />
                    <InputContent className="input_content">
                      Enter last name
                    </InputContent>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-around">
                <div className="form-row">
                  <div className="form-group col-md-12">
                    {/* <label>Email Address</label> */}
                    <Input
                      type="email"
                      className="form-control"
                      // placeholder="Enter email id"
                      value={user.email}
                    />
                    <InputContent className="input_content invite_email">
                      Enter mail id
                    </InputContent>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <RolesDropDown
                      roles={roles || []}
                      selectedRoles={selectedRoles}
                      addToSelectedRoles={addToSelectedRoles}
                      removeFromSelectedRoles={removeFromSelectedRoles}
                      getSelectedRolesArr={getSelectedRolesArr}
                      containsCBAdmin={containsCBAdmin}
                      containsCBContributor={containsCBContributor}
                      bots={bots}
                      selectedBots={selectedBots}
                      setSelectedBots={setSelectedBots}
                      selectedAdminBots={selectedAdminBots}
                      setselectedAdminBots={setselectedAdminBots}
                      rBACAcceess={rBACAcceess}
                    ></RolesDropDown>
                  </div>
                </div>
              </div>
            </div>

            <ButtonContainer className="invite_user_popup pb-0">
              <Button primary onClick={cancel} type="button">
                <label>Cancel</label>
              </Button>
              <Button
                disabled={selectedRolesIdArr.length < 1  ||  
                  (containsCBContributor && selectedBots.length < 1) ||
                  (containsCBAdmin && selectedAdminBots.length <1)}
                onClick={submit}
                type="button"
                className={selectedRolesIdArr.length < 1  ||  
                  (containsCBContributor && selectedBots.length < 1) ||
                  (containsCBAdmin && selectedAdminBots.length <1) ? "editor_btn_disabled" : "btn_invite" }
              >
                <label>Update</label>
              </Button>
            </ButtonContainer>
          </div>
        </React.Fragment>
      )}
    </Modal>
  );
};
export type BotsP = {
  id: string;
  name: string;
};
const InviteUserPopUp = ({
  bots,
  ws,
  callbacks: { cancel, completed, failed },
  currentUser,
  rBACAcceess
}: InvitationPopupP) => {
  const inviteUserMutation = useMutation<unknown, AxiosError, BasicUserInfo>(
    (userInfo) => {
      return inviteUser(ws, userInfo);
    }
  );
  const [first_name, setFirstName] = useState('');
  const [last_name, setLastName] = useState('');
  const [email, setEmail] = useState('');
  // const [bots, setBots] = useState<BotsP[]>([]);
  const [botOpen, setBotOpen] = useState<boolean>(false);
  const [emailValidation, setEmailValidation] = useState<{
    valid: boolean;
    message: any;
  }>({
    valid: true,
    message: null,
  });
  const [err, setErr] = useState<any>(null);
  const [selectedBots, setSelectedBots] = useState<string[]>([]);
  const [selectedAdminBots, setselectedAdminBots] = useState<string[]>([]);
  const {
    roles,
    queryInfo: rolesQueryInfo,
    selectedRoles,
    addToSelectedRoles,
    removeFromSelectedRoles,
    getSelectedRolesArr,
    containsCBContributor,
    containsCBAdmin,
  } = useRoles(ws);
  const botArrayselection = (name: string) => {
    if (selectedBots.includes(name)) {
      const newArray = selectedBots.filter((item) => item !== name);
      setSelectedBots(newArray);
    } else {
      setSelectedBots([...selectedBots, name]);
    }
  };
  const detailedBotsArray = bots.filter((b) => selectedBots?.includes(b.name));
  const detailedadminbot = bots.filter((b) =>
    selectedAdminBots?.includes(b.name)
  );
  const selectedBotID = (array: BotsP[]) => {
    const newArr = array.map((a) => a.id);
    return newArr;
  };
  const selectedRolesIdArr = useMemo<Pick<Role, 'id'>[]>(
    () =>
      getSelectedRolesArr().map(({ id }) => ({
        id,
      })),
    [selectedRoles]
  );

  const permissionArray = (array: BotsP[]) => {
    const newArr = array.map((db) => {
      return [
        {
          resource: `CHAT_BOT_MODULE/BOT/${db.id}`,
          access: 'READ',
        },
        {
          resource: `CHAT_BOT_MODULE/BOT/${db.id}/ROOT_DIALOG_NODE/*/CONDITION`,
          access: 'READ.WRITE',
        },
        {
          resource: `CHAT_BOT_MODULE/BOT/${db.id}/ROOT_DIALOG_NODE/*/RESPONSE_NODE`,
          access: 'READ.WRITE.DELETE',
        },
        {
          resource: `CHAT_BOT_MODULE/BOT/${db.id}/SUB_DIALOG_NODE/**`,
          access: 'READ.WRITE.DELETE',
        },
        {
          resource: `CHAT_BOT_MODULE/BOT/${db.id}/INTENTS`,
          access: 'READ.WRITE',
        },
        {
          resource: `CHAT_BOT_MODULE/BOT/${db.id}/INTENTS/*`,
          access: 'READ.WRITE',
        },
        {
          resource: `CHAT_BOT_MODULE/BOT/${db.id}/INTENTS/*/EXAMPLES`,
          access: 'READ.WRITE.DELETE',
        },
        {
          resource: `CHAT_BOT_MODULE/BOT/${db.id}/ENTITY`,
          access: 'READ.WRITE',
        },
        {
          resource: `CHAT_BOT_MODULE/BOT/${db.id}/ENTITY/*`,
          access: 'READ.WRITE',
        },
        {
          resource: `CHAT_BOT_MODULE/BOT/${db.id}/ENTITY/*/ENTITY_VALUE`,
          access: 'READ.WRITE.DELETE',
        },
        {
          resource: `CHAT_BOT_MODULE/BOT/${db.id}/AGENT_BOT`,
          access: 'READ.WRITE.DELETE',
        },
        {
          resource: `CHAT_BOT_MODULE/BOT/${db.id}/SETTINGS`,
          access: 'READ.WRITE',
        },
        {
          resource: `CHAT_BOT_MODULE/BOT/${db.id}/ROOT_DIALOG_NODE`,
          access: 'READ.WRITE',
        },
        {
          resource: `CHAT_BOT_MODULE/BOT/${db.id}/CHATBOT_SUMMARY`,
          access: 'READ',
        },
        {
          resource: `CHAT_BOT_MODULE/BOT/${db.id}/LOGS`,
          access: 'READ',
        },
      ];
    });
    const flatArr = Array.prototype.concat.apply([], newArr);

    return [
      ...flatArr,
      {
        resource: `TRAINING_MODULE/**`,
        access: 'READ',
      },
      {
        resource: `CHAT_BOT_MODULE/BOT`,
        access: 'READ',
      },
      {
        resource: `BOT_MARKET_PLACE`,
        access: 'READ',
      },
    ];
  };
  const permissionArrayforCBAdmin = (array: BotsP[]) => {
    const newArr = array.map((db) => {
      return [
        {
          resource: `CHAT_BOT_MODULE/BOT/${db.id}`,
          access: 'READ',
        },
        {
          resource: `CHAT_BOT_MODULE/BOT/${db.id}/ROOT_DIALOG_NODE/*/CONDITION`,
          access: 'READ.WRITE.DELETE',
        },
        {
          resource: `CHAT_BOT_MODULE/BOT/${db.id}/ROOT_DIALOG_NODE/*/RESPONSE_NODE`,
          access: 'READ.WRITE.DELETE',
        },
        {
          resource: `CHAT_BOT_MODULE/BOT/${db.id}/SUB_DIALOG_NODE/**`,
          access: 'READ.WRITE.DELETE',
        },
        {
          resource: `CHAT_BOT_MODULE/BOT/${db.id}/INTENTS`,
          access: 'READ.WRITE.DELETE',
        },
        {
          resource: `CHAT_BOT_MODULE/BOT/${db.id}/INTENTS/*`,
          access: 'READ.WRITE.DELETE',
        },
        {
          resource: `CHAT_BOT_MODULE/BOT/${db.id}/INTENTS/*/EXAMPLES`,
          access: 'READ.WRITE.DELETE',
        },
        {
          resource: `CHAT_BOT_MODULE/BOT/${db.id}/ENTITY`,
          access: 'READ.WRITE.DELETE',
        },
        {
          resource: `CHAT_BOT_MODULE/BOT/${db.id}/ENTITY/*`,
          access: 'READ.WRITE.DELETE',
        },
        {
          resource: `CHAT_BOT_MODULE/BOT/${db.id}/ENTITY/*/ENTITY_VALUE`,
          access: 'READ.WRITE.DELETE',
        },
        {
          resource: `CHAT_BOT_MODULE/BOT/${db.id}/AGENT_BOT`,
          access: 'READ.WRITE.DELETE',
        },
        {
          resource: `CHAT_BOT_MODULE/BOT/${db.id}/SETTINGS`,
          access: 'READ.WRITE',
        },
        {
          resource: `CHAT_BOT_MODULE/BOT/${db.id}/ROOT_DIALOG_NODE`,
          access: 'READ.WRITE.DELETE',
        },
        {
          resource: `CHAT_BOT_MODULE/BOT/${db.id}/CHATBOT_SUMMARY`,
          access: 'READ',
        },
        {
          resource: `CHAT_BOT_MODULE/BOT/${db.id}/LOGS`,
          access: 'READ',
        },
      ];
    });
    const flatArr = Array.prototype.concat.apply([], newArr);

    return [
      ...flatArr,
      {
        resource: `TRAINING_MODULE/**`,
        access: 'READ',
      },
      {
        resource: `CHAT_BOT_MODULE/BOT`,
        access: 'READ.WRITE.DELETE',
      },
      {
        resource: `BOT_MARKET_PLACE`,
        access: 'READ',
      },
      {
        resource: `ENGINE/PUBLIC_BOT`,
        access: 'READ.WRITE',
      },
    ];
  };

  const rolesCBCRemoved: any = selectedRolesIdArr.filter(
    (a) => a.id === 1
  );
  const submit = async function () {
    const chatBotlist = (email: string) => {
      const emailName = email.split('@')[0];
      const randomNum = Math.floor(1000 + Math.random() * 9000);
      const cbcValue = {
        name: `chatbot_contributor_${emailName}_${randomNum}`,
        display_name: 'Chatbot Contributor',
        meta: {
          bot_list: selectedBotID(detailedBotsArray),
          contains_chatbotContributor: containsCBContributor,
          botname_list: selectedBots,
        },
        permissions: permissionArray(detailedBotsArray),
        description:
          'Read and Write access to the chatbot builder (cannot delete, only create and update)',
      };
      return rolesCBCRemoved.concat(cbcValue);
    };
    const chatBotAdminlist = (email: string) => {
      const emailName = email.split('@')[0];
      const randomNum = Math.floor(1000 + Math.random() * 9000);
      const cbcValue = {
        name: `chatbot_admin_${emailName}_${randomNum}`,
        display_name: 'Chatbot Admin',
        meta: {
          bot_list: selectedBotID(detailedadminbot),
          contains_chatbotContributor: false,
          conatins_chatbotAdmin: containsCBAdmin,
          botname_list: selectedAdminBots,
        },
        permissions: permissionArrayforCBAdmin(detailedadminbot),
        description:
          'Access to a particular chatbot (create, update, delete, and publish)',
      };
      return rolesCBCRemoved.concat(cbcValue);
    };
    const userInfo: BasicUserInfo = {
      first_name: first_name.length ? first_name : undefined,
      last_name: last_name.length ? last_name : undefined,
      email,
      roles:
        !containsCBAdmin && !containsCBContributor
          ? selectedRolesIdArr
          : containsCBAdmin && containsCBContributor
          ? [chatBotAdminlist(email), chatBotlist(email)]
          : containsCBAdmin
          ? chatBotAdminlist(email)
          : chatBotlist(email),
    };
    inviteUserMutation.mutate(userInfo, {

      onSuccess() {
        logEvent(ws, {
          event: 'EVENT::INVITE_USER::INVITE_TO_WORKSPACE',
          event_meta: {},
        });
        completed(userInfo);
        window.location.reload()
      },
      onError:(e)=> {
        const error = e.response?.data.message
        const errCap = error.charAt(0).toUpperCase() + error.slice(1).toLowerCase();
        setErr(failed(errCap, userInfo));
      },
    });
  };

  const errComponent = err ? (
    <React.Fragment>
      {err}
      <Button primary onClick={cancel} type="button">
        <label>Close</label>
      </Button>
    </React.Fragment>
  ) : null;

  return (
    <Modal
      className="invite_new_user"
      close={!inviteUserMutation.isLoading ? cancel : R.identity}
      showPopupLoader={inviteUserMutation.isLoading || rolesQueryInfo.isLoading}
    >
      {errComponent || (
        <React.Fragment>
          <h2>Invite New User </h2>
          <div className="invite_new_user_box">
            <div className="account_popup_user_input automate">
              <div className="d-flex justify-content-around">
                <div className="form-row">
                  <div className="form-group col-md-12">
                    {/* <label>First Name</label> */}
                    <Input
                      type="text"
                      className="form-control"
                      // placeholder="Enter first name"
                      value={first_name}
                      onChange={(e: any) => {
                        setFirstName(allowOnlyAlpha(e.target.value));
                      }}
                    />
                    <InputContent className="input_content">
                      Enter first name
                    </InputContent>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-12">
                    {/* <label>Last Name</label> */}
                    <Input
                      type="text"
                      className="form-control"
                      // placeholder="Enter last name"
                      value={last_name}
                      onChange={(e: any) => {
                        setLastName(allowOnlyAlpha(e.target.value));
                      }}
                    />
                    <InputContent className="input_content">
                      Enter last name
                    </InputContent>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-around">
                <div className="form-row">
                  <div className="form-group col-md-12">
                    {/* <label>Email Address</label> */}
                    <Input
                      type="email"
                      className={
                        email.length > 0
                          ? 'form-control have_value'
                          : 'form-control'
                      }
                      // placeholder="Enter email id"
                      value={email}
                      onChange={(e: any) => {
                        const email = e.target.value;
                        const formatIsValid = isValidEmail(email);
                        const emailIsSameAsLoggedInUser =
                          currentUser.email === email;
                        const isValid =
                          formatIsValid &&
                          !emailIsSameAsLoggedInUser &&
                          !isBlockedDomain(email);
                        setEmailValidation({
                          valid: isValid,
                          message: formatIsValid
                            ? emailIsSameAsLoggedInUser
                              ? "You can't invite yourself"
                              : 'Only corporate emails are allowed'
                            : 'Email is invalid',
                        });
                        setEmail(email);
                      }}
                    />
                    <InputContent className="input_content invite_email">
                      Enter mail id
                    </InputContent>
                    {!emailValidation.valid && (
                      <p className="error_msg">{emailValidation.message}</p>
                    )}
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-12">
                    {/* <label>Last Name</label> */}
                    <RolesDropDown
                      roles={roles || []}
                      selectedRoles={selectedRoles}
                      addToSelectedRoles={addToSelectedRoles}
                      removeFromSelectedRoles={removeFromSelectedRoles}
                      getSelectedRolesArr={getSelectedRolesArr}
                      containsCBAdmin={containsCBAdmin}
                      containsCBContributor={containsCBContributor}
                      bots={bots}
                      selectedBots={selectedBots}
                      setSelectedBots={setSelectedBots}
                      selectedAdminBots={selectedAdminBots}
                      setselectedAdminBots={setselectedAdminBots}
                      rBACAcceess={rBACAcceess}
                    ></RolesDropDown>
                  </div>
                </div>
              </div>
            </div>

            <ButtonContainer className="invite_user_popup pb-0">
              <Button primary onClick={cancel} type="button">
                <label>Cancel</label>
              </Button>
              <Button
                disabled={
                  !isValidEmail(email) ||
                  selectedRolesIdArr.length < 1 ||  
                  (containsCBContributor && selectedBots.length < 1) ||
                  (containsCBAdmin && selectedAdminBots.length <1)
                }
                onClick={submit}
                type="button"
                className={first_name === "" || last_name === "" || !isValidEmail(email) ||
                  selectedRolesIdArr.length < 1  ||  
                  (containsCBContributor && selectedBots.length < 1) ||
                  (containsCBAdmin && selectedAdminBots.length <1) ? "editor_btn_disabled btn_invite" : "btn_invite" }
              >
                <label>Send Invite</label>
              </Button>
            </ButtonContainer>
          </div>
        </React.Fragment>
      )}
    </Modal>
  );
};

function UserRow({ user, actions, currentUser, mfaToggle }: UserRowP) {
  const isOwner = user.id === user.AccessibleWorkspaces[0].ownerId;
  const roles = user.Roles;

  return (
    <Tr>
      <Td size={2} onClick={() =>
        currentUser.id !== user.id && !isOwner && actions.editRole(user)
      }>
        <div className="td_user_container">
          <div className="td_user_img">
            {' '}
            <UserIconAnalyticsMenu />{' '}
          </div>
          <div className="td_user_mail">{user.email}</div>
        </div>
      </Td>

      <Td size={1.2} onClick={() =>
        currentUser.id !== user.id && !isOwner && actions.editRole(user)
      }>
        <div className="td_header">{user.first_name}</div>
      </Td>
      <Td size={1.2} onClick={() =>
        currentUser.id !== user.id && !isOwner && actions.editRole(user)
      }>
        <div className="td_header">{user.last_name}</div>
      </Td>
      <Td size={2} onClick={() =>
        currentUser.id !== user.id && !isOwner && actions.editRole(user)
      }>
        <div className="td_user_container">
          <div className="position-relative d-flex flex-column">
            {user.Roles.map((role) => (
              <div
                className="p-1  d-flex align-items-center"
                key={role.id}
                onClick={() =>
                  currentUser.id !== user.id &&
                  !isOwner &&
                  actions.editRole(user)
                }
              >
                {role.display_name}
                {/* <Tooltip
                  className="target customTip"
                  zIndex={10000}
                  arrowSize={8}
                  tagName="span"
                  content={role.display_name}
                  distance={8}
                >
                  <InfoIcon />
                </Tooltip> */}
                {/* <span className="td_editicon">
                  {currentUser.id !== user.id && !isOwner && <EditIcon />}
                </span> */}
                {role.display_name === 'chatbot_contributor' &&
                role.meta?.bot_list?.length === 0 ? (
                  <Tooltip
                    className="target customTip"
                    styles={{ fill: '#EB6453' }}
                    zIndex={10000}
                    arrowSize={8}
                    tagName="span"
                    content={
                      'Chatbot contributor has no access to any chatbot.'
                    }
                    distance={5}
                  >
                    <WarningAlertIcon />
                  </Tooltip>
                ) : (
                  ''
                )}
                {role.display_name === 'Chatbot Contributor' ||
                role.display_name === 'Chatbot Admin' ? (
                  <Tooltip
                    className="target customTip manage_user_bots"
                    styles={{ fill: '#EB6453' }}
                    zIndex={10000}
                    arrowSize={8} 
                    tagName="span"
                    content={contributorList(role)}
                    distance={5}
                  >
                    <span className="manage_user_bots_btn">Bots</span>
                  </Tooltip>
                ) : (
                  ''
                )}
              </div>
            ))}
          </div>
        </div>
      </Td>
      <Td size={1.2} onClick={() =>
        currentUser.id !== user.id && !isOwner && actions.editRole(user)
      }>
        <div className="td_header">
          {user.MobileNumberVsUser &&
            `+${user.MobileNumberVsUser.code} ${user.MobileNumberVsUser.mobileNo}`}
        </div>
      </Td>
      <Td size={1.3} onClick={() =>
        currentUser.id !== user.id && !isOwner && actions.editRole(user)
      }>
        <div className="td_header">
          {user.CountryCode && user.CountryCode.name}
        </div>
      </Td>
      <Td size={0.5}>
        <div className="td_header">
          <div className="show_hide">
            <div className="switch_box box_1">
              <input
                type="checkbox"
                style={{ opacity: 0.5 }}
                className={`switch_1 widget_switch`}
                checked={mfaToggle}
                disabled
              />
            </div>
          </div>
        </div>
      </Td>
      <Td size={2}>
        <div className="td_header">
          {
            <UserActiveStatus
              user={user}
              actions={{ ...actions }}
              isOwner={isOwner}
              currentUser={currentUser.id === user.id}
            ></UserActiveStatus>
          }
        </div>
      </Td>
      <Td size={0.6}>
        {currentUser.id !== user.id && !isOwner && (
          <div className="list-view-icon td_header">
            <span
              className="td_editicon"
              onClick={(e) => {
                e.stopPropagation();
                actions.editRole(user);
              }}
            >
              {currentUser.id !== user.id && !isOwner && <EditIcon />}
            </span>
            <span
              className="td_editicon"
              onClick={(e) => {
                e.stopPropagation();
                actions.removeUser(user);
              }}
            >
              <DeleteIcon />
            </span>
          </div>
          // <SmallButton primary onClick={() => actions.removeUser(user)}>
          //   <label>Remove user</label>
          // </SmallButton>
        )}
      </Td>
    </Tr>
  );
}

function UserActiveStatus({
  user,
  actions,
  isOwner,
  currentUser,
}: {
  user: User;
  actions: UserActions;
  isOwner: boolean;
  currentUser: boolean;
}) {
  if (isOwner) {
    return (
      <div className="manage_user_status_section active_user">
        <div className="show_hide">
          <div className="switch_box box_1 empty"></div>
        </div>
        <span>Workpace Owner</span>
      </div>
    );
  } else if (currentUser) {
    return (
      <div className="manage_user_status_section active_user">
        <div className="show_hide">
          <div className="switch_box box_1"></div>
        </div>
        <span>Has workspace access</span>
      </div>
    );
  } else{
  return user.has_password ? (
    user.AccessibleWorkspaces[0].WorkspaceAccess.valid ? (
      <div className="manage_user_status_section active_user">
        <div className="show_hide">
          <div className="switch_box box_1">
            <input
              type="checkbox"
              className={`switch_1 disabled`}
              checked={true}
              onChange={() => actions.disableUser(user)}
            />
          </div>
        </div>
        <span>Has workspace access</span>
      </div>
    ) : (
      <div className="manage_user_status_section inactive_user">
        <div className="show_hide">
          <div className="switch_box box_1">
            <input
              type="checkbox"
              className={`switch_1`}
              checked={false}
              onChange={() => actions.enableUser(user)}
            />
          </div>
        </div>
        <span>No Workspace Access</span>
      </div>
    )
  ) : (
    <span className="td_user_invited">Invited</span>
  );
  }
}

function UsersTable({ users, actions, currentUser, mfaToggle }: UserTableP) {
  return currentUser ? (
    <Table>
      <Thead>
        <Tr>
          <Th size={2}>User email</Th>
          <Th size={1.2}>First name</Th>
          <Th size={1.2}>Last name</Th>
          <Th size={2}>Role (Beta)</Th>
          <Th size={1.2}>Phone number</Th>
          <Th size={1.3}>Country</Th>
          <Th size={0.5}>MFA</Th>
          <Th size={2}>Status</Th>
          <Th size={0.6}></Th>
        </Tr>
      </Thead>
      <Tbody>
        {users.map((user) => (
          <UserRow
            {...{ user }}
            key={user.id}
            actions={{ ...actions }}
            currentUser={currentUser}
            mfaToggle={mfaToggle}
          />
        ))}
      </Tbody>
    </Table>
  ) : (
    'Loading'
  );
}

type ManageUsersWithRolesP = {
  match: {
    params: {
      workspacename: string;
    };
  };
};

export const ManageUsersWithRoles = function (props: ManageUsersWithRolesP) {
  const workspace = props.match.params.workspacename;
  const {
    invitePopUp,
    users,
    noOfUsersAllowed,
    currentUser,
    newUserAdded,
    queryInfo: { loading, error, isError },
    confirmationPopup,
    disableUser,
    enableUser,
    removeUser,
    editRolesPopup,
    editRole,
    invalidateWsUsers,
  } = useMangeUsers(workspace);

  const _multiStepCached = useQuery(
    'users::mfa-access',
    () => stripeGetApi(`${workspace}/info/MFA_ACCESS`),
    {
      onSuccess: (response) => {
        //console.log(response,'response')
      },
      staleTime: 0,
      refetchOnMount: true,
    }
  );
  const mfaAllowed = 
    _multiStepCached.isSuccess && _multiStepCached.data.output == 'yes';
  const [bots, setBots] = useState<BotsP[]>([]);
  useEffect(() => {
    getAssistants(workspace)
      .then((res) => {
        const bots = res.map((b) => ({ id: b.id, name: b.name }));
        setBots(bots);
      })
      .catch((err) => console.log('Err::', err));
  }, []);

  const [mfaToggle, setMfaToggle] = useState<boolean>(false);

  const [mfaactivation, setMfaActivation] = useState<boolean>(false);
  const [mfaDeactivation, setMfaDeactivation] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const [mfaSuccess, setMfasuccess] = useState<boolean>(false);
  const [mfaRemoved, setMfaRemoved] = useState<boolean>(false);
  const [otpError, setOtpError] = useState<boolean>(false);

  useEffect(() => {
    getJson(loginApiURL(`/${workspace}/mFAActiveStatusInWS`))
      .then((res) => setMfaToggle(res.is_mfa_active))
      .catch((e) => {
        console.log(e);
      });
  }, [currentUser]);

  if (loading) {
    return <Loader.PageLoader show={true} />;
  } else if (isError) {
    const errCode = error && error.response ? error.response.status : 500;
    return errCode >= 400 && errCode < 500 ? (
      <Error.Unauthorized />
    ) : (
      <Error.PageLoadingError
        btnName="Retry Now"
        onClick={() => window.location.reload()}
      />
    );
  }

  const inviteUserProperties: InvitationPopupP = {
    bots: bots,
    currentUser,
    ws: workspace,
    callbacks: {
      cancel: invitePopUp.close,
      failed: (err) => err,
      completed: () => {
        newUserAdded();
      },
    },
    rBACAcceess:mfaAllowed
  };

  const onSave = (password: string) => {
    const postValue = {
      password: `${password}`,
      is_mfa_active: mfaToggle,
    };
    patchJson(loginApiURL(`/${workspace}/mFAActiveStatusInWS`), postValue)
      .then((res) => {
        Axios.get(loginApiURL('/auth-tokens/access_token'), {
          withCredentials: true,
        }).then((data) => {
          const token = data.data;
          authorizeToken(token);
          setMfaActivation(false);
          setMfaDeactivation(false);
          mfaToggle ? setMfasuccess(true) : setMfaRemoved(true);
          setOtpError(false);
          setPassword('');
        });
      })
      .catch((e) => {
        setOtpError(true);
        setPassword('');
      });
  };
  const botlist_cbc = (user: User) => {
    const Roles = user.Roles;
    const conatinsCBC = containsChatBotContributor(Roles);
    if (conatinsCBC) {
      const newArray = Roles.filter(
        (a) => a.display_name === 'Chatbot Contributor'
      )[0]?.meta?.botname_list;
      return Roles.filter((r) => r.display_name === 'Chatbot Contributor')[0]
        .meta.botname_list;
    } else {
      return [];
    }
  };
  const botlist_cbAdmin = (user: User) => {
    const Roles = user.Roles;
    const conatinscbAdmin = containsChatBotAdmin(Roles);
    if (conatinscbAdmin) {
      const newArray = Roles.filter(
        (a) => a.display_name === 'Chatbot Admin'
      )[0]?.meta?.botname_list;
      return Roles.filter((r) => r.display_name === 'Chatbot Admin')[0].meta
        .botname_list;
    } else {
      return [];
    }
  };
  return (
    <React.Fragment>
      <section className="tour_automation_canvas manage_user_new">
        <div className="header_box_shadow" />
        <div className="flow_canvas1">
          {/* <div className="flex_canvas1"> */}
            <div className="flex_menu1 flow_group_home">
              <div className="indent_dialog_left">
                <div className="indent_left">
                  <div className="indent_title mb-0">Manage Users</div>
                </div>
                <div className="indent_right">
                  <div className="btn_width_wrapper">
                    {users &&
                      noOfUsersAllowed &&
                      users.length < noOfUsersAllowed && (
                        <Button
                          className="btn btn-primary"
                          onClick={invitePopUp.open}
                        >
                          <span className="header_button">
                            <svg
                              height="512pt"
                              viewBox="0 0 512 512"
                              width="512pt"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="m512 412c0 11.046875-8.953125 20-20 20h-60v60c0 11.046875-8.953125 20-20 20s-20-8.953125-20-20v-60h-60c-11.046875 0-20-8.953125-20-20s8.953125-20 20-20h60v-60c0-11.046875 8.953125-20 20-20s20 8.953125 20 20v60h60c11.046875 0 20 8.953125 20 20zm-160 80c0 11.046875-8.953125 20-20 20h-272c-33.085938 0-60-26.914062-60-60v-37c0-34.515625 14.804688-67.3125 40.613281-89.988281 20.894531-18.363281 60.910157-45.578125 125.238281-59.722657-35.15625-27.082031-57.851562-69.582031-57.851562-117.289062 0-81.605469 66.394531-148 148-148s148 66.394531 148 148-66.394531 148-148 148c-109.902344 0-168.777344 41.300781-188.984375 59.058594-17.167969 15.085937-27.015625 36.929687-27.015625 59.941406v37c0 11.027344 8.972656 20 20 20h272c11.046875 0 20 8.953125 20 20zm-96-236c59.550781 0 108-48.449219 108-108s-48.449219-108-108-108-108 48.449219-108 108 48.449219 108 108 108zm0 0" />
                            </svg>
                          </span>
                          <label>Invite User</label>
                        </Button>
                      )}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex_side_menu1 intent_home flow_group_home">
              <div className="intent_dialog_right" />
                  <ProfileTabs {...props as any}/>
            </div>
          {/* </div> */}
        </div>
      </section>
      <TabContainer>
        <div>
          <h4>
            Protect your Workativ account with Multi-Factor Authentication{' '}
          </h4>
          <div className="show_hide">
            {!currentUser.verified || !mfaAllowed ? (
              <div
                className="switch_box box_1 not_verified"
                style={{ marginLeft: 10 }}
              >
                <input
                  type="checkbox"
                  className={`switch_1`}
                  // style={{opacity:0.2}}
                  disabled
                />
                <Tooltip
                  className="target customTip"
                  zIndex={10000}
                  arrowSize={8}
                  tagName="span"
                  content={
                    !mfaAllowed
                      ? 'This feature is only available for Enterprise plan.'
                      : 'Verify email to enable MFA'
                  }
                  distance={5}
                >
                  <InfoIcon />
                </Tooltip>
              </div>
            ) : (
              <div className="switch_box box_1" style={{ marginLeft: 10 }}>
                {mfaToggle === false ? (
                  <input
                    type="checkbox"
                    className={`switch_1`}
                    style={{ top: '-6px' }}
                    onClick={() => {
                      setMfaToggle(true);
                      setMfaActivation(true);
                      setMfaDeactivation(false);
                    }}
                  />
                ) : (
                  <input
                    type="checkbox"
                    className={`switch_1`}
                    onClick={() => {
                      setMfaToggle(false);
                      setMfaActivation(false);
                      setMfaDeactivation(true);
                    }}
                    checked
                  />
                )}
              </div>
            )}
          </div>
        </div>
        <p>
          Each time users sign in to your Workativ Assistant, they will need a
          password and a verification code.
        </p>
      </TabContainer>

      {/* // }) */}
      {/* } */}

      <section className="cogni_cards sup_acc_user_details z-index-1-important manage_uesr_table">
        <div className="w-100 float-left">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="sup_acc_user_details_header">
                {/* <div className="manage_user_line d-flex align-items-baseline w-100 float-left">
                  <h5>Users details</h5>
                </div> */}
                <TabelWrapper>
                  <TableContainer>
                    {users && (
                      <UsersTable
                        users={users}
                        actions={{
                          disableUser,
                          enableUser,
                          removeUser,
                          editRole,
                        }}
                        currentUser={currentUser}
                        mfaToggle={mfaToggle}
                      />
                    )}
                  </TableContainer>
                </TabelWrapper>
              </div>
            </div>
          </div>
        </div>
      </section>
      {mfaactivation === true && currentUser && (
        <Modal className="mfa_password_popup">
          <section className="mfa_password_container">
            <h2>Multi-Factor Authentication</h2>
            <p>To continue, please enter your password</p>
            <InputContainer>
              <Input primary type="text" value={currentUser.email} />
              <InputContent>Email adderess</InputContent>
            </InputContainer>
            <InputContainer>
              <Input
                primary
                type="password"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
              <InputContent>Confirm password</InputContent>
              {otpError === true && (
                <span className="info_icon info_icon_Adaptive alerticon">
                  <Tooltip
                    className="target customTip"
                    zIndex={10000}
                    arrowSize={8}
                    tagName="span"
                    content={'Wrong Password. Please try again'}
                    distance={5}
                  >
                    <WarningAlertIcon />
                  </Tooltip>
                </span>
              )}
            </InputContainer>
            <ButtonContainer style={{ padding: 0 }}>
              <div className="popup_footer_button_container">
                <Button
                  style={{ margin: 0 }}
                  className={password.length < 8 ? 'editor_btn_disabled' : ''}
                  type="button"
                  onClick={() => {
                    onSave(password);
                  }}
                >
                  Continue
                </Button>
              </div>
              <Button
                style={{ margin: '0 20px 0 0' }}
                primary
                type="button"
                onClick={() => {
                  setMfaToggle(false);
                  setMfaActivation(false);
                  setOtpError(false);
                }}
              >
                Cancel
              </Button>
            </ButtonContainer>
          </section>
        </Modal>
      )}
      {mfaDeactivation === true && currentUser && (
        <Modal className="mfa_password_popup">
          <section className="mfa_password_container">
            <h2>Disable Multi-Factor Authentication</h2>
            <p>
              To continue, plesae enter your password.<br></br>
              This will disable Multi-Factor Authentication entirely
            </p>
            <InputContainer>
              <Input primary type="text" value={currentUser.email} />
              <InputContent>Email adderess</InputContent>
            </InputContainer>
            <InputContainer>
              <Input
                primary
                type="password"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
              <InputContent>Confirm password</InputContent>
              {otpError === true && (
                <span className="info_icon info_icon_Adaptive alerticon">
                  <Tooltip
                    className="target customTip"
                    zIndex={10000}
                    arrowSize={8}
                    tagName="span"
                    content={'Wrong Password. Please try again'}
                    distance={5}
                  >
                    <WarningAlertIcon />
                  </Tooltip>
                </span>
              )}
            </InputContainer>
            <ButtonContainer style={{ padding: 0 }}>
              <div className="popup_footer_button_container">
                <Button
                  style={{ margin: 0 }}
                  className={password.length < 8 ? 'editor_btn_disabled' : ''}
                  // className={false ? 'ditor_btn_disabled' : ''}
                  type="button"
                  onClick={() => {
                    onSave(password);
                  }}
                >
                  Continue
                </Button>
              </div>
              <Button
                style={{ margin: '0 20px 0 0' }}
                primary
                type="button"
                onClick={() => {
                  setMfaToggle(true);
                  setMfaDeactivation(false);
                  setOtpError(false);
                }}
              >
                Cancel
              </Button>
            </ButtonContainer>
          </section>
        </Modal>
      )}
      {mfaSuccess === true && (
        <Modal className="mfa_password_popup">
          <section className="mfa_password_container mfa_enable">
            <h2>Multi-Factor Authentication</h2>
            <div className="mfa_password_img">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 48 48"
                xmlSpace="preserve"
              >
                <path
                  d="M26 2.3C12.7 2.3 2 13 2 26.3s10.7 24 24 24 24-10.7 24-24c0-13.2-10.8-24-24-24zm13 17.6l-14.9 15c-.5.5-1.4.6-2 .2L13 27.3c-.6-.6-.7-1.5-.1-2.1.5-.6 1.4-.7 2.1-.1l8.1 6.9L37 18c.6-.6 1.5-.6 2 0 .7.5.6 1.3 0 1.9z"
                  fill="#20ce88"
                  transform="translate(-2 -2.3)"
                />
              </svg>
              <p>Successfully enabled</p>
            </div>
            <p>
              Hereafter, all your workspace users will need to enter their
              password and verification codes to log in.
            </p>
            <Button
              // style={{margin: '0 20px 0 0'}}
              primary
              type="button"
              onClick={() => {
                setMfasuccess(false);
              }}
            >
              Done
            </Button>
          </section>
        </Modal>
      )}
      {mfaRemoved === true && (
        <Modal className="mfa_password_popup">
          <section className="mfa_password_container disable_mfa">
            <h2>Disable Multi-Factor Authentication</h2>
            <div className="mfa_password_img">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 48 48"
                xmlSpace="preserve"
              >
                <path
                  d="M26 2.3C12.7 2.3 2 13 2 26.3s10.7 24 24 24 24-10.7 24-24c0-13.2-10.8-24-24-24zm13 17.6l-14.9 15c-.5.5-1.4.6-2 .2L13 27.3c-.6-.6-.7-1.5-.1-2.1.5-.6 1.4-.7 2.1-.1l8.1 6.9L37 18c.6-.6 1.5-.6 2 0 .7.5.6 1.3 0 1.9z"
                  fill="#c3c3c3"
                  transform="translate(-2 -2.3)"
                />
              </svg>
            </div>
            <p>Multi-Factor Authentication disabled</p>
            <Button
              // style={{margin: '0 20px 0 0'}}
              primary
              type="button"
              onClick={() => {
                setMfaRemoved(false);
              }}
            >
              Done
            </Button>
          </section>
        </Modal>
      )}
      {invitePopUp.show && <InviteUserPopUp {...inviteUserProperties} />}
      {editRolesPopup.rolesPopup && bots.length > 0 && (
        <EditRolesPopUP
          bots={bots}
          user={editRolesPopup.rolesPopup.user}
          ws={editRolesPopup.rolesPopup.ws}
          callbacks={{
            completed: () => {
              invalidateWsUsers();
              editRolesPopup.close();
            },
            failed: (e) => e.message,
            cancel: editRolesPopup.close,
          }}
          botlist={botlist_cbc(editRolesPopup.rolesPopup.user)}
          adminBotList={botlist_cbAdmin(editRolesPopup.rolesPopup.user)}
          rBACAcceess={mfaAllowed}
        ></EditRolesPopUP>
      )}
      {confirmationPopup.confirmationContent && (
        <ConfirmationPopUp
          {...confirmationPopup.confirmationContent}
        ></ConfirmationPopUp>
      )}
    </React.Fragment>
  );
};
export default ManageUsersWithRoles;

const contributorList = (role: Role) => {
  const botname_list: string[] = role.meta?.botname_list;
  return (
    <div className='tooltips_header'>
      <p>Accessed Bots</p>
      {botname_list.map((bot) => (
        <li><span><DialogIcon /></span>{bot}</li>
      ))}
    </div>
  );
};
