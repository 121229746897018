/*eslint-disable*/
import * as React from 'react'

import { BillingDataWrapper, BillingEstimationWrapper, SubscriptionWrapper } from '@/Subscription/Subscription'
// import { FlowWrapper, ProtectedFlowWrapper } from '@/Flows/FlowWrapper'
// import { JourneyContext } from '@/Journey/JourneyMachine'
import { Journey, JourneyState, WorkspaceS } from '@/Journey/Journey'
import {
    MarketPlaceAppsLoadStateHandlerV2,
    MarketPlaceWorkflowsLoadStateHandlerV2
} from '@/MarketPlace/AutomationMarketPlace'
// import { workspace } from '@/Integrations/Widget';
import { QueryClient, QueryClientProvider } from 'react-query'
import {
    Navigate,
    PathMatch,
    Route,
    BrowserRouter as Router,
    Routes,
    useMatch,
    useLocation,
    useParams,
    RouterProvider,
    createBrowserRouter,
    Outlet,
    useNavigate
} from 'react-router-dom'

import { NotFoundPage } from '@/404/NotFound'
// import { AgentHandoffWrapper } from '@/Assistant/AgentHandoff'
//DIALOG COMPILER IMPLEMENTAION STARTS
import AssistantConstructor from '@/Assistant/Construction'
// import AssistantDialogue from '@/Assistant/Dialogue'
// import { BotDetail } from '@/Assistant/Dialogue/BotDetails'
import { CopyPasteContext } from '@/Assistant/Dialogue/CopyPasteContext'
// import AssistantDialogueCanvas from '@/Assistant/DialogueCrew'
// import AssistantEntity from '@/Assistant/Entity'
import AssistantEntityValues from '@/Assistant/EntityCrew'
// import AssistantIntent from '@/Assistant/Intent'
import AssistantIntentExamples from '@/Assistant/IntentCrew'
// import { DialogSettings } from '@/Assistant/Settings/Settings'
import { ROUTER_PROPS_T } from '@/Assistant/kind'
import { Canvas } from '@/DialogEditor/Canvas'
import { Dashboard } from '@/Dashboard/Dashboard'
import { DetailedAutomationLogsListView } from '@/Flows/analytics/logs'
import { DialogDetail } from '@/DialogEditor/Home/DialogDetail'
import { DialogWrapper } from '@/DialogEditor/Home/DialogWrapper'
// import { DialogLogsHome } from '@/DialogEditor/Logs'
// import { WatsonTraining } from '@/DialogEditor/TrainingModule/TrainingModule'
import { DialogAnalyticsHome } from '@/DialogEditor/analytics/home'
import { Run } from '@/Flows/Run'
import { FlowAnalyticsHome } from '@/Flows/analytics/home'
import { FlowCanvasWrapper } from '@/Flows/canvas/FlowCanvasWrapper'
// import { GetStarted } from '@/DialogEditor/Canvas/Components/GetStarted'
import { NewGetStarted } from '@/DialogEditor/Canvas/Components/NewGetStartedPage'
import { JourneyMarketPlace } from '@/DialogEditor/Canvas/Components/JourneyMarketPlace'
import { Group } from '@/Flows/Group'
import { Integration } from '@/Integrations/Integration'
import { IntentExtraction } from '@/IntentExtraction/IntentExtraction'
import { ItSupportAi } from '@/Assistant/knowledge/ItSupport'
import { KnowledgeAIUpgrade } from '@/Assistant/knowledge/KnowledgeUpgrade'
import { KnowledgeAiHome } from '@/Assistant/knowledge/KnowledgeHome'
import { KnowledgeEntityAi } from '@/Assistant/knowledge/Entity'
import { KnowledgeExternalKB } from '@/Assistant/knowledge/ExternalKB'
import { KnowledgeHome } from '@/Assistant/knowledge/home'
import { KnowledgeSettings } from '@/Assistant/knowledge/Settings'
import { KnowledgeWebsiteAi } from '@/Assistant/knowledge/WebsiteKB'
import { KnowledgeWorkativKB } from '@/Assistant/knowledge/WorkativKB'
import { ManagePaymentWrapper } from '@/Subscription/PaymentDetails'
import ManageUsersWithRoles from '@/ManageUsers/v2/ManageUsers'
import { MarketFlowCanvasWrapper } from '@/Flows/canvas/marketplace/MarketFlowCanvasWrapper'
import Multifactor from '@/WorkspaceRedirection/MFAauthentication'
import { MyUploadsWrapper } from '@/MarketPlace/MyUploads'
// import { OnPremWrapper } from '@/Flows/onPremWrapper'
import { PlanWrapper } from '@/Subscription/Plans'
import { Profile } from '@/Profile/Profile.Page'
import { ReactQueryDevtools } from 'react-query/devtools'
import { ResponseNodeE } from '@/Assistant/Dialogue/kind'
import { SharePoint } from '@/Assistant/knowledge/SharePoint'
import { SideBarContext } from '@/SidePanel/SideBarContext'
import { Sidebar } from '@/SidePanel/Sidebar'
// import { SlackWrapper } from '@/Integrations/SlackWrapper'
import SupportPage from '@/Support/index'
import {SyncSummary} from '@/Assistant/knowledge/KnowledgeSync'
import {WebsiteSync} from '@/Assistant/knowledge/WebsiteSync'

// import { TeamsWrapper } from '@/Integrations/TeamsWrapper'
import { ThankYouPage } from '@/Integrations/ThankYouPage'
import { UserPermissionCheck } from './UserPermissionCheck'
import { VideosWrapper } from '@/Videos/VideosWrapper'
import { WatsonTraining } from '@/DialogEditor/TrainingModule/TrainingModule'
import { WelcomePage } from '@/Welcome/Welcome.Assistant'
import { WidgetManipulationWrapper } from '@/Integrations/WidgetManipulationWrapper'
// import { WidgetWrapper } from '@/Integrations/WidgetWrapper'
import { WorkSpaceRedirection } from '@/WorkspaceRedirection/WorkspaceRedirection'
import { AssistantFolder } from '@/Assistant/Dialogue'

// import {Mfascreening} from '@/WorkspaceRedirection/MFAscreening'
/*eslint-disable*/
/*eslint-disable*/
import ReactGALazy, {
    Tracked,
    TrackedAndProtected,
    TrackedAndProtectedWithWorkspace
} from '@/common/google-analytics/tracking'
// import { ReactQueryDevtools } from 'react-query/devtools'
import { usePrevious } from '@/common/hooks/usePrevious'
import { getJson, loginApiURL, userJourneyWithWorkspace } from '@/common/utils/api-utils'
import { getAssistants } from '@/Assistant/Mechanisms/action'
import { onPremConnectionsWrapper } from '@/Flows/onPremConnectionsWrapper'
import { postJson } from '@/common/utils/axios-utils'
import { profileDataT } from '@/Profile/types'
import { usePermissions } from '@/common/utils/auth-token-api-utils'
import { BotHome, HeaderForCanvasPage } from '@/Assistant/BotHome'
import { SingleAssistant } from '@/Assistant/BotHome/SingleAssistant'
import { ChatChannelsHome } from '@/Assistant/BotHome/ChatChannelsHome'
import { KnowledgeLinksHome } from '@/Assistant/BotHome/KnowledgeHome'

import { AfterBotStarted } from '@/DialogEditor/Canvas/Components/BotSelection'
import { VideoContext, VideoPlayerComponent, useVideos } from '@/Videos/Videos'
import { RootJourneyContext, RootWorkspaceContext, UpdateJourneyProvider } from '@/Journey/JourneyContext'
import { AlertWrapper } from '@/Subscription/Plans-v2'
import { AutomationHome } from '@/Flows/AutomationHome'
import { WorkflowTabs } from '@/Flows/AutomationHome/WorkflowTabs'
import { AutomationMarketPlacehome } from '@/MarketPlace/AutomationMarketPlacehome'
import { signal } from '@preact/signals-react'

// const AssistantIntent = React.lazy(()=>import('@/Assistant/Intent'))

// const AssistantEntity = React.lazy(()=>import('@/Assistant/Entity'))

export const singletonQueryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            staleTime: Infinity,
            // staleTime: 4000,
      //     },
        }
    }
})

//DIALOG COMPILER IMPLEMENTAION ENDS

const initialDummyUser = {
    id: -1,
    first_name: '',
    last_name: 'user',
    email: 'dummyUser@workativ.com',
    org: null,
    role: null,
    verified: true
}

const redirectPreservingParams = (toPath: string) => ({ location }: any) => (
    <Navigate to={{ ...location, pathname: `${location.pathname}${toPath}` }} />
)

const makeRemountingComponentOnRouteChange = (
    Component: (props: any) => JSX.Element,
    uniqueKeyFunction: (props: ROUTER_PROPS_T) => string
) => (props: ROUTER_PROPS_T) => {
    // console.log('remounting', props)

    // const newProps = Object.assign({ match: props.computedMatch }, props)
    return <Component key={uniqueKeyFunction(props)} {...props} />
}

function returnReplacedRoute(template: string, obj: { [key: string]: string }) {
    return Object.keys(obj).reduce((acc, val) => {
        return acc.replace(new RegExp(`{${val}}`, 'g'), obj[`${val}`])
    }, template)
}

async function returnPermissionModule(input: ROUTER_PROPS_T, template: string, queryClient: QueryClient) {
    const workspaceName = input.match.params.workspacename
    const assistantName = input.match.params.assistantname

    const botsOutput = await queryClient.fetchQuery(['assistants'], {
        queryFn: () => getAssistants(workspaceName).then((resp) => resp)
    })
    const botId = botsOutput ? botsOutput.filter((b) => b.name === assistantName)[0] : ''

    return returnReplacedRoute(template, { id: botId ? botId.id : '' })
}

const LANDING_ROUTE = 'journey'
// const LANDING_ROUTE = 'welcome'

const DefaultLandingRoute = redirectPreservingParams(LANDING_ROUTE)

const dialogComponents = [
    {
        path: 'conversations',
        Component: React.lazy(()=>import('@/Assistant/Dialogue')),
        _moduleName: {
            type: 'template-generated',
            template: 'CHAT_BOT_MODULE/BOT/{id}/ROOT_DIALOG_NODE',
            action: returnPermissionModule
        },
        _permission: 'READ'
    },
    {
        path: 'intent',
        Component: React.lazy(()=>import('@/Assistant/Intent')),
        _moduleName: {
            type: 'template-generated',
            template: 'CHAT_BOT_MODULE/BOT/{id}/INTENTS',
            action: returnPermissionModule
        },
        _permission: 'READ'
    },
    {
        path: 'entity',
        Component: React.lazy(()=>import('@/Assistant/Entity')),
        _moduleName: {
            type: 'template-generated',
            template: 'CHAT_BOT_MODULE/BOT/{id}/ENTITY',
            action: returnPermissionModule
        },
        _permission: 'READ'
    },
    {
        path: 'dashboard',
        Component: React.lazy(()=>import('@/Assistant/Dialogue/BotDetails').then(module=>({default:module.BotDetail}))),
        _moduleName: {
            type: 'template-generated',
            template: 'CHAT_BOT_MODULE/BOT/{id}/CHATBOT_SUMMARY',
            action: returnPermissionModule
        },
        _permission: 'READ'
    },
    {
        path: 'agent-handover',
        Component: React.lazy(()=>import('@/Assistant/AgentHandoff').then(module=>({default:module.AgentHandoffWrapper}))),
        _moduleName: {
            type: 'template-generated',
            template: 'CHAT_BOT_MODULE/BOT/{id}/AGENT_BOT',
            action: returnPermissionModule
        },
        _permission: 'READ'
    },
    {
        path: 'chat-history',
        Component:
        // makeRemountingComponentOnRouteChange(
        //     DialogLogsHome,
        //     (props: ROUTER_PROPS_T) => `${props.match.params.workspacename}-${props.match.params.assistantname}`
        // )

        React.lazy(()=> import('@/DialogEditor/Logs').then(module=>({default:module.DialogLogsHome}))),
        _moduleName: {
            type: 'template-generated',
            template: 'CHAT_BOT_MODULE/BOT/{id}/LOGS',
            action: returnPermissionModule
        },
        _permission: 'READ'
    },
    {
        path: 'settings',
        Component:
        // makeRemountingComponentOnRouteChange(
        //     DialogSettings,
        //     (props: ROUTER_PROPS_T) => `${props.match.params.workspacename}-${props.match.params.assistantname}`
        // ),
        React.lazy(()=> import('@/Assistant/Settings/Settings').then(module=>({default:module.DialogSettings}))),
        _moduleName: {
            type: 'template-generated',
            template: 'CHAT_BOT_MODULE/BOT/{id}/SETTINGS',
            action: returnPermissionModule
        },
        _permission: 'READ'
    },
    {
        path: 'train-assistant',
        // Component: WatsonTraining,
        Component:React.lazy(()=> import('@/DialogEditor/TrainingModule/TrainingModule').then(module=>({default:module.WatsonTraining}))),
        _moduleName: 'TRAINING_MODULE',
        _permission: 'READ'
    },
    {
        path: 'folder/:folderID',
        // Component: WatsonTraining,
        Component:React.lazy(()=> import('@/Assistant/Dialogue').then(module=>({default:module.AssistantFolder}))),
        _moduleName: {
            type: 'template-generated',
            template: 'CHAT_BOT_MODULE/BOT/{id}/ROOT_DIALOG_NODE',
            action: returnPermissionModule
        },
        _permission: 'READ'
    },
    {
        path: 'dialog/:dialogue_node',
        Component:React.lazy(()=> import('@/Assistant/DialogueCrew')),
        _moduleName: {
            type: 'template-generated',
            template: 'CHAT_BOT_MODULE/BOT/{id}/ROOT_DIALOG_NODE',
            action: returnPermissionModule
        },
        _permission: 'READ'
    }
]

const dialogCanvasPage = [
    {
        path: 'dialog/:dialogue_node',
        // Component: makeRemountingComponentOnRouteChange(AssistantDialogueCanvas, (props: ROUTER_PROPS_T) => {
        //     // console.log(props.computedMatch.params)
        //     // props.match = props.computedMatch
        //     return `${props.match.params.workspacename}-${props.match.params.assistantname}`
        // }),
        Component:React.lazy(()=> import('@/Assistant/DialogueCrew')),
        _moduleName: {
            type: 'template-generated',
            template: 'CHAT_BOT_MODULE/BOT/{id}/ROOT_DIALOG_NODE',
            action: returnPermissionModule
        },
        _permission: 'READ'
    },
]

const chatChannelPages = [
    {
        path: 'teams',
        Component: React.lazy(()=> import('@/Integrations/TeamsWrapper').then(module=>({default:module.TeamsWrapper}))),
        _moduleName: 'CHAT_CHANNEL_MODULE/CHANNEL/TEAMS',
        _permission: 'READ'
    },
    {
        path: 'slack',
        Component: React.lazy(()=> import('@/Integrations/SlackWrapper').then(module=>({default:module.SlackWrapper}))),
        _moduleName: 'CHAT_CHANNEL_MODULE/CHANNEL/SLACK',
        _permission: 'READ'
    },
    {
        path: 'widget',
        Component: React.lazy(()=> import('@/Integrations/WidgetWrapper').then(module=>({default:module.WidgetWrapper}))),
        _moduleName: 'CHAT_CHANNEL_MODULE/CHANNEL/WIDGET',
        _permission: 'READ'
    },
    // {
    //     path: '/workspace/:workspacename/integration/widget/:id',
    //     Component: WidgetManipulationWrapper,
    //     _moduleName: 'CHAT_CHANNEL_MODULE/CHANNEL/WIDGET',
    //     _permission: 'WRITE'
    // },
]

const knowledgePages = [
    {
        path: 'website-kb',
        Component: React.lazy(()=> import('@/Assistant/knowledge/WebsiteKB').then(module=>({default:module.KnowledgeWebsiteAi}))),
        _moduleName: 'KNOWLEDGE_AI',
        _permission: 'GRANTED'
    },
    {
        path: 'external-kb',
        Component: React.lazy(()=> import('@/Assistant/knowledge/ExternalKB').then(module=>({default:module.KnowledgeExternalKB}))),
        _moduleName: 'KNOWLEDGE_AI',
        _permission: 'GRANTED'
    },
    {
        path: 'external-kb/:connection',
        Component: React.lazy(()=> import('@/Assistant/knowledge/SharePoint').then(module=>({default:module.SharePoint}))),
        // Component: SharePoint,
        _moduleName: 'KNOWLEDGE_AI',
        _permission: 'GRANTED'
    },
    {
        path: 'external-kb/:connection/:id/syncsummary',
        Component: React.lazy(()=> import('@/Assistant/knowledge/KnowledgeSync').then(module=>({default:module.SyncSummary}))),
        // Component: SyncSummary,
        _moduleName: 'KNOWLEDGE_AI',
        _permission: 'GRANTED'
    },
    {
        path: 'website-kb/:id/syncsummary',
        Component: React.lazy(()=> import('@/Assistant/knowledge/WebsiteSync').then(module=>({default:module.WebsiteSync}))),
        // Component: SyncSummary,
        _moduleName: 'KNOWLEDGE_AI',
        _permission: 'GRANTED'
    },
    // {
    //     path: '/workspace/:workspacename/knowledge/workativ-kb',
    //     Component: KnowledgeWorkativKB,
    //     _moduleName: 'KNOWLEDGE_AI',
    //     _permission: 'GRANTED'
    // },
    // {
    //     path: '/workspace/:workspacename/knowledge/entity-kb',
    //     Component: KnowledgeEntityAi,
    //     _moduleName: 'KNOWLEDGE_AI',
    //     _permission: 'GRANTED'
    // },
    // {
    //     path: '/workspace/:workspacename/knowledge/website-kb',
    //     Component: KnowledgeWebsiteAi,
    //     _moduleName: 'KNOWLEDGE_AI',
    //     _permission: 'GRANTED'
    // },
    // {
    //     path: '/workspace/:workspacename/knowledge/it-support',
    //     Component: ItSupportAi,
    //     _moduleName: 'KNOWLEDGE_AI',
    //     _permission: 'GRANTED'
    // },
    // {
    //     path: '/workspace/:workspacename/knowledge/home',
    //     Component: KnowledgeHome,
    //     _moduleName: 'KNOWLEDGE_AI',
    //     _permission: 'GRANTED'
    // },
]

const automationViewComponent= [{
    path:':view',
    Component:React.lazy(()=> import('@/Flows/FlowWrapper').then(module=>({default:module.ProtectedFlowWrapper}))),
    _moduleName: 'WORKFLOW_MODULE/WORKFLOWS',
    _permission: 'READ'
}
]

const automationComponents = [

    // {
    //     path: '/workspace/:workspacename/automation/group/:groupId',
    //     Component: Group,
    //     _moduleName: 'WORKFLOW_MODULE/WORKFLOWS',
    //     _permission: 'READ'
    // },
    // {
    //     path: '/workspace/:workspacename/automation/run/:id',
    //     Component: Run,
    //     _moduleName: 'WORKFLOW_MODULE/WORKFLOWS',
    //     _permission: 'READ'
    // },

    {
        path: 'dashboard',
        Component:React.lazy(()=> import('@/Flows/analytics/home').then(module=>({default:module.FlowAnalyticsHome}))),
        _moduleName: 'WORKFLOW_MODULE/WORKFLOWS',
        _permission: 'READ'
    },
    {
        path: 'detail/:id',
        Component:React.lazy(()=> import('@/Flows/canvas/FlowCanvasWrapper').then(module=>({default:module.FlowCanvasWrapper}))),
        _moduleName: 'WORKFLOW_MODULE/WORKFLOWS',
        _permission: 'READ'
    },
    {
        path: 'onprem-agent',
        Component:React.lazy(()=> import('@/Flows/onPremWrapper').then(module=>({default:module.OnPremWrapper}))),
        _moduleName: 'WORKFLOW_MODULE/ON_PREM',
        _permission: 'READ'
    },
    {
        path: 'onprem-agent/detail/:id',
        Component:React.lazy(()=> import('@/Flows/onPremConnectionsWrapper').then(module=>({default:module.onPremConnectionsWrapper}))),
        _moduleName: 'WORKFLOW_MODULE/ON_PREM',
        _permission: 'READ'
    },
    {
        path: 'logs',
        Component:React.lazy(()=> import('@/Flows/analytics/logs').then(module=>({default:module.DetailedAutomationLogsListView}))),
        _moduleName: 'WORKFLOW_MODULE/WORKFLOWS',
        _permission: 'READ'
    },
]

const WorkflowMarketPlacePages = [
    {
        path: 'workflows',
        Component:React.lazy(()=> import('@/MarketPlace/AutomationMarketPlace').then(module=>({default:module.MarketPlaceAppsLoadStateHandlerV2}))),
        _moduleName: 'WORKFLOW_MARKET_PLACE',
        _permission: 'READ'
    },
    {
        path: 'myuploads',
        Component: MyUploadsWrapper,
        _moduleName: 'WORKFLOW_MARKET_PLACE',
        _permission: 'READ'
    },
    {
        path: 'workflow/detail/:id',
        Component: MarketFlowCanvasWrapper,
        _moduleName: 'WORKFLOW_MARKET_PLACE',
        _permission: 'READ'
    },
]

const components = [
    //DIALOG COMPILER IMPLEMENTAION STARTS
    // {
    //     path: '/workspace/:workspacename/dialog/homepage',
    //     Component: BotHome,
    //     _moduleName: 'CHAT_BOT_MODULE/BOT',
    //     _permission: 'READ'
    // },
    // {
    //     path: '/workspace/:workspacename/dialog/:assistantname/summary',
    //     Component: makeRemountingComponentOnRouteChange(
    //         BotDetail,
    //         (props: ROUTER_PROPS_T) => `${props.match.params.workspacename}-${props.match.params.assistantname}`
    //     ),
    //     _moduleName: {
    //         type: 'template-generated',
    //         template: 'CHAT_BOT_MODULE/BOT/{id}/CHATBOT_SUMMARY',
    //         action: returnPermissionModule
    //     },
    //     _permission: 'READ'
    // },
    // {
    //     path: '/workspace/:workspacename/dialog/:assistantname/dialogs',
    //     Component: makeRemountingComponentOnRouteChange(AssistantDialogue, (props: ROUTER_PROPS_T) => {
    //         // console.log(props.computedMatch.params)
    //         // props.match = props.computedMatch
    //         return `${props.match.params.workspacename}-${props.match.params.assistantname}`
    //     }),
    //     _moduleName: {
    //         type: 'template-generated',
    //         template: 'CHAT_BOT_MODULE/BOT/{id}/ROOT_DIALOG_NODE',
    //         action: returnPermissionModule
    //     },
    //     _permission: 'READ'
    // },
    // {
    //     path: '/workspace/:workspacename/dialog/:assistantname/folder/:folderID',
    //     Component: makeRemountingComponentOnRouteChange(AssistantFolder, (props: ROUTER_PROPS_T) => {
    //         // console.log(props.computedMatch.params)
    //         // props.match = props.computedMatch
    //         return `${props.match.params.workspacename}-${props.match.params.assistantname}`
    //     }),
    //     _moduleName: {
    //         type: 'template-generated',
    //         template: 'CHAT_BOT_MODULE/BOT/{id}/ROOT_DIALOG_NODE',
    //         action: returnPermissionModule
    //     },
    //     _permission: 'READ'
    // },
    // {
    //     path: '/workspace/:workspacename/dialog/:assistantname/detail/:dialogue_node',
    //     Component: makeRemountingComponentOnRouteChange(AssistantDialogueCanvas, (props: ROUTER_PROPS_T) => {
    //         // console.log(props.computedMatch.params)
    //         // props.match = props.computedMatch
    //         return `${props.match.params.workspacename}-${props.match.params.assistantname}`
    //     }),
    //     _moduleName: {
    //         type: 'template-generated',
    //         template: 'CHAT_BOT_MODULE/BOT/{id}/ROOT_DIALOG_NODE',
    //         action: returnPermissionModule
    //     },
    //     _permission: 'READ'
    // },
    // {
    //     path: '/workspace/:workspacename/dialog/:assistantname/intent',
    //     Component: makeRemountingComponentOnRouteChange(
    //         BotHome,
    //         (props: ROUTER_PROPS_T) => `${props.match.params.workspacename}-${props.match.params.assistantname}`
    //     ),
    //     _moduleName: {
    //         type: 'template-generated',
    //         template: 'CHAT_BOT_MODULE/BOT/{id}/INTENTS',
    //         action: returnPermissionModule
    //     },
    //     _permission: 'READ'
    // },
    // {
    //     path: '/workspace/:workspacename/dialog/:assistantname/intent/:intent',
    //     Component: makeRemountingComponentOnRouteChange(
    //         AssistantIntentExamples,
    //         (props: ROUTER_PROPS_T) =>
    //             `${props.match.params.workspacename}-${props.match.params.assistantname}-${props.match.params.intent}`
    //     ),
    //     _moduleName: {
    //         type: 'template-generated',
    //         template: 'CHAT_BOT_MODULE/BOT/{id}/INTENTS',
    //         action: returnPermissionModule
    //     },
    //     _permission: 'READ'
    // },
    // {
    //     path: '/workspace/:workspacename/dialog/:assistantname/entity',
    //     Component: makeRemountingComponentOnRouteChange(
    //         AssistantEntity,
    //         (props: ROUTER_PROPS_T) => `${props.match.params.workspacename}-${props.match.params.assistantname}`
    //     ),
    //     _moduleName: {
    //         type: 'template-generated',
    //         template: 'CHAT_BOT_MODULE/BOT/{id}/ENTITY',
    //         action: returnPermissionModule
    //     },
    //     _permission: 'READ'
    // },
    // {
    //     path: '/workspace/:workspacename/dialog/:assistantname/entity/:entity',
    //     Component: makeRemountingComponentOnRouteChange(
    //         AssistantEntityValues,
    //         (props: ROUTER_PROPS_T) =>
    //             `${props.match.params.workspacename}-${props.match.params.assistantname}-${props.match.params.entity}`
    //     ),
    //     _moduleName: {
    //         type: 'template-generated',
    //         template: 'CHAT_BOT_MODULE/BOT/{id}/ENTITY',
    //         action: returnPermissionModule
    //     },
    //     _permission: 'READ'
    // },
    // {
    //     path: '/workspace/:workspacename/dialog/:assistantname/agent-handoff',
    //     Component: makeRemountingComponentOnRouteChange(
    //         AgentHandoffWrapper,
    //         (props: ROUTER_PROPS_T) => `${props.match.params.workspacename}-${props.match.params.assistantname}`
    //     ),
    //     _moduleName: {
    //         type: 'template-generated',
    //         template: 'CHAT_BOT_MODULE/BOT/{id}/AGENT_BOT',
    //         action: returnPermissionModule
    //     },
    //     _permission: 'READ'
    // },
    // {
    //     path: '/workspace/:workspacename/dialog/:assistantname/analytics',
    //     Component: makeRemountingComponentOnRouteChange(
    //         DialogAnalyticsHome,
    //         (props: ROUTER_PROPS_T) => `${props.match.params.workspacename}-${props.match.params.assistantname}`
    //     ),
    //     _moduleName: 'CHAT_BOT_MODULE/BOT',
    //     _permission: 'READ'
    // },
    // {
    //     path: '/workspace/:workspacename/dialog/:assistantname/logs',
    //     Component: makeRemountingComponentOnRouteChange(
    //         DialogLogsHome,
    //         (props: ROUTER_PROPS_T) => `${props.match.params.workspacename}-${props.match.params.assistantname}`
    //     ),
    //     _moduleName: {
    //         type: 'template-generated',
    //         template: 'CHAT_BOT_MODULE/BOT/{id}/LOGS',
    //         action: returnPermissionModule
    //     },
    //     _permission: 'READ'
    // },
    // {
    //     path: '/workspace/:workspacename/dialog/:assistantname/settings',
    //     Component: makeRemountingComponentOnRouteChange(
    //         DialogSettings,
    //         (props: ROUTER_PROPS_T) => `${props.match.params.workspacename}-${props.match.params.assistantname}`
    //     ),
    //     _moduleName: {
    //         type: 'template-generated',
    //         template: 'CHAT_BOT_MODULE/BOT/{id}/SETTINGS',
    //         action: returnPermissionModule
    //     },
    //     _permission: 'READ'
    // },
    // {
    //     path: '/workspace/:workspacename/train-assistant/home',
    //     Component: WatsonTraining,
    //     _moduleName: 'TRAINING_MODULE',
    //     _permission: 'READ'
    // },

    //DIALOG COMPILER IMPLEMENTAION ENDS

    //SUBSCRIPTION IMPLEMENTAION STARTS
    {
        path: '/workspace/:workspacename/billing',
        Component: SubscriptionWrapper,
        _moduleName: 'BILLING_PAGE',
        _permission: 'READ'
    },
    {
        path: '/workspace/:workspacename/billing/plans',
        Component: PlanWrapper,
        _moduleName: 'BILLING_PAGE',
        _permission: 'READ'
    },
    {
        path: '/workspace/:workspacename/billing/history',
        Component: BillingDataWrapper,
        _moduleName: 'BILLING_PAGE',
        _permission: 'READ'
    },
    {
        path: '/workspace/:workspacename/billing/paymentdetails',
        Component: ManagePaymentWrapper,
        _moduleName: 'BILLING_PAGE',
        _permission: 'READ'
    },
    {
        path: '/workspace/:workspacename/billing/estimate',
        Component: BillingEstimationWrapper,
        _moduleName: 'BILLING_PAGE',
        _permission: 'READ'
    },

    //SUBSCRIPTION IMPLEMENTAION ENDS

    {
        path: '/workspace/:workspacename/dashboard',
        Component: Dashboard,
        _moduleName: 'WORKFLOW_MODULE/WORKFLOWS',
        _permission: 'READ'
    },
    // {
    //     path: '/workspace/:workspacename/marketplace/automation/:step(select-application|list-automations)',
    //     Component: MarketPlaceLoadStateHandler
    // },
    // {
    //     path: '/workspace/:workspacename/dialog/home',
    //     Component: DialogWrapper,
    //     _moduleName: 'WORKFLOW_MODULE/WORKFLOWS',
    //     _permission: 'READ'
    // },
    // {
    //     path: '/workspace/:workspacename/dialog/detail/:node',
    //     Component: Canvas,
    //     _moduleName: 'WORKFLOW_MODULE/WORKFLOWS',
    //     _permission: 'READ'
    // },


    // {
    //     path: '/workspace/:workspacename/dialog/entity',
    //     Component: EntityWrapper
    // },
    // {
    //     path: '/workspace/:workspacename/dialog/entity/:name',
    //     Component: DetailViewPage
    // },
    // {
    //     path: '/workspace/:workspacename/dialog/intent',
    //     Component: IntentWrapper
    // },
    // {
    //     path: '/workspace/:workspacename/dialog/intent/:name',
    //     Component: ExampleRoute
    // },
    // {
    //     path: '/workspace/:workspacename/integration/chat',
    //     Component: Integration,
    //     _moduleName: 'WORKFLOW_MODULE/WORKFLOWS',
    //     _permission: 'READ'
    // },
    // {
    //     path: '/workspace/:workspacename/integration/chat/teams',
    //     Component: TeamsWrapper,
    //     _moduleName: 'WORKFLOW_MODULE/WORKFLOWS',
    //     _permission: 'READ'
    // },
    // {
    //     path: '/workspace/:workspacename/integration/chat/slack',
    //     Component: SlackWrapper,
    //     _moduleName: 'WORKFLOW_MODULE/WORKFLOWS',
    //     _permission: 'READ'
    // },
    // {
    //     path: '/workspace/:workspacename/integration/chat/widget',
    //     Component: WidgetWrapper,
    //     _moduleName: 'CHAT_CHANNEL_MODULE/CHANNEL/WIDGET',
    //     _permission: 'READ'
    // },
    // {
    //     path: '/workspace/:workspacename/integration/widget/:id',
    //     Component: WidgetManipulationWrapper,
    //     _moduleName: 'CHAT_CHANNEL_MODULE/CHANNEL/WIDGET',
    //     _permission: 'WRITE'
    // },
    // {
    //     path: '/workspace/:workspacename/integration/chat/:app',
    //     Component: AppRedirector
    // },
    {
        path: '/workspace/:workspacename/testing',
        Component: DialogDetail,
        _moduleName: 'WORKFLOW_MODULE/WORKFLOWS',
        _permission: 'READ'
    },
    {
        path: '/workspace/:workspacename/welcome',
        Component: WelcomePage,
        _moduleName: 'WELCOME_PAGE',
        _permission: 'READ'
    },
    {
        path: '/workspace/:workspacename/:botId/:botName/bot-created',
        Component: AfterBotStarted,
        _moduleName: 'CHAT_BOT_MODULE/BOT',
        _permission: 'READ'
    },
    // {
    //     path: '/workspace/:workspacename/get-started',
    //     Component: GetStarted,
    //     _moduleName: 'CHAT_BOT_MODULE/BOT',
    //     _permission: 'READ'
    // },

    {
        path: '/workspace/:workspacename/dialog/analytics',
        Component: DialogAnalyticsHome,
        _moduleName: 'WORKFLOW_MODULE/WORKFLOWS',
        _permission: 'READ'
    },
    // {
    //     path: '/workspace/:workspacename/dialog/logs',
    //     Component: DialogLogsHome,
    //     _moduleName: 'WORKFLOW_MODULE/WORKFLOWS',
    //     _permission: 'READ'
    // },
    // {
    //     path: '/workspace/:workspacename/automation/summary',
    //     Component: FlowAnalyticsHome,
    //     _moduleName: 'WORKFLOW_MODULE/WORKFLOWS',
    //     _permission: 'READ'
    // },
    // {
    //     path: '/workspace/:workspacename/automation/logs',
    //     Component: DetailedAutomationLogsListView,
    //     _moduleName: 'WORKFLOW_MODULE/WORKFLOWS',
    //     _permission: 'READ'
    // },
    // {
    //     path: '/workspace/:workspacename/integration/slack',
    //     Component: AppRedirector,
    //     _moduleName: 'CHAT_CHANNEL_MODULE/CHANNEL/SLACK',
    //     _permission: 'READ'
    // },
    // {
    //     path: '/workspace/:workspacename/integration/teams',
    //     Component: AppRedirector,
    //     _moduleName: 'CHAT_CHANNEL_MODULE/CHANNEL/TEAMS',
    //     _permission: 'READ'
    // },
    {
        path: '/workspace/:workspacename/profile',
        Component: Profile,
        _moduleName: 'WORKFLOW_MODULE/WORKFLOWS',
        _permission: 'GRANTED'
    },
    {
        path: '/workspace/:workspacename/users',
        Component: ManageUsersWithRoles,
        _moduleName: 'WORKFLOW_MODULE/WORKFLOWS',
        _permission: 'READ'
    },
    // {
    //     path: '/workspace/:workspacename/users/auth',
    //     Component: Multifactor
    // },
    {
        path: '/workspace/:workspacename/WorkSpaceRedirection',
        Component: WorkSpaceRedirection
    },
    // {
    //     path:"/workspace/:workspacename/Mfascreening",
    //     Component:Mfascreening
    // },
    {
        path: '/workspace/:workspacename/support',
        Component: SupportPage,
        _moduleName: 'WORKFLOW_MODULE/WORKFLOWS',
        _permission: 'GRANTED'
    },
    // {
    //     path: '/workspace/:workspacename/help/videos',
    //     Component: VideosWrapper,
    //     _moduleName: 'WORKFLOW_MODULE/WORKFLOWS',
    //     _permission: 'GRANTED'
    // },
    {
        path: '/workspace/:workspacename/intent-extraction',
        Component: IntentExtraction,
        _moduleName: 'WORKFLOW_MODULE/WORKFLOWS',
        _permission: 'READ'
    },
    // {
    //     path: '/workspace/:workspacename/knowledge',
    //     Component: KnowledgeAiHome,
    //     _moduleName: 'KNOWLEDGE_AI',
    //     _permission: 'GRANTED'
    // },
    // {
    //     path: '/workspace/:workspacename/knowledge/external-kb',
    //     Component: KnowledgeExternalKB,
    //     _moduleName: 'KNOWLEDGE_AI',
    //     _permission: 'GRANTED'
    // },
    // {
    //     path: '/workspace/:workspacename/knowledge/external-kb/:connection',
    //     Component: SharePoint,
    //     _moduleName: 'KNOWLEDGE_AI',
    //     _permission: 'GRANTED'
    // },
    // {
    //     path: '/workspace/:workspacename/knowledge/external-kb/:connection/:id/syncsummary',
    //     Component: SyncSummary,
    //     _moduleName: 'KNOWLEDGE_AI',
    //     _permission: 'GRANTED'
    // },
    // {
    //     path: '/workspace/:workspacename/knowledge/workativ-kb',
    //     Component: KnowledgeWorkativKB,
    //     _moduleName: 'KNOWLEDGE_AI',
    //     _permission: 'GRANTED'
    // },
    // {
    //     path: '/workspace/:workspacename/knowledge/entity-kb',
    //     Component: KnowledgeEntityAi,
    //     _moduleName: 'KNOWLEDGE_AI',
    //     _permission: 'GRANTED'
    // },
    {
        path: '/workspace/:workspacename/knowledge/settings',
        Component: KnowledgeSettings,
        _moduleName: 'KNOWLEDGE_AI',
        _permission: 'GRANTED'
    },
    {
        path: '/workspace/:workspacename/knowledge/upgrade',
        Component: KnowledgeAIUpgrade,
        _moduleName: 'KNOWLEDGE_AI',
        _permission: 'GRANTED'
    }
]

type GlobalAlert = {
    type: 'WARNING' | 'ALERT'
}

export function AppWithCache() {
    console.log('React version ::: ', React.version)
    return (
        <QueryClientProvider client={singletonQueryClient}>
            <App />
        </QueryClientProvider>
    )
}

function SingleAssistantTab(){
    const params = useParams()
    return params["dialogue_node"] ? <Outlet/> : (
        <AppComponents
            props={{
                ...{
                    path: ':assistantname',
                    Component: SingleAssistant,
                    _moduleName: 'CHAT_BOT_MODULE/BOT',
                    _permission: 'READ'
                }
            }}
        />
    )
}

function AppComponents(props: any) {
    const { path, Component, _moduleName, _permission } = props.props
    const location = useLocation()
    const params = useParams()
    // console.log(path,props,location)
    const navigate = useNavigate()

    return (
        <TrackedAndProtectedWithWorkspace
            match={{ params, path: location.pathname, url: location.pathname }}
            location={location}
            render={(props: any) => {
                // console.log('TrackedAndProtected1 ::: ', props)
                return (
                    <React.Fragment>
                        {/* <StatelessComponent parentProps={props} updateProps={(props) => setState({...props, key:location.key,history: { push: (url: string) => {
                            console.log(url, navigate,"urllll")
                            navigate(url, { relative: "path" })
                            setStates(Math.random())
                        }, location } })} /> */}
                        <div className="flex_canvas_right menu_header">
                            <div
                                className={
                                    path !== 'detail/:dialogue_node'
                                        ? path !== '/workspace/:workspacename/automation/detail/:id'
                                            ? 'cogni__main dialog_paste_popup'
                                            : ''
                                        : 'cogni__main dialog_editor_canvas'
                                }
                            >
                                {_permission == 'GRANTED' ? (
                                    <Component {...{...props, history: { push: (url: string) => navigate(url), replace: (url: string) => navigate(url,{replace:true}), location }}} />
                                ) : (
                                    <UserPermissionCheck
                                        {...{...props, ...(path == "conversations" || path == "view" ? { key:location.key } : {}), history: { push: (url: string) => navigate(url),replace: (url: string) => navigate(url,{replace:true}), location } }}
                                        Component={Component}
                                        _moduleName={_moduleName}
                                        _permission={_permission}
                                    />
                                )}
                            </div>
                        </div>
                    </React.Fragment>
                )
            }}
        />
    )
}


function RenderElement(props: { Component: any; isTracked: string, setJourneyState?:Function, initializedMFA?:React.MutableRefObject<boolean>}) {
    // console.log(props,"Component")

    const { Component, isTracked, setJourneyState, initializedMFA } = props
    const location = useLocation()
    const params = useParams()
    const navigate = useNavigate()

    function returnComponent(isTracked: string) {
        switch (isTracked) {
            case 'tracked':
                return (
                    <Tracked location={location} path={location.pathname}>
                        <Component
                            {...{ match: { params }, history: { push: (url: string) => navigate(url) }, location, setJourneyState }}
                        />
                    </Tracked>
                )
            case "tracked-protected":
                    console.log(isTracked,"tracked-protected")
                    return(
                        <TrackedAndProtected location={location} path={location.pathname}>
                            <Component
                                {...{ match: { params }, history: { push: (url: string) => navigate(url) }, location, setJourneyState }}
                            />
                        </TrackedAndProtected>
                    )
            default:
                return (
                    <Component
                        {...{ match: { params, path: location.pathname, url: location.pathname }, history: { push: (url: string) => navigate(url) }, location, setJourneyState, initializedMFA}}
                    />
                )
        }
    }
    return Component ? returnComponent(isTracked) : null
}

function BaseRoute(props:{setJourneyState:any}){
    const location = useLocation()
    return <>
                {location.pathname == "/" ? <RenderElement Component={WorkSpaceRedirection} isTracked='tracked-protected' setJourneyState={props.setJourneyState}/> : null}
                <Outlet/>
           </>
}

const copiedData:any = signal<any>(null);

function App() {
    const [state, setState] = React.useState({})

    const initializedMFA = React.useRef(false)

    const [journeyState, setJourneyState] = React.useState<JourneyState>({
        type: 'loading'
    })

    const [rootWorkspace, setRootWorkspace] = React.useState<WorkspaceS>({
        type: 'yet-to-load'
    })

    const {
        videoURL,
        setVideoURL,
        lastPlayerSize,
        videoLoading,
        setVideoLoading,
        videosList,
        setVideoUrlId,
        playerSize,
        setPlayerSize,
        refetchVideos
    } = useVideos({ state })

    const x = React.useRef<boolean>(false)

    const pJ = usePrevious(journeyState) as JourneyState

    const fnCallBack = React.useCallback(setJourneyState, [])

    const [isSubPanelOpen, setSubPanelOpen] = React.useState(false)
    const [userData, setUserData] = React.useState<profileDataT>(initialDummyUser)

    function setCopiedData (data:any){
        copiedData.value = data
    }

    React.useEffect(() => {
        if (userData.id != -1) {
            ReactGALazy().set({ userId: userData.email })
            ReactGALazy().set({ dimension1: userData.email })
        }

            if (window) {
            const localWindow = window as any
            // console.log('USER-GUIDING', localWindow.userGuiding)
            localWindow.userGuiding.identify(`user_assistant_${userData.id}`, {
                email: userData.email,
                name: `${userData.first_name} ${userData.last_name}`,
                platform: 'Assistant',
                environment: 'production'
            })
        }
    }, [userData])

    console.log('User journey', pJ, journeyState)
    if (pJ && pJ.type == 'loading' && journeyState.type === 'success' && x.current == false) {
        const value1 = journeyState.value.state()
        console.log('Added subscription for journey',value1)
        x.current = true
        journeyState.value.subscribe((app) => {
            const value = journeyState.value.state()

            console.log('val', journeyState.value.state())
            window.localStorage.setItem(
                value.context.systemContext.workspace,
                JSON.stringify({ state: value.value, context: value.context.executionContext })
            )
            postJson(userJourneyWithWorkspace(value.context.systemContext.workspace, '/journey/update'), {
                journeyJson: { state: value.value, context: value.context.executionContext }
            })
            // setJourneyState({ type: 'success', value: journeyState.value, id: JSON.stringify(value).length })
        })
    }

    function refetchProfileData() {
        const promse = () => getJson(loginApiURL('/user_info'))

        promse()
            .then((user) => {
                setUserData(user)
            })
            .catch((err:any) => {
                console.log('err')
            })
    }


    // function JourneyComponent(props:any){
    //     const hasPermission = usePermissions(props.match.params.workspacename, "WELCOME_PAGE", "READ");

    //     if(hasPermission.value){
    //         return (
    //             <Journey setJourneyState={setJourneyState} {...props} />
    //         )
    //     }else{
    //         return <Navigate
    //             to={{
    //                     pathname: `/workspace/${props.match.params.workspacename}/dialog/homepage`,
    //                     state: {
    //                         from: props.location
    //                     }
    //                 }}
    //         />
    //     }
    // }

function SuccessComponent() {
    const location = useLocation()
    if (location.search.includes('?code=') && location.search.includes('&state=')) {
        return (
            <React.Fragment>
                <RenderElement Component={ThankYouPage} isTracked='no'/>,
            </React.Fragment>
        )
    } else if (location.search.includes('?error=access_denied')) {
        window.close()
        return null
    } else {
        return (
            <Navigate
                to={'/'}
                replace
                state={{
                    from: location
                }}
            />
        )
    }
}


    const router = createBrowserRouter([
        {
            path: '/',
            element: <BaseRoute setJourneyState={setJourneyState}/>,
            children: [
                {
                    path:"success",
                    element:<SuccessComponent/>
                },
                {
                    path: 'workspace/:workspacename',
                    element:<RenderElement Component={(props:any)=><Sidebar {...props} setUserData={setUserData}/>} isTracked='no'/>,
                    children: [
                        {
                            path:"/workspace/:workspacename",
                            element:<Navigate to={LANDING_ROUTE} replace />,
                        },
                        {
                            path:"users/auth",
                            element: <RenderElement Component={Multifactor} isTracked='no' setJourneyState={setJourneyState} initializedMFA={initializedMFA}/>,
                        },
                        {
                            path:"/workspace/:workspacename/journey",
                            element: <RenderElement Component={Journey} isTracked='no' setJourneyState={setJourneyState}/>
                        },
                        {
                            path:"get-started",
                            // element: <RenderElement Component={NewGetStarted} isTracked='no' setJourneyState={setJourneyState}/>
                            element: <AppComponents
                                        props={{
                                            ...{
                                                path: 'assistant',
                                                Component: NewGetStarted,
                                                _moduleName: 'CHAT_BOT_MODULE/BOT',
                                                _permission: 'GRANTED'
                                            }
                            }}
                        />
                        },
                        {
                            path:"get-started/marketplace",
                            element: <AppComponents
                                        props={{
                                            ...{
                                                path: 'assistant',
                                                Component: (props:any) => <JourneyMarketPlace userData={userData} {...props}/>,
                                                _moduleName: 'CHAT_BOT_MODULE/BOT',
                                                _permission: 'GRANTED'
                                            }
                                }}
                            />
                        },
                        {
                            path: 'assistant',
                            element: (
                                <AppComponents
                                    props={{
                                        ...{
                                            path: 'assistant',
                                            Component: BotHome,
                                            _moduleName: 'CHAT_BOT_MODULE/BOT',
                                            _permission: 'READ'
                                        }
                                    }}
                                />
                            ),
                            children: [
                                {
                                    element: (
                                        <AppComponents
                                            props={{
                                                ...{
                                                    path: 'homepage',
                                                    Component: AssistantConstructor,
                                                    _moduleName: 'CHAT_BOT_MODULE/BOT',
                                                    _permission: 'READ'
                                                }
                                            }}
                                        />
                                    ),
                                    path: 'homepage'
                                },
                                {
                                    element:<SingleAssistantTab/>,
                                    path: ':assistantname',
                                    children: dialogComponents.map((comp: any, i: number) => ({
                                        path: comp.path,
                                        element: <React.Suspense fallback={<></>}>
                                            <AppComponents props={{ ...comp }} />
                                        </React.Suspense>
                                    }))
                                },
                                {
                                    path:"chat-channels",
                                    element: (
                                        <AppComponents
                                            props={{
                                                ...{
                                                    path: 'chat-channels',
                                                    Component: ChatChannelsHome,
                                                    _moduleName: 'CHAT_BOT_MODULE/BOT',
                                                    _permission: 'READ'
                                                }
                                            }}
                                        />
                                    ),
                                    children: chatChannelPages.map((comp: any, i: number) => ({
                                        path: comp.path,
                                        element: <React.Suspense fallback={<></>}>
                                            <AppComponents props={{ ...comp }} />
                                        </React.Suspense>
                                    }))
                                },
                                {
                                    path:"knowledge",
                                    element: (
                                        <AppComponents
                                            props={{
                                                ...{
                                                    path: 'knowledge',
                                                    Component: KnowledgeLinksHome,
                                                    _moduleName: 'KNOWLEDGE_AI',
                                                    _permission: 'GRANTED'
                                                }
                                            }}
                                        />
                                    ),
                                    children: knowledgePages.map((comp: any, i: number) => ({
                                        path: comp.path,
                                        element: <React.Suspense fallback={<></>}>
                                            <AppComponents props={{ ...comp, setState: setState }} />
                                        </React.Suspense>
                                    }))
                                },
                            ]
                        },
                        {
                            path:"automation",
                            element: (
                                <AppComponents
                                    props={{
                                        ...{
                                            path: 'automation',
                                            Component: AutomationHome,
                                            _moduleName: 'WORKFLOW_MODULE/WORKFLOWS',
                                            _permission: 'READ'
                                        }
                                    }}
                                />
                            ),
                            children:[{
                                path:"view",
                                element: <AppComponents
                                            props={{
                                                ...{
                                                    path: 'view',
                                                    Component: WorkflowTabs,
                                                    _moduleName: 'WORKFLOW_MODULE/WORKFLOWS',
                                                    _permission: 'READ'
                                                }
                                            }}
                                        />,
                                children: automationViewComponent.map((comp: any, i: number) => ({
                                    path: comp.path,
                                    element: <React.Suspense fallback={<></>}>
                                        <AppComponents props={{ ...comp, setState: setState }} />
                                    </React.Suspense>
                                }))
                            },
                            ...automationComponents.map((comp: any, i: number) => ({
                                path: comp.path,
                                element: <React.Suspense fallback={<></>}>
                                    <AppComponents props={{ ...comp, setState: setState }} />
                                </React.Suspense>
                            }))
                        ]},
                        {
                            path:"marketplace",
                            element: (
                                <AppComponents
                                    props={{
                                        ...{
                                            path: 'marketplace',
                                            Component: AutomationMarketPlacehome,
                                            _moduleName: 'WORKFLOW_MARKET_PLACE',
                                            _permission: 'READ'
                                        }
                                    }}
                                />
                            ),
                            children: WorkflowMarketPlacePages.map((comp: any, i: number) => ({
                                    path: comp.path,
                                    element: <React.Suspense fallback={<></>}>
                                        <AppComponents props={{ ...comp, setState: setState }} />
                                    </React.Suspense>
                            }))
                        },
                        ...components.map((com)=>{
                            return(
                            {
                                path:com.path,
                                element: (
                                    <AppComponents
                                        props={{
                                            ...com
                                        }}
                                    />
                                )
                            }
                        )})
                    ]
                },
                {
                    path:"*",
                    element: <RenderElement Component={NotFoundPage} isTracked='tracked'/>
                }
            ],
        }
    ])

    return (
        <React.Fragment>
            <UpdateJourneyProvider.Provider value={fnCallBack}>
                <RootWorkspaceContext.Provider value={rootWorkspace}>
                    <RootJourneyContext.Provider value={journeyState}>
                        <SideBarContext.Provider
                            value={{
                                isSubPanelOpen,
                                setSubPanelOpen,
                                userData,
                                refetchProfileData
                            }}
                        >
                            <CopyPasteContext.Provider
                                value={{
                                    copiedData,
                                    setCopiedData
                                }}
                            >
                                <VideoContext.Provider
                                    value={{
                                        videoURL,
                                        setVideoURL: setVideoUrlId,
                                        videosList,
                                        refetchVideos
                                    }}
                                >
                                    {!userData.verified && <AlertWrapper userData={userData} {...state} />}
                                    <div
                                        id="wrapper"
                                        className={!userData.verified ? 'flow_canvas un_verified' : 'flow_canvas'}
                                    >
                                        <div className="flex_canvas">
                                            {/* {state && Object.keys(state).length > 0 && (
                                                <Sidebar {...state} setUserData={setUserData} />
                                            )} */}
                                            <RouterProvider router={router} />
                                        </div>
                                    </div>

                                    <VideoPlayerComponent
                                        {...{
                                            videoURL,
                                            playerSize,
                                            setPlayerSize,
                                            videoLoading,
                                            setVideoLoading,
                                            lastPlayerSize,
                                            setVideoURL
                                        }}
                                    />
                                </VideoContext.Provider>
                            </CopyPasteContext.Provider>
                        </SideBarContext.Provider>
                        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
                    </RootJourneyContext.Provider>
                </RootWorkspaceContext.Provider>
            </UpdateJourneyProvider.Provider>
        </React.Fragment>
    )
}

function StatelessComponent(props: { parentProps: any; updateProps: (props: any) => void }) {
    const { parentProps, updateProps } = props
    // console.log('StatelessComponent:', props)

    React.useEffect(() => {
        // const newProps = Object.assign({ match: parentProps.computedMatch }, parentProps)
        updateProps(parentProps)
    }, [props.parentProps.location])

    return null
}

export default App

{
    /* app routes */
}
// <Router>
//     <React.Fragment>
//         <Routes>
//             <Route
//                 caseSensitive
//                 path="/workspace/:workspacename"
//                 children={DefaultLandingRoute}
//             />

//             <Route
//                 caseSensitive
//                 path="/workspace/:workspacename/journey"

//                 children={(props: any) => (
//                     <Journey setJourneyState={setJourneyState} {...props} />
//                 )}
//             />

//             <Route
//                 caseSensitive
//                 path="/workspace/:workspacename/get-started"
//                 children={(props: any) => <GetStarted {...props} setJourneyState={setJourneyState}/>}
//             />

//             <TrackedAndProtected
//                 caseSensitive
//                 path="/workspace/:workspacename/users/auth"
//                 setJourneyState={setJourneyState}
//                 render={(props: any) => <Multifactor {...props} />}
//             />

//             <TrackedAndProtected
//                 setJourneyState={setJourneyState}
//                 caseSensitive
//                 path="/"
//                 render={(props: any) => <WorkSpaceRedirection {...props} />}
//             />
//             {renderRoutes(dialogComponents)}

//             <Tracked
//                 caseSensitive
//                 path="*"
//                 render={(props: any) => <NotFoundPage {...props} />}
//             />

//             <Tracked
//                 caseSensitive
//                 path="/success"
//                 render={(props: any) => {
//                     if (
//                         props.location.search.includes('?code=') &&
//                         props.location.search.includes('&state=')
//                     ) {
//                         return (
//                             <React.Fragment>
//                                 <ThankYouPage {...props} />
//                             </React.Fragment>
//                         )
//                     } else if (
//                         props.location.search.includes(
//                             '?error=access_denied'
//                         )
//                     ) {
//                         window.close()
//                         return null
//                     } else {
//                         return (
//                             <Navigate
//                                 to={'/'}
//                                 state={{
//                                         from: props.location
//                                 }}
//                             />
//                         )
//                     }
//                 }}
//             />
//         </Routes>
//     </React.Fragment>
// </Router>
{
    /* app routes */
}
