import { AxiosError } from 'axios';
import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { fetchRoles } from './managerUsers.api';
import { Role } from './user.types';
import { getAssistants } from '@/Assistant/Mechanisms/action';
import { BotsP } from './ManageUsers';

type WsName = string;

export type ChatBotContributor = {
  id: number;
  name: string;
  display_name: string;
  workspace: any,
  meta: {
    bot_list:string[],
    botname_list:string[]
  },
  createdAt:string,
  updatedAt: string
};

export const contributorRole: () => ChatBotContributor = () => ({
  id: -1, // invalid id given for contributor role.
  name: 'chatbot_contributor', // will be changed while submit
  display_name: 'Chatbot Contributor',
  workspace: null,
  meta: {
    bot_list:["ae2ccc0b-a5f2-4713-96d3-31ce026b2097"],
    botname_list:['IT Support Bot','ajith']
  },
  createdAt: "2023-03-30T11:15:04.000Z",
  updatedAt: "2023-03-30T11:15:04.000Z",
  description:"Read and Write access to the chatbot builder (cannot delete, only create and update)"
});

export const cbAdminRole : ()=> ChatBotContributor =()=>({
  id: -2, // invalid id given for Admin role.
  name: 'Chatbot_Admin',
  display_name: 'Chatbot Admin',
  workspace: null,
  meta: {
    bot_list:["ae2ccc0b-a5f2-4713-96d3-31ce026b2097"],
    botname_list:['IT Support Bot','ajith']
  },
  createdAt: "2023-03-30T11:15:04.000Z",
  updatedAt: "2023-03-30T11:15:04.000Z",
  description:"Access to a particular chatbot (create, update, delete, and publish)"
})

const useRolesQuery = (ws: WsName) => {
  const {
    isLoading,
    isError,
    error,
    data: roles,
  } = useQuery<Role[], AxiosError>([`roles`, ws], () => fetchRoles(ws));
  return {
    isLoading,
    isError,
    error,
    roles: roles && [...roles, contributorRole(),cbAdminRole()],
  };
};

type SelectedRolesMap = { [key: string]: boolean };

const useRolesDropDown = (initialSelectedRoles?: SelectedRolesMap) => {
  const [selectedRoles, setSelectedRoles] = useState(
    initialSelectedRoles || {}
  );
  const addToSelectedRoles = (role: Role) => {
    setSelectedRoles({ ...selectedRoles, [role.id]: true });
  };
  const removeFromSelectedRoles = (role: Role) => {
    setSelectedRoles({ ...selectedRoles, [role.id]: false });
  };
  return { selectedRoles, addToSelectedRoles, removeFromSelectedRoles };
};

export const useRoles = (
  ws: WsName,
  initialSelectedRoles?: SelectedRolesMap
) => {
  const [bots, setBots] = useState<BotsP[]>([]);
  useEffect(() => {
      getAssistants(ws)
          .then((res) => {
            const bots = res.map((b) => ({ id: b.id, name: b.name }));
            setBots(bots)
          })
          .catch(err => console.log('Err::', err))
  },[])
  const { roles, ...queryInfo } = useRolesQuery(ws);
  const { selectedRoles, addToSelectedRoles, removeFromSelectedRoles } =
    useRolesDropDown(initialSelectedRoles);
  const getSelectedRolesArr = () =>
    (roles || []).filter(({ id }) => selectedRoles[id]);
  const containsCBContributor:boolean = containsChatBotContributor(getSelectedRolesArr());
  const containsCBAdmin:boolean = containsChatBotAdmin(getSelectedRolesArr())
  const assignedBotList:string[]|[] = containsCBContributor ? gettingBotList(getSelectedRolesArr()) :[];
  return {
    roles,
    addToSelectedRoles,
    removeFromSelectedRoles,
    getSelectedRolesArr,
    selectedRoles,
    // selectedRolesArr,
    queryInfo,
    containsCBContributor,
    assignedBotList,
    containsCBAdmin
  };
};
export const gettingBotList=(array: any[])=>{
  const newArray = array.filter((a)=> a.display_name === 'Chatbot Contributor')[0]?.meta?.botname_list
  return newArray
}
export const isChatBotContributor: (role: Pick<Role, 'display_name'>) => boolean = (
  role
) => role.display_name === 'Chatbot Contributor';

export const containsChatBotContributor: (
  role: Pick<Role, 'display_name'>[]
) => boolean = (roles) => roles.some(isChatBotContributor);

export const isChatBotAdmin: (role: Pick<Role, 'display_name'>) => boolean = (
  role
) => role.display_name === 'Chatbot Admin';

export const containsChatBotAdmin: (
  role: Pick<Role, 'display_name'>[]
) => boolean = (roles) => roles.some(isChatBotAdmin);
export default useRoles;
