import Axios from 'axios'
import React from 'react'

import { deleteJson as deleteJSONWithData } from '@/common/utils/api-utils'
import { withAuth } from '@/common/utils/auth-token-api-utils'
import { automationAPIURLWithWorkspace } from '@/common/utils/api-utils'
//TYPE IMPORT
import { AssistantT } from '../Construction/kind'
import { DialogueT, ResponseNodeE } from '../Dialogue/kind'
import { IntentRequestT } from '../Intent/kind'
import { ExampleRequestT } from '../IntentCrew/kind'

const getJson = (url: string) => withAuth(() => Axios.get(url).then(resp => resp.data))

const putJson = (url: string, data: any) => withAuth(() => Axios.put(url, data).then(resp => resp.data))

const postJson = (url: string, data: any) => withAuth(() => Axios.post(url, data).then(resp => resp.data))

const deleteJson = (url: string) => withAuth(() => Axios.delete(url))

export const watsonApiURLWithoutWorkspace = (api: string) => process.env.REACT_APP_WATSON_API + api

const DialogApiURLWithBotID = (workspace: string, botid: string, api: string) => {
    return process.env.REACT_APP_DIALOG_API + '/' + workspace + '/' + botid + '/' + api
}

const DialogApiURLWithOutWorkspace = (botid: string, api: string) => {
    return process.env.REACT_APP_DIALOG_API + '/' + botid + '/' + api
}

export const DialogApiURLWithoutBotID = (workspace: string, api: string) => {
    return process.env.REACT_APP_DIALOG_API + '/' + workspace + '/' + api
}

export const DialogApiURLForPolling = (api: string, botid: string) => {
    return process.env.REACT_APP_NLU_API + api + '/' + botid
}
// export const DialogApiURLForPolling = (api: string, botid: string) => {
//     return `http://dev-assistant.workativ.ai:8086/${api}/${botid}`
// }

export const fetchAutomationList = (workspacename: string) =>
    getJson(automationAPIURLWithWorkspace(workspacename, `/automation/fetch/all`))

export const fetchAutomationConfig = (workspacename: string, automation: string) =>
    getJson(automationAPIURLWithWorkspace(workspacename, `/automation/${automation}/config`))

export const fetchUIJsonForFlows = (workspacename: string, flowsId: string[]) =>
    postJson(automationAPIURLWithWorkspace(workspacename, `/automation/details`), { automations: flowsId })

export const fetchActionConfigs = (workspacename: string, actionIds: string[]) =>
    postJson(automationAPIURLWithWorkspace(workspacename, `/actions/configs`), { actions: actionIds })

export const fetchKBsList = (workspacename: string) =>
    getJson(`${process.env['REACT_APP_KNOWLEDGE_AI_EXTERNAL_ROUTE']}/${workspacename}/knowledge-base/category`)
 
// HOMEPAGE'S API
export const getAssistants = (workspaceName: string): Promise<AssistantT[]> =>{
   return getJson(DialogApiURLWithoutBotID(workspaceName, `bots`)).then(data =>
        data.map((e: any) => ({
            ...e,
            updated: e.updated + 'Z',
            created: e.created + 'Z',
            deployed: e.deployed === null ? null : e.deployed + 'Z'
        }))
    )
}

export const createAssistant = (workspaceName: string, data: any): Promise<AssistantT> =>
    postJson(DialogApiURLWithoutBotID(workspaceName, `create_bot?bot_name=${data.name}`), data)

export const updateAssistant = (workspaceName: string, botId: string, data: any, name: string): Promise<AssistantT> =>
    postJson(DialogApiURLWithoutBotID(workspaceName, `bot/${botId}?bot_name=${name}`), data)

export const deleteAssistant = (workspaceName: string, botId: string): Promise<any> =>
    deleteJson(DialogApiURLWithoutBotID(workspaceName, `${botId}/remove_bot`))

export const publishAssistant = (workspaceName: string, BOT_ID: string): Promise<AssistantT> =>
    postJson(DialogApiURLWithBotID(workspaceName, BOT_ID, 'deploy/engine'), {})

export const unpublishAssistant = (workspaceName: string, BOT_ID: string): Promise<AssistantT> =>
    postJson(DialogApiURLWithBotID(workspaceName, BOT_ID, 'publish/revoke'), {})

export const deployAssistant = (workspaceName: string, BOT_ID: string): Promise<AssistantT> =>
    postJson(DialogApiURLWithBotID(workspaceName, BOT_ID, 'deploy/engine?deployment_type=testing'), {})

export const getSettings = (workspaceName: string, BOT_ID: string) => {
    return getJson(DialogApiURLWithBotID(workspaceName, BOT_ID, `settings`))
}
export const getAgentSettings = (workspaceName: string, BOT_ID: string) => {
    return getJson(DialogApiURLWithBotID(workspaceName, BOT_ID, `agent_setting`))
}

export const updateSettings = (workspaceName: string, botId: string, data: any) => {
    return postJson(DialogApiURLWithBotID(workspaceName, botId, `settings/${data.bot_id}`), data)
}

export const updateAgentSettings = (workspaceName: string, botId: string, data: any) => {
    return postJson(DialogApiURLWithBotID(workspaceName, botId, `update_agent_settings`), data)
}

// DIALOGUE'S API
export const getDialogues = (workspaceName: string, BOT_ID: string) =>
    getJson(DialogApiURLWithBotID(workspaceName, BOT_ID, `if_nodes`)).then(data =>
        data.map((e: any) => ({
            ...e,
            updated: e.updated + 'Z',
            created: e.created + 'Z'
        }))
    )

export const getDialogueRoots = (workspaceName: string, BOT_ID: string) =>
    getJson(DialogApiURLWithBotID(workspaceName, BOT_ID, `if_node/roots`)).then(data =>
        data.map((e: any) => ({
            ...e,
            updated: e.updated + 'Z',
            created: e.created + 'Z'
        }))
    )

export const getDialogueRootDescendants = (workspaceName: string, BOT_ID: string, dialogue_node: string) =>
    getJson(DialogApiURLWithBotID(workspaceName, BOT_ID, `if_node/with_descendants/${dialogue_node}`))
export const getDialogueFromMarketplace = async (workspaceName: string, BOT_ID: string, dialogue_node: string) =>
    await getJson(
        `${process.env.REACT_APP_DIALOG_MARKETPLACE}/botmarketplace/${workspaceName}/bot/${BOT_ID}/ifnode/${dialogue_node}`
    ).then(res => res.output)

export const createIfDialogue = (workspaceName: string, BOT_ID: string, data: any, dialog_title: string) =>
    postJson(
        DialogApiURLWithBotID(
            workspaceName,
            BOT_ID,
            `if_node?dialog_title=${dialog_title}&action=${data.parent === null ? 'created' : 'updated'}`
        ),
        data
    )

export const createFolder = (workspaceName: string, BOT_ID: string, data: any) =>
postJson(
    DialogApiURLWithBotID(
        workspaceName,
        BOT_ID,
        `group_if_nodes`
    ),
    data
)

export const createFAQNode = (workspaceName: string, BOT_ID: string, data: any) =>
    postJson(DialogApiURLWithBotID(workspaceName, BOT_ID, `if_node?dialog_title=${data.title}&action=created`), data)
export const createIfDialogueWithInitial = (workspaceName: string, BOT_ID: string, data: any) =>
    postJson(
        DialogApiURLWithBotID(
            workspaceName,
            BOT_ID,
            `if_node?create_intial_nodes=true&dialog_title=${data.title}&action=created`
        ),
        data
    )

export const moveIfDialogue = (workspaceName: string, BOT_ID: string, IF: string, data: any) =>
    postJson(DialogApiURLWithBotID(workspaceName, BOT_ID, `move/if_node/${IF}`), data)

export const updateIfDialogue = (
    workspaceName: string,
    BOT_ID: string,
    IF: string,
    data: DialogueT,
    dialog_title: string
) =>
    postJson(
        DialogApiURLWithBotID(workspaceName, BOT_ID, `if_node/${IF}?dialog_title=${dialog_title}&action=updated`),
        data
    )

export const deleteIfDialogue = (
    workspaceName: string,
    BOT_ID: string,
    IF: string,
    dialog_title: string,
    action: string
) =>
    deleteJson(
        DialogApiURLWithBotID(workspaceName, BOT_ID, `if_node/${IF}?dialog_title=${dialog_title}&action=${action}`)
    )

export const multipleDeleteIfDialogue = (workspaceName: string, BOT_ID: string, if_node_list: string[]) =>
    Axios.delete(DialogApiURLWithBotID(workspaceName, BOT_ID, `if_node?bulk_delete=true`), {
        data: {
            if_node_list
        }
    })

export const copyIfNode = (
        workspaceName: string,
        BOT_ID: string,
        data: {
            node_id: string;
            previous_sibling_id: null;
            parent: string;
        } | {
            node_id: string;
            previous_sibling_id: string;
            parent: null;
        },
        API:string
    ) =>
        postJson(
            DialogApiURLWithBotID(workspaceName, BOT_ID, API),
            data
        )
// const botlimit = (workspace: string) => `${process.env.REACT_APP_SUBSCRIPTION_API}/${workspace}/resource/BOT/limit`
// export const subscriptionBotLimit = (workspace: string) => getJson(botlimit(workspace))

//RESPONSE NODE
export const createResponseDialogue = (
    workspaceName: string,
    BOT_ID: string,
    IF: string,
    data: ResponseNodeE,
    dialog_title: string,
    sub_nodes?: any[]
) =>
    postJson(DialogApiURLWithBotID(workspaceName, BOT_ID, `if_node/${IF}/response_node?dialog_title=${dialog_title}`), {
        node: data,
        sub_nodes: sub_nodes ? sub_nodes : []
    })

export const updateResponseDialogue = (
    workspaceName: string,
    BOT_ID: string,
    IF: string,
    Node: string,
    data: ResponseNodeE,
    dialog_title: string
) =>
    postJson(
        DialogApiURLWithBotID(
            workspaceName,
            BOT_ID,
            `if_node/${IF}/response_node/${Node}?dialog_title=${dialog_title}`
        ),
        data
    )

export const deleteResponseDialogue = (
    workspaceName: string,
    BOT_ID: string,
    IF: string,
    Node: string,
    dialog_title: string
) =>
    deleteJson(
        DialogApiURLWithBotID(workspaceName, BOT_ID, `if_node/${IF}/response_node/${Node}?dialog_title=${dialog_title}`)
    )

// INTENT'S API
export const getIntents = (workspaceName: string, BOT_ID: string) =>
    getJson(DialogApiURLWithBotID(workspaceName, BOT_ID, `intents`)).then(data =>
        data.map((e: any) => ({
            ...e,
            updated: e.updated + 'Z',
            created: e.created + 'Z'
        }))
    )

export const createIntent = (workspaceName: string, BOT_ID: string, intent: IntentRequestT) =>
    postJson(DialogApiURLWithBotID(workspaceName, BOT_ID, `intent?intent_name=${intent.intent}`), intent)

export const updateIntent = (workspaceName: string, BOT_ID: string, ID: string, intent: IntentRequestT) =>
    postJson(DialogApiURLWithBotID(workspaceName, BOT_ID, `intent/${ID}?intent_name=${intent.intent}`), intent)

export const deleteIntent = (workspaceName: string, BOT_ID: string, ID: string, intent: string) =>
    deleteJson(DialogApiURLWithBotID(workspaceName, BOT_ID, `intent/${ID}?intent_name=${intent}`))

export const getIntentExample = (workspaceName: string, BOT_ID: string, ID: string) =>
    getJson(DialogApiURLWithBotID(workspaceName, BOT_ID, `intent/${ID}/examples`)).then(data =>
        data.map((e: any) => ({
            ...e,
            updated: e.updated + 'Z',
            created: e.created + 'Z'
        }))
    )

export const createIntentExample = (
    workspaceName: string,
    BOT_ID: string,
    ID: string,
    data: string[],
    intent: string
) => postJson(DialogApiURLWithBotID(workspaceName, BOT_ID, `intent/${ID}/examples?intent_name=${intent}`), data)

export const deleteIntentExample = (
    workspaceName: string,
    BOT_ID: string,
    IntentID: string,
    ID: string,
    intent: string
) => deleteJson(DialogApiURLWithBotID(workspaceName, BOT_ID, `intent/${IntentID}/example/${ID}?intent_name=${intent}`))

export const updateIntentExample = (
    workspaceName: string,
    BOT_ID: string,
    IntentID: string,
    ID: string,
    data: ExampleRequestT,
    intent: string
) =>
    postJson(
        DialogApiURLWithBotID(workspaceName, BOT_ID, `intent/${IntentID}/example/${ID}?intent_name=${intent}`),
        data
    )

export const updateTrainingFAQ = (workspaceName: string, BOT_ID: string, newId: string, data: any, oldId?: string) =>
    postJson(
        !oldId
            ? DialogApiURLWithBotID(workspaceName, BOT_ID, `train_faq/${newId}`)
            : DialogApiURLWithBotID(workspaceName, BOT_ID, `move_faq/${oldId}/${newId}`),
        data
    )

export const removeTrainingFAQ = (workspaceName: string, BOT_ID: string, faqId: string, data: any) =>
    deleteJSONWithData(DialogApiURLWithBotID(workspaceName, BOT_ID, `delete_faq/${faqId}`))(data)

// ENTITY'S API
export const getEntities = (workspaceName: string, BOT_ID: string) =>
    getJson(DialogApiURLWithBotID(workspaceName, BOT_ID, `entities`)).then(data =>
        data.map((e: any) => ({
            ...e,
            updated: e.updated + 'Z',
            created: e.created + 'Z'
        }))
    )

export const createEntity = (workspaceName: string, BOT_ID: string, entity: any) =>
    postJson(DialogApiURLWithBotID(workspaceName, BOT_ID, `entity?entity_name=${entity.entity}`), entity)

export const deleteEntity = (workspaceName: string, BOT_ID: string, ID: string, entity: string) =>
    deleteJson(DialogApiURLWithBotID(workspaceName, BOT_ID, `entity/${ID}?entity_name=${entity}`))

export const updateEntity = (workspaceName: string, BOT_ID: string, ID: string, entity: any) =>
    postJson(DialogApiURLWithBotID(workspaceName, BOT_ID, `entity/${ID}?entity_name=${entity.entity}`), entity)

// ENTITY VALUE API
export const createEntityValue = (
    workspaceName: string,
    BOT_ID: string,
    ENTITY_ID: string,
    value: any,
    entity: string
) => postJson(DialogApiURLWithBotID(workspaceName, BOT_ID, `entity/${ENTITY_ID}/value?entity_name=${entity}`), value)

export const deleteEntityValue = (
    workspaceName: string,
    BOT_ID: string,
    ENTITY_ID: string,
    VALUE_ID: string,
    entity: string
) =>
    deleteJson(
        DialogApiURLWithBotID(workspaceName, BOT_ID, `entity/${ENTITY_ID}/value/${VALUE_ID}?entity_name=${entity}`)
    )

export const updateEntityValue = (
    workspaceName: string,
    BOT_ID: string,
    ENTITY_ID: string,
    VALUE_ID: string,
    data: any,
    entity: string
) =>
    postJson(
        DialogApiURLWithBotID(workspaceName, BOT_ID, `entity/${ENTITY_ID}/value/${VALUE_ID}?entity_name=${entity}`),
        data
    )

export type statusT = 'training' | 'trained' | 'failed' | 'bot_not_deployed' | "checking_for_update"

export const StatusContext = React.createContext<statusT>('training')
