import Downshift from 'downshift'
/*eslint-disable*/
import React, { useContext, useEffect, useRef, useState } from 'react'

import { Error, ErrorBoundary } from '@/common/components/ErrorBoundary'
import { Modal } from '@/common/components/Modal'
import { Button } from '@/common/styled/Dialog.BotDetails.Dumb'
import { ButtonWrapper, Paragraph } from '@/common/styled/Dialogue.Dumb'

import { DialogueT, IfNodeT, ResponseNodeE } from '../../Dialogue/kind'
//ACTION IMPORT
import { deleteIfDialogue, deleteResponseDialogue, deployAssistant, updateIfDialogue } from '../../Mechanisms/action'
//CONTEXT IMPORT
import { DialogueContext, VariableContext, VariableT } from '../Context'
import AdaptiveResponse from '../Nodes/AdaptiveCards'
import { AgentHandoffResponse } from '../Nodes/AgentHandoffResponse'
import AttachResponse from '../Nodes/AttachResponse'
import Automation from '../Nodes/Automation'
import Exit from '../Nodes/Exit'
import JumpDialogue from '../Nodes/JumpDialogue'
import OptionResponse from '../Nodes/OptionResponse'
import Question from '../Nodes/Question'
//COMPONENT IMPORT
import RecogniseDialog from '../Nodes/RecogniseDialog'
import SlotsContainer from '../Nodes/Slot'
import TextResponse from '../Nodes/TextResponse'
import VariableResponseNode from '../Nodes/VariableResponse'
import Variable from '../Variable'
//TYPE IMPORT
import { ActionT, VIEW } from '../kind'
import KnowledgeAISearch from '../Nodes/KnowledgeAISearch'

const Dialogues = ({ isdeployed, deploybot, isPublish, publishBot, assistant, props }: any) => (
    <DialogueContext.Consumer>
        {({ PageState, setPageState, workspaceName, intents, assistantID }) => {
            switch (PageState.action) {
                case ActionT.IF_DIALOGUE:
                    return (
                        <NodesWithContext {...props}>
                            <RecogniseDialog />
                        </NodesWithContext>
                    )
                case ActionT.TEXT_RESPONSE:
                    return (
                        <NodesWithContext {...props}>
                            <TextResponse />
                        </NodesWithContext>
                    )
                case ActionT.OPTIONS_RESPONSE:
                    return (
                        <NodesWithContext {...props}>
                            <OptionResponse />
                        </NodesWithContext>
                    )
                case ActionT.ADAPTIVE_RESPONSE:
                    return (
                        <NodesWithContext {...props}>
                            <AdaptiveResponse />
                        </NodesWithContext>
                    )
                case ActionT.ATTACH_RESPONSE:
                    return (
                        <NodesWithContext {...props}>
                            <AttachResponse />
                        </NodesWithContext>
                    )
                case ActionT.AGENT_HANDOFF_RESPONSE:
                    return (
                        <NodesWithContext {...props}>
                            <AgentHandoffResponse />
                        </NodesWithContext>
                    )
                case ActionT.VARIABLE_RESPONSE:
                    return (
                        <NodesWithContext {...props}>
                            <VariableResponseNode />
                        </NodesWithContext>
                    )
                case ActionT.JUMP_DIALOGUE:
                    return (
                        <NodesWithContext {...props}>
                            <JumpDialogue />
                        </NodesWithContext>
                    )
                case ActionT.QUESTION_RESPONSE:
                    return (
                        <NodesWithContext {...props}>
                            <Question />
                        </NodesWithContext>
                    )
                case ActionT.SLOT_RESPONSE:
                    return (
                        <NodesWithContext {...props}>
                            <SlotsContainer />
                        </NodesWithContext>
                    )
                case ActionT.FLOW_DIALOGUE:
                    return (
                        <NodesWithContext {...props}>
                            <Automation />
                        </NodesWithContext>
                    )
                case ActionT.KNOWLEDGEAI_RESPONSE:
                        return (
                            <NodesWithContext {...props}>
                                <KnowledgeAISearch />
                            </NodesWithContext>
                        )
                case ActionT.EXIT_DIALOGUE:
                    return (
                        <NodesWithContext {...props}>
                            <Exit />
                        </NodesWithContext>
                    )
                case ActionT.DELETE_RESPONSE_NODE:
                    return <DeleteResponse dialogue={PageState.data} />

                case ActionT.DELETE_JUMP_NODE:
                    return <DeleteJump dialogue={PageState.data} />

                case ActionT.DELETE_EXIT_NODE:
                    return <DeleteJump dialogue={PageState.data} />

                case ActionT.ASSISTANT_VIEW:
                    return null
                case ActionT.DEPLOY:
                    return <DeployBot />
                case ActionT.DELETE_DIALOGUE_NODE:
                    return <DeleteDialogue dialogue={PageState.data} />
                default:
                    return null
            }
        }}
    </DialogueContext.Consumer>
)

enum ErrorState {
    Valid,
    InValid
}

type HasError = {
    type: ErrorState.Valid
    error: boolean
    info: string
}

type NoError = {
    type: ErrorState.InValid
}

const NoErrorV: NoError = { type: ErrorState.InValid }

type ErrorT = NoError | HasError

const DeployBot = () => {
    const { PageState, setPageState, assistant, workspaceName, fetchCallback } = useContext(DialogueContext)

    const [modelError, setModelError] = useState<ErrorT>(NoErrorV)

    const deployBot = () => {
        setPageState(state => ({ ...state, loading: true }))
        deployAssistant(workspaceName, assistant.id)
            .then(data => {
                fetchCallback(() => {})
            })
            .catch(error => {
                setPageState(e => ({
                    ...e,
                    loading: false
                }))
                setModelError({
                    type: ErrorState.Valid,
                    error: true,
                    info: 'Something Went Wrong'
                })
            })
    }

    return PageState.action === ActionT.DEPLOY ? (
        <Modal showPopupLoader={PageState.loading}>
            <ErrorBoundary
                error={modelError}
                render={(err: any, info: any) => {
                    return (
                        err && (
                            <Error.Delete
                                onClick={() => {
                                    setModelError(NoErrorV)
                                }}
                                info={info}
                            />
                        )
                    )
                }}
            >
                <h2>Deployment</h2>
                <p>Would you like to deploy all the Dialogs, Intents, Entities created/updated to this point?</p>
                <div>
                    <button
                        className="btn btn-primary"
                        type="button"
                        onClick={() => {
                            setPageState({
                                action: ActionT.VIEW
                            })
                        }}
                    >
                        Cancel
                    </button>
                    <button
                        className="btn btn-success"
                        type="button"
                        onClick={() => {
                            deployBot()
                        }}
                    >
                        Ok
                    </button>
                </div>
            </ErrorBoundary>
        </Modal>
    ) : null
}

export const NodesWithContext = (props: any) => {
    const [popupExposed, assignPopupExposed] = useState<boolean>(false)
    const [isUrlOpen, setUrlisOpen] = useState<boolean>(false)

    const [variable, assignVariable] = useState<VariableT | null>(null)
    const [selectedEditor, setSelectedEditor] = useState<number | null>(null)
    const [forAutomationVariables, setForAutomationVariables] = useState<'collection' | 'variable' | 'input'>(
        'variable'
    )
    const [displayStatusOfEntityListInVariable, setDisplayStatusOfEntityListInVariable] = useState<'SHOW' | 'HIDE'>(
        'SHOW'
    )

    const eleRef = useRef<any>(null)

    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event: any) {
            if (event.target) {
                console.log('You clicked outside of me!', event.target)
                eleRef.current = event.target
            }
        }
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    return (
        <VariableContext.Provider
            value={{
                popupExposed,
                assignPopupExposed,
                variable,
                assignVariable,
                selectedEditor,
                setSelectedEditor,
                displayStatusOfEntityListInVariable,
                setDisplayStatusOfEntityListInVariable,
                forAutomationVariables,
                setForAutomationVariables,
                isUrlOpen, 
                setUrlisOpen
            }}
        >
            <div className="flow_popup2">
                <div data-ml-modal="">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            {props.children}

                            {popupExposed ? (
                                <Downshift
                                    isOpen={popupExposed}
                                    onOuterClick={e => {
                                        const ele = document.getElementById(
                                            selectedEditor ? selectedEditor.toString() : ''
                                        )

                                        const ele1 = document.getElementById('editor-input')

                                        const ele2 = document.getElementById("collection_picker_in_input_page")
                                        setTimeout(() => {
                                            if (
                                                eleRef &&
                                                ele &&
                                                ele.contains(eleRef.current) == false &&
                                                (!ele1 || ele1.contains(eleRef.current) == false) && 
                                                (!ele2 || ele2.contains(eleRef.current) == false)
                                            ) {
                                                assignPopupExposed(false)
                                            }
                                        })
                                    }}
                                >
                                    {() => (
                                        <section className="dialog_context_popup_height">
                                            <Variable
                                                props={props}
                                                displayStatusOfEntityListInVariable={
                                                    displayStatusOfEntityListInVariable
                                                }
                                            />
                                        </section>
                                    )}
                                </Downshift>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </VariableContext.Provider>
    )
}

const getTittle = (dialogue: ResponseNodeE) => {
    switch (dialogue.type) {
        case 'text':
            return 'Delete Message'
        case 'option':
            return 'Delete Options'
        case 'ask_a_question':
            return 'Delete Ask a Question'
        case 'slot_container':
            return 'Delete Verify user details'
        case 'automation':
            return 'Delete Automation'
        case 'adaptive_card':
            return 'Delete Adaptive Card'
        case 'file_upload':
            return 'Delete Upload Attachment'
        case 'agent_handoff':
            return 'Delete Agent Handover'
        case 'context_variable':
            return 'Delete Custom Variables'
        case 'knowledge_ai_search':
            return "Delete Knowledge AI Search"
        default:
            return 'Delete Node'
    }
}
const getSubtitle = (dialogue: ResponseNodeE) => {
    switch (dialogue.type) {
        case 'text':
            return 'Are you sure to delete the Message? '
        case 'option':
            return 'Are you sure to delete the Options?'
        case 'ask_a_question':
            return 'Are you sure to delete the Question?'
        case 'slot_container':
            return 'Are you sure to delete Verify user details?'
        case 'automation':
            return 'Are you sure to delete this Workflow Automation? '
        case 'adaptive_card':
            return 'Are you sure you want to delete Adaptive Card?'
        case 'file_upload':
            return 'Are you sure you want to delete Upload Attachment?'
        case 'agent_handoff':
            return 'Are you sure you want to delete Agent Handover?'
        case 'context_variable':
            return 'Are you sure to delete Custom Variables?'
        case 'knowledge_ai_search':
            return 'Are you sure to delete Knowledge AI Search?'
        default:
            return 'Are you sure to delete?'
    }
}

const DeleteDialogue = ({ dialogue }: { dialogue: DialogueT }) => {
    const [loading, setLoading] = useState<boolean>(false)

    const [modelError, setModelError] = useState<ErrorT>(NoErrorV)

    const { workspaceName, assistantID, fetchCallback, setPageState, navigateHomePage, dialogueName, queryClient , parentIfnode} = useContext(
        DialogueContext
    )

    const handleClick = () => {
        setLoading(true)
        deleteIfDialogue(
            workspaceName,
            assistantID,
            dialogue.uuid,
            dialogueName,
            dialogue.parent === null ? 'removed' : 'updated'
        )
            .then(() => {
                if (dialogue.parent === null) {
                    navigateHomePage()
                } else {
                    queryClient.invalidateQueries(['dialogDescendants', (parentIfnode as IfNodeT).uuid])
                    queryClient.invalidateQueries(['dialogRoots', assistantID])
                    queryClient.invalidateQueries(['dialogs', assistantID])
                    queryClient.invalidateQueries(['assistants'])
                    fetchCallback(() => {
                        setPageState(VIEW)
                        setLoading(false)
                    })
                }
            })
            .catch(() => {
                setLoading(false)
                setModelError({
                    type: ErrorState.Valid,
                    error: true,
                    info: 'Something Went Wrong'
                })
            })
    }

    return (
        <DialogueContext.Consumer>
            {({ setPageState }) => (
                <ArriveModal
                    title={'Delete Dialog'}
                    subtitle={'Are you sure to delete this dialog? '}
                    handleCancel={() => setPageState(VIEW)}
                    loading={loading}
                    handleClick={() => handleClick()}
                    modelError={modelError}
                    closeError={() => {
                        setLoading(false)
                        setModelError(NoErrorV)
                    }}
                />
            )}
        </DialogueContext.Consumer>
    )
}

const DeleteJump = ({ dialogue }: any) => {
    const [loading, setLoading] = useState<boolean>(false)

    const [modelError, setModelError] = useState<ErrorT>(NoErrorV)

    const { workspaceName, assistantID, fetchCallback, setPageState, dialogueName, queryClient, parentIfnode } = useContext(DialogueContext)

    const handleClick = () => {
        setLoading(true)
        updateIfDialogue(
            workspaceName,
            assistantID,
            dialogue.uuid,
            {
                ...dialogue,
                next_step: 'wait_for_user_input'
            },
            dialogueName
        )
            .then(() => {
                queryClient.invalidateQueries(['dialogDescendants', (parentIfnode as IfNodeT).uuid])
                queryClient.invalidateQueries(['dialogRoots', assistantID])
                queryClient.invalidateQueries(['dialogs', assistantID])
                queryClient.invalidateQueries(['assistants'])
                fetchCallback(() => {
                    setLoading(false)
                    setPageState(VIEW)
                })
            })
            .catch(() => {
                setLoading(false)
                setModelError({
                    type: ErrorState.Valid,
                    error: true,
                    info: 'Something Went Wrong'
                })
            })
    }

    const isJumpNode = (): boolean => {
        const next_step: any = dialogue.next_step
        if (typeof next_step === 'string') {
            return true
        } else if (typeof next_step !== 'string') {
            if (next_step.jump_to === undefined) {
                return false
            }
        }
        return true
    }

    return (
        <DialogueContext.Consumer>
            {({ setPageState }) => (
                <ArriveModal
                    title={isJumpNode() ? 'Delete Jump' : 'Delete Exit'}
                    subtitle={isJumpNode() ? 'Are you sure to delete jump?' : 'Are you sure to delete exit?'}
                    handleCancel={() => setPageState(VIEW)}
                    loading={loading}
                    handleClick={() => handleClick()}
                    modelError={modelError}
                    closeError={() => {
                        setLoading(false)
                        setModelError(NoErrorV)
                    }}
                />
            )}
        </DialogueContext.Consumer>
    )
}

const DeleteResponse = ({ dialogue }: { dialogue: ResponseNodeE }) => {
    const [loading, setLoading] = useState<boolean>(false)

    const [modelError, setModelError] = useState<ErrorT>(NoErrorV)

    const { workspaceName, assistantID, fetchCallback, setPageState, dialogueName, queryClient, parentIfnode } = useContext(DialogueContext)

    const handleClick = () => {
        setLoading(true)
        deleteResponseDialogue(workspaceName, assistantID, dialogue.parent, dialogue.id, dialogueName)
            .then(async() => {
                 queryClient.invalidateQueries(['dialogDescendants', (parentIfnode as IfNodeT).uuid])
                 queryClient.invalidateQueries(['dialogRoots', assistantID])
                 queryClient.invalidateQueries(['dialogs', assistantID])
                 queryClient.invalidateQueries(['assistants'])
                fetchCallback(() => {
                    setLoading(false)

                    setPageState(VIEW)
                })
            })
            .catch(() => {
                setLoading(false)
                setModelError({
                    type: ErrorState.Valid,
                    error: true,
                    info: 'Something Went Wrong'
                })
            })
    }

    return (
        <DialogueContext.Consumer>
            {({ setPageState }) => (
                <ArriveModal
                    title={getTittle(dialogue)}
                    subtitle={getSubtitle(dialogue)}
                    handleCancel={() => setPageState(VIEW)}
                    loading={loading}
                    handleClick={() => handleClick()}
                    modelError={modelError}
                    closeError={() => {
                        setLoading(false)
                        setModelError(NoErrorV)
                    }}
                />
            )}
        </DialogueContext.Consumer>
    )
}

function ArriveModal(props: {
    handleCancel: Function
    handleClick: Function

    loading: boolean
    title: string
    subtitle: string
    modelError: ErrorT
    closeError: Function
}) {
    const { handleCancel, loading, title, subtitle, handleClick, modelError, closeError } = props
    return (
        <Modal close={handleCancel} showPopupLoader={loading}>
            <ErrorBoundary
                error={modelError}
                render={(err: any, info: any) => {
                    return (
                        err && (
                            <Error.Delete
                                onClick={() => {
                                    closeError()
                                }}
                                info={info}
                            />
                        )
                    )
                }}
            >
                <React.Fragment>
                    <h2>{title}</h2>
                    <Paragraph>{subtitle}</Paragraph>
                </React.Fragment>

                <ButtonWrapper>
                    <Button primary type="button" onClick={() => handleCancel()}>
                        <label>No</label>
                    </Button>
                    <Button type="button" onClick={() => handleClick()}>
                        <label>Yes</label>
                    </Button>
                </ButtonWrapper>
            </ErrorBoundary>
        </Modal>
    )
}

export default Dialogues
