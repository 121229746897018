import * as React from 'react'
/*eslint-disable*/
import * as ReactDOM from 'react-dom'

import {
    addHandlerForLogout,
    applyOnce,
    initializeAccessTokenElection,
    loadSessionStorage
} from '@/common/user-sessions/across-tabs'

import { AppWithCache } from './App'

/*eslint-disable*/


// initialize logout across tabs
addHandlerForLogout(_ => {
    // console.log('Logging out...')
    location.replace(window.location.origin + '/authentication/u')
})

initializeAccessTokenElection().then(_ => {
    // document.title = '♛ ' + 'Leader'
    console.log('♛')
})

loadSessionStorage()

applyOnce().then(_ => {
    console.log("React DOM :: version" , ReactDOM.version,ReactDOM)
    // console.log("VERSION CHECK",(window as any).React1.version === (window as any).React2.version);
    const root = document.getElementById('root') as HTMLElement
    ReactDOM.render(<AppWithCache />, root)
})
