import {
  getJson,
  loginApiURL,
  postJson,
  putJson,
  subscriptionApiURL,
} from '@/common/utils/api-utils';
import { deleteReq, patchReq } from '@/common/utils/axios-fp.utils';
import { fold } from 'fp-ts/lib/Either';
import URLTemplate from 'url-template';
import { Role, User } from './user.types';

enum APIList {
  numberOfAllowedAdmins = '/{ws}/resource/admin/limit',
  workspaceUsers = '/access/v2/{ws}/users',
  inviteUsers = '/access/v2/invite/{ws}',
  revoke = '/access/revoke/{ws}',
  enable = '/access/enable/{ws}',
  remove = '/access/v2/remove/{ws}',
  roles = '/access/v2/{ws}/roles',
  updateRoles = '/access/v2/roles/{ws}/{user}',
}

export type BasicUserInfo = {
  first_name?: string;
  last_name?: string;
  email: string;
  roles: Pick<Role, 'id'>[] | [];
};

const getUsersURL = URLTemplate.parse(APIList.workspaceUsers);
const inviteUserURL = URLTemplate.parse(APIList.inviteUsers);
const getRolesURL = URLTemplate.parse(APIList.roles);
const revokeURL = URLTemplate.parse(APIList.revoke);
const enableUserURL = URLTemplate.parse(APIList.enable);
const removeUserURL = URLTemplate.parse(APIList.remove);
const updateRolesURL = URLTemplate.parse(APIList.updateRoles);
const numberOfAllowedAdminsURL = URLTemplate.parse(
  APIList.numberOfAllowedAdmins
);

export const fetchUsers = (ws: string) => {
  // return Promise.resolve([
  //   {
  //     id: 32,
  //     first_name: 'Arun',
  //     last_name: 'Karikalan',
  //     email: 'arun.k@workativ.com',
  //     verified: true,
  //     Roles: [
  //       {
  //         id: 1,
  //         name: 'super_admin',
  //         display_name: 'Super Admin',
  //         workspace: null,
  //         meta: {},
  //         createdAt: '2023-03-15T18:17:28.000Z',
  //         updatedAt: '2023-03-15T18:17:28.000Z',
  //       },
  //     ],
  //     AccessibleWorkspaces: [
  //       { name: 'nirup', WorkspaceAccess: { valid: true } },
  //     ],
  //     CountryCode: { name: 'Andorra', ISOcode: 'AD' },
  //     MobileNumberVsUser: null,
  //   },
  //   {
  //     id: 37,
  //     first_name: 'Shri',
  //     last_name: 'Ghayathri',
  //     email: 'shri.ghayathri@skitter.in',
  //     verified: false,
  //     Roles: [
  //       {
  //         id: -1,
  //         name: 'chatbot_contributor',
  //         display_name: 'Chatbot Contributor',
  //         workspace: null,
  //         meta: { bot_list: ['IT Support Bot'],botname_list:["IT Support Bot"] },
  //         createdAt: '2023-03-15T18:17:28.000Z',
  //         updatedAt: '2023-03-15T18:17:28.000Z',
  //       },
  //       // {
  //       //   id: 2,
  //       //   name: 'global_admin',
  //       //   display_name: 'Global Admin',
  //       //   workspace: null,
  //       //   meta: {},
  //       //   createdAt: '2023-03-15T18:17:28.000Z',
  //       //   updatedAt: '2023-03-15T18:17:28.000Z',
  //       // },
  //     ],
  //     AccessibleWorkspaces: [
  //       { name: 'nirup', WorkspaceAccess: { valid: true } },
  //     ],
  //     CountryCode: { name: 'India', ISOcode: 'IN' },
  //     MobileNumberVsUser: {
  //       id: 79,
  //       mobileNo: '9486794023',
  //       verified: true,
  //       valid: true,
  //       createdAt: '2022-04-27T11:05:26.000Z',
  //       updatedAt: '2022-04-27T11:05:26.000Z',
  //       code: '91',
  //       userId: 37,
  //     },
  //   },
  //   // {"id":62,"first_name":"Abi","last_name":"Kumarasamy","email":"arun.digital.karikalan1994@gmail.com","verified":true,"Roles":[{"id":1,"name":"super_admin","display_name":"Super Admin","workspace":null,"meta":{},"createdAt":"2023-03-15T18:17:28.000Z","updatedAt":"2023-03-15T18:17:28.000Z"}],"AccessibleWorkspaces":[{"name":"nirup","WorkspaceAccess":{"valid":true}}],"CountryCode":null,"MobileNumberVsUser":null},{"id":240,"first_name":"Like","last_name":"Alive","email":"test.summa@wedo.com","verified":false,"Roles":[{"id":1,"name":"super_admin","display_name":"Super Admin","workspace":null,"meta":{},"createdAt":"2023-03-15T18:17:28.000Z","updatedAt":"2023-03-15T18:17:28.000Z"}],"AccessibleWorkspaces":[{"name":"nirup","WorkspaceAccess":{"valid":true}}],"CountryCode":null,"MobileNumberVsUser":null}
  // ]);
  return getJson<User[]>(
    loginApiURL(
      getUsersURL.expand({
        ws,
      })
    )
  );
};

export type RolesInputT = Pick<Role, 'id'>[];

export const updateRoles = (ws: string, userId: number, roles: RolesInputT) => {
  return putJson(
    loginApiURL(
      updateRolesURL.expand({
        ws,
        user: userId,
      })
    )
  )()(roles);
};

export const inviteUser = (ws: string, user: BasicUserInfo) => {
  return postJson(
    loginApiURL(
      inviteUserURL.expand({
        ws,
      })
    )
  )(user);
};

const MOCK_Roles = [
  {
    id: 1,
    name: 'super_admin',
    display_name: 'Admin',
    workspace: null,
    meta: {},
    createdAt: '2023-03-30T11:15:04.000Z',
    updatedAt: '2023-03-30T11:15:04.000Z',
  },
  // {
  //   id: 2,
  //   name: 'Chat_Channel_Admin',
  //   display_name: 'Chat Channel Admin',
  //   workspace: null,
  //   meta: {},
  //   createdAt: '2023-03-30T11:15:04.000Z',
  //   updatedAt: '2023-03-30T11:15:04.000Z',
  // },
  // {
  //   id: 3,
  //   name: 'Chatbot_Admin',
  //   display_name: 'Chatbot Admin',
  //   workspace: null,
  //   meta: {},
  //   createdAt: '2023-03-30T11:15:04.000Z',
  //   updatedAt: '2023-03-30T11:15:04.000Z',
  // },
];
export const fetchRoles = (ws: string) => {
  return getJson<Role[]>(loginApiURL(getRolesURL.expand({ ws })));
  // return Promise.resolve(MOCK_Roles);
};

export const disableUser = (ws: string, userId: number) =>
  deleteReq<{ id: number }>(loginApiURL(revokeURL.expand({ ws })))({
    id: userId,
  })().then((deleteRes) => {
    return fold(
      (err) => Promise.reject(err),
      () => Promise.resolve()
    )(deleteRes);
  });

export const enableUser = (ws: string, userId: number) =>
  patchReq<{ id: number }>(loginApiURL(enableUserURL.expand({ ws })))({
    id: userId,
  })().then((enableRes) => {
    return fold(
      (err) => Promise.reject(err),
      () => Promise.resolve()
    )(enableRes);
  });

export const removeUser = (ws: string, userId: number) =>
  deleteReq<{ id: number }>(loginApiURL(removeUserURL.expand({ ws })))({
    id: userId,
  })().then((removeRes) => {
    return fold(
      (err) => Promise.reject(err),
      () => Promise.resolve()
    )(removeRes);
  });

export const getAllowedNumberOfAdminsApi: (
  ws: string
) => Promise<number> = async (ws: string) => {
  console.log('ws ::: ', ws);
  const {
    output: { resourceLimit },
  } = await getJson(
    subscriptionApiURL(numberOfAllowedAdminsURL.expand({ ws }))
  );
  console.log('resourceLimit ::: ', resourceLimit);
  return resourceLimit;
};
