/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import {
  TabContainer,
  TabHeader,
  TabRow,
  TabLeft,
  TabRight,
} from '@/Flows/analytics/logs';
import { Link, Outlet, useParams } from 'react-router-dom';
import { SmallButton } from '@/common/styled/Dialog.Canvas.Nodes.Dumb'

const channels = [
//   {
//     name: 'Create Article',
//     to: '/slack',
//     id: 'article',
//   },
//   {
//     name: 'Upload Files',
//     to: '/teams',
//     id: 'files',
//   },
//   {
//     name: 'Q&A',
//     to: '/widget',
//     id: 'Q&A',
//   },
  {
    name: 'Website link',
    to: '/website-kb',
    id: 'website',
  },
  {
    name: 'External Sources',
    to: '/external-kb',
    id: 'external',
  },
];

export function KnowledgeLinksHome(props: any) {
  const { match } = props;
  const { assistantname, workspacename } = match.params;

  const [active, setActive] = useState('website');

  const params = useParams();

  useEffect(() => {
    if (props.location.pathname.includes('knowledge/external-kb')) {
      setActive('external');
    } else if (props.location.pathname.includes('knowledge/website-kb')) {
      setActive('website');
    } 
    // else if (props.location.pathname.includes('chat-channels/widget')) {
    //   setActive('widget');
    // }
  });

  return (
    <>
      <TabContainer className="sub_menu_section knowledge_ai_section">
        <TabRow className="sub_menu knowledge_ai">
          <TabLeft>
            {channels.map((cha) => {
              return (
                <TabHeader active={cha.id == active}>
                  <Link
                    className={cha.id == active ? 'active' : ''}
                    to={`/workspace/${workspacename}/assistant/knowledge${cha.to}`}
                    onClick={() => {
                      setActive(cha.id);
                    }}
                  >
                    {cha.name}
                  </Link>
                </TabHeader>
              );
            })}
          </TabLeft>
          <TabRight>
            <div id="assistant-sub-header"></div>
          </TabRight>
        </TabRow>
      </TabContainer>
      <Outlet />
    </>
  );
}
